import { isAuthenticated } from '../auth/index';

export const uploadFiles = (files) => {
    return fetch(`${process.env.REACT_APP_DOMAIN_BACK}/upload`,{
        method: "POST",
        headers: {
            Accept: "application/json", 
            Authorization: `Bearer ${isAuthenticated().token}`,
        },
        body: files
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err));
};