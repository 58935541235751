import { isAuthenticated  } from "../../../auth";

export const getCategories = () => {
    return fetch(`${process.env.REACT_APP_DOMAIN_BACK}/category/list`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${isAuthenticated().token}`,
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err));
}