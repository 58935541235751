import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Cancel from '@material-ui/icons/Clear';
import Hidden from '@material-ui/core/Hidden';
import Save from '@material-ui/icons/SaveAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isAuthenticated } from '../../../auth';
import { toast } from 'react-toastify';
import Moment from 'react-moment';

class InvoicesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            invoiceId : '',
            isLoadingInvoice: false,
        }
    }

    componentDidMount() {
        this.getAllInvoices()
    }

    getAllInvoices() {
        this.setState({
            isLoadingInvoice: true
        });
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/billing/${isAuthenticated().companyActually.slug}/list`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            }
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error);
            }
            //console.log(json);
            this.setState({
                invoices: json,
                isLoadingInvoice: false
            })
           
        })
        .catch(err => console.log(err));
    }


    render() {
        if (this.props.step !== 1) {
            return null
        }
        const {
            invoices,
            isLoadingInvoice
        } = this.state;
        return(
            <Grid container>
                <Grid item md={8} xs={12} className="grayBg p-tb-2">
                    <span className="cancel p-lr-5 " onClick={this.props.closeBilling}><Cancel /></span>
                    <h3 className="p-lr-5">Billing</h3>
                    <p className="p-lr-5">Your statements and invoices are listed below.</p>
                    <p className="p-lr-5">Statements</p>
                    {
                        !isLoadingInvoice ?
                        Object.keys(invoices).length ?
                        invoices.map((invoice, index) => (
                            <section key={invoice.id} className={ !invoice.paid ? "whiteBg m-lr-5 m-tb-2 p-lr-2 p-tb-2 bills" :"m-lr-5 m-tb-2 p-lr-2 p-tb-2 bills"}>
                                <div className="billingStatements textLeft">
                                    <p>Invoice #{invoice.number}</p>
                                    <p className="account"><Moment unix format="MMM D, YYYY" withTitle>{invoice.date}</Moment></p>

                                </div>
                                <div className="billingStatements textRight">
                                    <p>
                                        ${ (invoice.total / 100).toFixed(2) }
                                        {
                                            invoice.paid ? <a href={invoice.invoice_pdf}>
                                                <Save className="downloadInvoice"/>
                                            </a> : null
                                        }
                                        </p>
                                    <p className="account">
                                    {
                                        !invoice.paid ? 'UNPAID' : 'PAID'
                                    }
                                    </p>
                                </div>
                                {
                                    isAuthenticated().role === 'administrator' || isAuthenticated().role === 'staff' || 
                                    isAuthenticated().role === 'user' ?
                                    null:
                                    !invoice.paid ? 
                                    <FormControl className="loginForm m-tb-2 ">
                                        <TextField
                                            type="submit"
                                            className="yellowSubmit nextArrow"
                                            variant="outlined"
                                            value="PAY BALANCE"
                                            onClick = {() => this.props.showPayments(invoice.id)}
                                        />
                                    </FormControl> 
                                    : null
                                }
                            </section>
                        ))
                        :
                        <p className="p-lr-5 account">Currently you don't have any invoices to pay</p>
                        :
                        <div style={{width: '100px',margin : 'auto'}}><CircularProgress/></div>
                    }
                </Grid>
                <Hidden smDown>
                    <Grid item md={4} className="darkBg yellowDotsBg"></Grid>
                </Hidden>
            </Grid>
        )
    }
}

export default InvoicesModal;