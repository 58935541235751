import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { isAuthenticated } from '../auth/index';

export default function withAuth(ComponentToProtect) {
    return class extends Component {
        constructor() {
            super();
            this.state = {
                loading: true,
                redirect: false
            };
        }
        
        componentDidMount() {
            fetch(`${process.env.REACT_APP_DOMAIN_BACK}/auth/checkUser`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${isAuthenticated().token}`,
                }
            })
            .then(res => {
                if (res.status === 200) {
                    this.setState({ loading: false });
                } else {
                    localStorage.removeItem('gembah_user');
                    this.setState({
                        loading: false,
                        redirect: true,
                    });
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({ loading: false, redirect: true });
            });
        }
        
        render() {
            const {
                loading,
                redirect
            } = this.state;
            if (loading) {
                return null;
            }
            if (redirect) {
                return <Redirect to="/login" />;
            }
            return <ComponentToProtect {...this.props} />;
        }
    };
}
