import React,{Component} from 'react';
import {
    Dialog,
    Grid,
    Button,
    FormControl,
    Hidden,
    MenuItem,
    Avatar,
    Select,
    TextField,
    InputAdornment,
}
from '@material-ui/core';
import { MentionsInput, Mention } from 'react-mentions';
import defaultStyle from '../../TaskDetails/stylesDefault';
import defaultMentionStyle from '../../TaskDetails/defaultMentionStyle';
import {ProductTextField} from '../../NewProduct/styles';
import Cancel from '@material-ui/icons/Clear';
import Add from '@material-ui/icons/Add';
import Pencil from '@material-ui/icons/Create'
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { isAuthenticated } from '../../../auth/index';
import {toast} from 'react-toastify';
import dompurify from 'dompurify';

class EditTask extends Component {
    constructor(props){
        super(props);
        this.state = {
            users:[],
            peopleToMentions : [],
            newsTodos: this.props.taskInfo.todo,
            editTaskName : false,
            editTaskDescription:false,
            editTodos : false,
            editAssign : false,
            newName:'',
            newDescription:'',
            newTodoItem:'',
            newAssignto:''
        }
    }
    componentDidMount() {
        this.getUsersToSuggest();
        this.commentData = new FormData();
       
    }
    onChangeComments = (e, newValue, newPlainTextValue, mentions) => {
        this.setState({
            newDescription: e.target.value,
            peopleToMentions: mentions
        })
    }
    getUsersToSuggest = () => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/company/${isAuthenticated().companyActually.slug}/employees`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
            this.setState({
                users: json,
            });
        })
        .catch(err => console.log(err));
    }

    onChange = (e) => {
        this.setState({
          [e.target.name] : e.target.value  
        })
    }

    editName = () =>{
        this.setState( prevState => ({
            editTaskName : !prevState.editTaskName
        }));
    }

    editDescription = () => {
        this.setState( prevState => ({
            editTaskDescription: !prevState.editTaskDescription
        }));
    }

    editTodos = (text,index) => {
        this.setState({
            editTodos: true,
            newTodoItem : text
        });
        this.removeFile('todos',this.state.newsTodos,index);
    }

    showEditionTodo = () => {
        this.setState({ editTodos: true})
    }

    editAssign = () => {
        this.setState( prevState => ({
            editAssign: !prevState.editAssign
        }));
    }
    addTodos = () => {
        if (this.state.editTodos) {
            if (this.state.newTodoItem === '') {
                return toast.error('To-do input required');
            }

            let todoNew = [{
                text: this.state.newTodoItem
            }];
           // console.log(todoNew, 'new todo');
            
            this.setState({
                newsTodos: [
                    ...this.state.newsTodos,
                    ...todoNew
                ],
                newTodoItem: ''
            });
           
            
           
        } else {
            this.setState({
                editTodos: true,
                newTodoItem: ''
            });
        }
    };
    hideTodoInput = () => {
        this.setState({
            editTodos: false,
            newTodoItem: ''
        });
    }
    removeFile = (arrayName, array, position) => {
		array.splice(position,1);
		this.setState({
			[arrayName]: array
		});
    };
    saveEdit = () => {
        let body = {};
        

        /** If edit name check for validations */
        if(this.state.editTaskName){
            if (this.state.newName === '') {
                return toast.error('Task name required');
            }
            const nameBody = {
                name : this.state.newName
            }
            body = Object.assign(body,nameBody);
        }

        /** if edit description check for validations */
        if(this.state.editTaskDescription){
            if (this.state.newDescription === '') {
                return toast.error('Task description required');
            }
            const descriptionBody = {
                description : this.state.newDescription
            }
            body = Object.assign(body,descriptionBody);
        }

        /** if edit todos */
        if(this.state.editTodos){
            if (this.state.newTodoItem === '') {
                return toast.error('Please add To-Do');
            }
            
        }
        const tod = {
            todo : this.state.newsTodos
        }
        body = Object.assign(body,tod);
        /** if edit Assigned to */
        if(this.state.editAssign){
            if(this.state.newAssignto === ''){
                return toast.error('Please select an user to assign the task');
            }
            const assignBody = {
                assignedTo : this.state.newAssignto
            }
            body = Object.assign(body,assignBody);
        }

        //console.log(body, 'body to send');
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/task/${this.props.taskInfo.slug}`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
            body: JSON.stringify(body),
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error)
            }
            this.props.close();
            this.props.reloadTaskInfo({
                taskInfo: json
            })
            this.setState( prevState => ({
                editTaskDescription: false,
                editTaskName : false,
                editTodos : false,
                editAssign : false,
            }));
            toast.success('Edited succesfully');
          
        })
        .catch(err => console.log(err));
    }
    render(){
        const {
            editTaskName,
            editTaskDescription,
            editTodos,
            editAssign,
            newName,
            newDescription,
            newTodoItem,
            newAssignto,
            newsTodos,
            users
        } = this.state;

       
        const { taskInfo} = this.props;
        const sanitize = dompurify.sanitize;
        
        return(
            <Dialog
            open = {this.props.open}
            onClose= {this.props.close}
            aria-labelledby="simple-dialog-title"
            className="fullDialog"
            maxWidth='md'
            id='editTaskModal'
        >
            <Grid container>
                <Grid item md={9} xs={12} className="grayBg p-lr-5 p-tb-2">
                    <span className="cancel" onClick={this.props.close}><Cancel/></span>
                    <h3>EDIT TASK</h3>
                    <p>Take a look and edit all necessary information</p>
                    <div id="edit-task-container">
                        <p className="bold">Task Name</p>
                        {
                            editTaskName ?
                            <FormControl className="loginForm">
                            <Button className="editPreviousOrder cancel floatRight" onClick={this.editName}>
                                    <Cancel />
                            </Button>
                            <ProductTextField
                                name="newName"
                                value={newName}
                                onChange = {e => this.onChange(e)}
                                variant= "outlined"
                                className = "loginInput"
                            />
                            
                            </FormControl>
                        :
                        <p>{taskInfo.name}
                             <Button className="editPreviousOrder" onClick={this.editName}>
                                <Pencil/>
                            </Button>
                        </p>
                        }
                        <p className="bold">Description</p>
                        {
                            editTaskDescription ?
                            <FormControl className="loginForm ">
                                <Button className="editPreviousOrder cancel " 
                                style={{'display':'block','marginLeft':'auto'}}
                                onClick={this.editDescription}>
                                    <Cancel />
                                </Button>
                            <MentionsInput
                                value={newDescription}
                                onChange={this.onChangeComments}
                                style={defaultStyle}
                                placeholder={"Mention people using '@'"}
                                className="comments-textarea"
                            >
                                <Mention
                                    trigger="@"
                                    data={users}
                                    markup="<span class='taggedUser' id='__id__'>__display__</span>"
                                    appendSpaceOnAdd={true}
                                    renderSuggestion={
                                        (
                                            suggestion,
                                            search,
                                            highlightedDisplay,
                                            index,
                                            focused
                                        ) => (
                                            <div className={`user ${focused ? 'focused': ''}`}>
                                                {highlightedDisplay}
                                            </div>
                                        )
                                    }
                                    style={defaultMentionStyle}
                                />
                            </MentionsInput>
                            </FormControl>
                            :
                            
                                !taskInfo.description ?  
                                <p>No description given 
                                <Button className="editPreviousOrder" onClick={this.editDescription}>
                                <Pencil/>
                                </Button>
                                </p>
                                :
                                <span>
                                    <p dangerouslySetInnerHTML={{__html: sanitize(taskInfo.description)}}></p>
                                    <Button className="editPreviousOrder" onClick={this.editDescription}>
                                    <Pencil/>
                                    </Button>
                                </span>
                            
                            
                             
                        }
                        <p className="bold">To-do</p>
                        {
                            Object.keys(newsTodos).length  ?
                            newsTodos.map((todo, index) => (
                                <div key={index} className="m-b-2 todosItemsText">
                                    <p className="account repeaterFields">{todo.text}</p>
                                    <span style={{'color':'#bdbdbd'}}>
                                        <RemoveCircle className="cursorPointer" onClick={e => this.removeFile('todos', newsTodos, index)}/>
                                        <Pencil className="cursorPointer" onClick={e => this.editTodos(todo.text, index)}/>
                                    </span>
                                </div>
                            ))
                            :
                            <p>This task doesn't have to-do items</p>
                        }
                        {
                            editTodos ? 
                            <FormControl className="loginForm">
                                <ProductTextField
                                    name="newTodoItem"
                                    value={newTodoItem}
                                    variant="outlined"
                                    className="todosItems loginInput"
                                    placeholder= "Task To-Do here..."
                                    onChange={e => this.onChange(e)}
                                    style={{'marginBottom': '5px'}}
                                />
                                <span className="cancel">
                                    <Cancel onClick={this.hideTodoInput} />
                                </span>
                            </FormControl> : null
                        }
                        <FormControl className="loginForm">
                            <TextField
                                type="submit"
                                variant="outlined"
                                value="ADD TO-DO"
                                className="loginInput"
                                id="addTodo"
                                onClick={this.addTodos}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment>
                                            <Add />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                           
                        <p className="bold">Assign to</p>
                        {
                          editAssign ?
                          <div>
                            <Button className="editPreviousOrder cancel floatRight" onClick={this.editAssign}>
                            <Cancel />
                            </Button>
                            <Select
                                className="loginInput selectForm productsInput workSans"
                                name="newAssignto"
                                value={newAssignto}
                                onChange={ e => this.onChange(e)}
                                variant="outlined"
                            >
                                {
                                    users.map((user, index) => (
                                        <MenuItem
                                            key={user.id}
                                            className="workSans"
                                            value={user.id}
                                        >
                                            <Avatar className="avatarAssignedTo">
                                                {(user.first_name).charAt(0)}{(user.last_name).charAt(0)}
                                            </Avatar>
                                            {user.full}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            
                            </div>
                          :
                          <p>
                              {taskInfo.assignedTo.first_name + ' ' + taskInfo.assignedTo.last_name}
                            <Button className="editPreviousOrder " onClick={this.editAssign}>
                                <Pencil/>
                            </Button> 
                          </p>
                        }
                    </div>
                    <FormControl className="loginForm m-tb-2">
                        <TextField
                            type="submit"
                            className="yellowSubmit"
                            variant="outlined"
                            value="SAVE"
                            onClick={this.saveEdit}
                        />
                    </FormControl>
                </Grid>
                <Hidden smDown>
                 <Grid item md={3} className="darkBg yellowDotsBg"></Grid>
                </Hidden>
            </Grid>
          
            </Dialog>
        )
    }

}
export default EditTask;