import React,{Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Back from '@material-ui/icons/KeyboardBackspace';
import Cancel from '@material-ui/icons/Clear';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Hidden from '@material-ui/core/Hidden';
import {isAuthenticated} from '../../auth/index';
import {toast} from 'react-toastify';

class NewInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            skus: [],
            companies:[],
            itemsToBill: [],
            company:'',
            amount: 0,
            service: 'RESEARCH',
            skusDesc: '',
            quantity: 1,
            rate: 0,
            description: '',
            total: 0
        }
    }
    
    componentDidMount() {
        this.loadCompanies();
    }
    
    showAllSkus = (company) => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/admin/product/${company}/list`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            }
        })
        .then(res => res.json())
        .then(json => {
            this.setState({
                skus: json,
            });
        })
        .catch(err => console.log(err));
    }
    
    
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        
        if (e.target.name === 'company') {
            this.showAllSkus(e.target.value);
        }
       
    }
    
    removeFromArray = (arrayName, array, position) => {
        let a = array.splice(position, 1);

        console.log(a, 'remove')
        this.setState({
                [arrayName]: array,
                total : this.state.total - a[0].amount
            });
        
      
    };
    
    loadCompanies = () => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/admin/company/list`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
            if(json.error){
                return toast.error(json.error);
            }
            this.setState({
                companies: json
            });
        }).catch(err => console.log(err));
    }
    
    addToBill = () => {
        if (this.state.skusDesc === '') {
            return toast.error('Please select a SKU');
        }
        if (this.state.rate === '' || this.state.rate === 0) {
            return toast.error('Rate must be higher than zero');
        }
        if (this.state.rate === '' || this.state.description === '') {
            return toast.error('Please add a description');
        }
        const items = [
            {
                company: this.state.company,
                service: this.state.service,
                sku: this.state.skusDesc,
                quantity: this.state.quantity,
                description : this.state.description,
                rate: this.state.rate,
                amount: parseFloat(this.state.rate * this.state.quantity)
            }
        ]
        
        const totalVar = parseFloat(this.state.total + parseFloat(this.state.rate * this.state.quantity))
        this.setState({
            itemsToBill: [
                ...this.state.itemsToBill,
                ...items
            ],
            total: totalVar,
            service: 'RESEARCH',
            skusDesc: '',
            quantity: 1,
            rate: 0,
            description: '',
            amount: 0
        });
    }
    
    createInvoice = () => {
        if (this.state.company === '') {
            return toast.error('Please select a company to be billed');
        }
      
        //console.log(this.state.itemsToBill, 'items');
        if (this.state.itemsToBill.length === 0) {
            return toast.error('Please add an item to the bill, click on Add Item');
        }
        
        let body = {
            company: this.state.company,
            items: this.state.itemsToBill,
            total: this.state.total
        };

        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/billing/invoice`, {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
            body: JSON.stringify(body),
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error);
            }

            toast.success(json.message);
            this.setState({
                itemsToBill: [],
                company:'',
                amount: 0,
                service: 'RESEARCH',
                skusDesc: '',
                quantity: 1,
                rate: 0,
                description: '',
                total: 0
            })
        })
        .catch(err => console.log(err));
    }
    
    render() {
        const option = this.props.option
        const {
            company,
            companies,
            itemsToBill,
            service,
            skusDesc,
            skus,
            quantity,
            rate,
            description,
            total
        } = this.state;
        
        return(
            <>
            {
                option === 'new' ? <Grid container className="m-tb-5">
                    <Grid item md={11} xs={11}className="m-lr-auto">
                        <Button onClick={this.props.back} className="cancel"><Back/>Back</Button>
                        <h3>New invoice</h3>
                        <p>Please select a company to bill SKUs from them</p>
                        <FormControl variant="outlined" className="loginForm  m-tb-2">
                            <Select
                                name="company"
                                value={company}
                                variant="outlined"
                                onChange={ e => this.onChange(e)}
                                className="loginInput selectForm workSans"
                            >
                                {
                                    companies.map((comp,index) => (
                                        <MenuItem key={comp._id} value={comp.slug}>{comp.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={11} xs={11} className="m-lr-auto">
                        <div id="billingForm">
                            <Hidden smDown>
                            <div id="billingTitles" style={{'borderBottom': '1px solid #cccccc'}}>
                                <div className="textCenter billingColumn workSans"> <h4>Product/Service</h4></div>
                                <div className="textCenter billingColumn workSans"> <h4>SKU</h4></div>
                                <div className="textCenter billingColumn workSans"> <h4>Description</h4></div>
                                <div className="textCenter billingColumnSM workSans"> <h4>Qty</h4></div>
                                <div className="textCenter billingColumnSM workSans"> <h4>Rate</h4></div>
                                <div className="textCenter billingColumnSM workSans"> <h4>Amount</h4></div>
                                <div className="textCenter billingColumnSM"></div>
                            </div>
                            </Hidden>
                            {
                                itemsToBill.map((item,index) => (
                                    <>
                                    <Hidden smUp >
                                    <div id="billingTitles" key={index}>
                                        <div className="textCenter billingColumn workSans"> <h4>Product/Service</h4></div>
                                        <div className="textCenter billingColumn workSans"> <h4>SKU</h4></div>
                                        <div className="textCenter billingColumn workSans"> <h4>Description</h4></div>
                                        <div className="textCenter billingColumnSM workSans"> <h4>Qty</h4></div>
                                        <div className="textCenter billingColumnSM workSans"> <h4>Rate</h4></div>
                                        <div className="textCenter billingColumnSM workSans"> <h4>Amount</h4></div>
                                        <div className="textCenter billingColumnSM"></div>
                                    </div>
                                    </Hidden>
                                    <div id="billingValues" key={index}>
                                        <div className="billingColumn workSans">
                                            <p className="textCenter">{item.service}</p>
                                        </div>
                                        <div className="billingColumn workSans">
                                            <p className="textCenter">{item.sku}</p>
                                        </div>
                                        <div className="billingColumn workSans">
                                            <p className="textCenter">{item.description}</p>
                                        </div>
                                        <div className="billingColumnSM workSans">
                                            <p className="textCenter">{item.quantity}</p>
                                        </div>
                                        <div className="billingColumnSM workSans">
                                            <p className="textCenter">{item.rate}</p>
                                        </div>
                                        <div className="billingColumnSM workSans">
                                            <p className="textCenter">{item.amount}</p>
                                        </div>
                                        <div className="billingColumnSM workSans">
                                            <span onClick={e => this.removeFromArray('itemsToBill', itemsToBill, index)}><Cancel/></span>
                                        </div>
                                    </div>
                                    </>
                                ))
                            }
                             <Hidden smUp>
                            <div id="billingTitles" >
                                <div className="textCenter billingColumn workSans"> <h4>Product/Service</h4></div>
                                <div className="textCenter billingColumn workSans"> <h4>SKU</h4></div>
                                <div className="textCenter billingColumn workSans"> <h4>Description</h4></div>
                                <div className="textCenter billingColumnSM workSans"> <h4>Qty</h4></div>
                                <div className="textCenter billingColumnSM workSans"> <h4>Rate</h4></div>
                                <div className="textCenter billingColumnSM workSans"> <h4>Amount</h4></div>
                                <div className="textCenter billingColumnSM"></div>
                            </div>
                            </Hidden>
                            <div id="billingInputs">
                                <div className="billingColumn workSans">
                                    <Select
                                        name="service"
                                        value={service}
                                        variant="outlined"
                                        onChange = { e => this.onChange(e)}
                                        className= "selectBilling loginInput workSans"
                                    >
                                        <MenuItem value='Research'>Research</MenuItem>
                                        <MenuItem value='Design'>Design</MenuItem>
                                        <MenuItem value='Price Quote'>Price Quote</MenuItem>
                                        <MenuItem value='Pre-production'>Pre-production</MenuItem>
                                        <MenuItem value='Production Management'>Production Management</MenuItem>
                                        <MenuItem value='Product'>Product</MenuItem>
                                        <MenuItem value='Shipping'>Shipping</MenuItem>
                                        <MenuItem value='Expense'>Expense</MenuItem>
                                    </Select>
                                </div>
                                <div className="billingColumn workSans">
                                    <Select
                                        name="skusDesc"
                                        value={skusDesc}
                                        variant="outlined"
                                        onChange={ e => this.onChange(e)}
                                        className="selectBilling loginInput workSans"
                                    >
                                        {
                                            skus.map((comp,index) => (
                                                <MenuItem key={index} value={comp.name}>{comp.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </div>
                                <div className="billingColumn workSans">
                                        <input
                                        type="text"
                                        name="description"
                                        onChange = {e => this.onChange(e)}
                                        value = {description}

                                    />
                                </div>
                                <div className="billingColumnSM workSans">
                                    <input
                                        type="number"
                                        min="1"
                                        name="quantity"
                                        onChange = {e => this.onChange(e)}
                                        value = {quantity}
                                        steps="1"
                                    />
                                </div>
                                <div className="billingColumnSM workSans">
                                    <input
                                        type="number"
                                        min="1"
                                        name="rate"
                                        onChange = {e => this.onChange(e)}
                                        value={rate}
                                    />
                                </div>
                                <div className="billingColumnSM workSans textRight">
                                    <span >{parseFloat(this.state.rate * this.state.quantity)}</span>
                                </div>
                                <div className="billingColumnSM workSans"></div>
                            </div>
                            <button variant="outlined" className="logOutAdmin" onClick = {this.addToBill}>
                                Add Item to Billing
                            </button>
                        </div>
                      
                        <div className="floatRight">
                            <h3 className="workSans m-lr-5 m-tb-2">Total:</h3>
                            <input
                                type="number"
                                name="total"
                                className="textRight"
                                style={{'color': '#bd2d00', 'fontSize':'20px', 'fontWeight':'bold'}}
                                value={total.toFixed(2)}
                                readOnly
                            />
                        </div>
                    </Grid>
                    <Grid item md={11} xs={11} className="m-lr-auto">
                        <FormControl className="billingButton m-t-2">
                            <Button name="paid" className="yellowSubmit" variant="outlined" onClick={this.createInvoice}>
                                Send Invoice
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid> : null
            }
        </>
        )
    }
}
export default NewInvoice;
