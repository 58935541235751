import React , {Component} from 'react';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { MenuList } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { isAuthenticated } from '../../auth';
import EditTask from './EditTask';
import {toast} from 'react-toastify';

class DotsTasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            project: '',
            openEditTask : false,
            taskToDelete : '',
            openConfirmation : false
        };

        this.closeEdit = this.closeEdit.bind(this);
    }
    
    openEdit = () =>{
        this.setState({
            openEditTask : true
        });
    }
    closeEdit = () => {
        this.setState({
            openEditTask : false
        })
    }
   
    downloadAttachments = () => {
        
        const attachments = this.props.data.files;
       
        if(attachments.lenght !== 0){
            attachments.forEach(function (value, idx) {
                const response = {
                  file: value.path,
                };
                setTimeout(() => {
                    window.location.href = response.file;
                }, idx * 1000)
            })
        }
    }
    
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    
    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    deleteTask = () => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/task/${this.state.taskToDelete}`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error)
            }
            this.closeConfirmationDelete();
            this.props.closeTask();
            toast.success('Task deleted succesfully');
          
        })
        .catch(err => console.log(err));
    }
    closeConfirmationDelete = () => {
        this.setState({
            openConfirmation: false,
            taskToDelete:''
        })
    }
    openConfirmationDelete = (skuSlug) => {
        this.setState({
            openConfirmation : true,
            taskToDelete : skuSlug
        })
    }
    reloadTask = (data) => {
        this.props.reloadTask(data)
    }
    render() {
        const {
            anchorEl,
            openConfirmation
        } = this.state;

        return(
            <div className="floatRight">
                <IconButton aria-controls="menu-dots" aria-haspopup="true" onClick={this.handleClick}>
                    <MoreHoriz />
                </IconButton>
                <Popper
                    id="task-dots"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    transition
                    disablePortal
                >
                    {
                        ({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                id="menu-dots-grow"
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'left'}}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={ this.handleClose }>
                                        <MenuList>
                                            <MenuItem className="workSans" onClick={this.downloadAttachments}>   
                                                Download all task attachments(s)
                                            </MenuItem>
                                            {
                                                isAuthenticated().role === 'administrator' || isAuthenticated().role === 'staff' ?
                                                <MenuItem className="workSans" onClick={this.openEdit}>
                                                    Edit Task
                                                </MenuItem>
                                                :
                                                null
                                            }
                                            {
                                                isAuthenticated().role === 'administrator' || isAuthenticated().role === 'staff'?
                                                <MenuItem  className="workSans" onClick={e => this.openConfirmationDelete(this.props.data.slug)}>
                                                    Delete Task
                                                </MenuItem>
                                                :
                                                null
                                            }
                                            
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )
                    }
                </Popper>
                {
                    isAuthenticated().role === 'administrator' || isAuthenticated().role === 'staff' ?
                    <EditTask
                        open={this.state.openEditTask}
                        close={this.closeEdit}
                        taskInfo={this.props.data}
                        reloadTaskInfo={ this.reloadTask }
                    />
                    :
                    null
                }
               
               <Dialog
                    open={openConfirmation}
                    onClose={this.closeConfirmationDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">
                    <p className="m-0">Delete?</p>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description" className="workSans">
                    Are you sure you want to delete a Task?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions  >
                    <Button onClick={this.closeConfirmationDelete}  className="actionsConfirmation">
                        Cancel
                    </Button>
                    <Button onClick={this.deleteTask}  className="actionsConfirmation" autoFocus>
                        Delete
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default DotsTasks;