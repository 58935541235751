export const lt = (value, props) => {
    // get the maxLength from component's props
    if (value > props) {
        // Return jsx
        return true
    }
    return false;
};


export const required = (value, name) => {
    if (!value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return `${name} is required`;
    }
    return false;
};

export const isInt = (value) => {
    var n = Math.floor(Number(value));
    var check = String(n) === value && n >= 0;
    if (check) {
        return false;
    }
    return true;
};

