import React, {Component} from 'react';
import DesignIcon from '../../icons/DesignIcon';
import PreProductionIcon from '../../icons/PreProductionIcon';
import ProductionIcon from '../../icons/ProductionIcon';
import ShippingIcon from '../../icons/ShippingIcon';
import FulfilledIcon from '../../icons/FulfilledIcon';
import Hidden from '@material-ui/core/Hidden';

class LifecycleBar extends Component {
    constructor(props) {
        super();
        this.state = {
           numberDesign:'',
           numberPre: '',
           numberProd: '',
           numberShipp: '',
           numberFulfilled: ''
        };
    }
    
    render() {
        const productsArray =  this.props.productArray;
        
        let preprod = productsArray.filter( function(pre){         
            return pre.status === 'PRE-PRODUCTION';
        });
        preprod = preprod.length;
       
        let design = productsArray.filter( function(design){         
            return design.status === 'DESIGN';
        });
        design = design.length;
        let prod = productsArray.filter( function(prod){         
            return prod.status === 'PRODUCTION';
        });
        prod = prod.length;
        let shipping = productsArray.filter( function(shipping){         
            return shipping.status === 'SHIPPING';
        });
        shipping = shipping.length;
        let fulfilled = productsArray.filter( function(fulfilled){         
            return fulfilled.status === 'FULFILLED';
        });
        fulfilled = fulfilled.length;
        return(
            <div id="lifecycleBar" className="m-tb-2">
                <div className="flex" id="containerStages"> 
                    <div id="design" className="lifecycleInfo workSans flex" style={{'border': '2px solid #FFE6A2'}}>
                        <Hidden smDown>
                        <DesignIcon color="#000000"/>
                        </Hidden>
                        
                        <p className="inlineBlock textCenter"> Design</p>
                        <div style={{'backgroundColor':'#FFDE84'}}></div><span>{design}</span>
                    </div>
                    <div id="pre-production" className="lifecycleInfo workSans flex" style={{'border': '2px solid #FEC72F'}}>
                        <Hidden smDown>
                        <PreProductionIcon color="#000000"/>
                        </Hidden>
                        
                        <p className="inlineBlock textCenter"> Pre-Production</p>
                        <div style={{'backgroundColor':'#FEC72F'}}></div><span>{preprod}</span>
                    </div>
                    <div id="production" className="lifecycleInfo workSans flex" style={{'border': '2px solid #EC9172'}}>
                        <Hidden smDown>
                        <ProductionIcon color="#000000"/>
                        </Hidden>
                        
                        <p className="inlineBlock textCenter"> Production</p>
                        <div style={{'backgroundColor':'#EC9172'}}></div><span>{prod}</span>
                    </div>
                    <div id="shipping" className="lifecycleInfo workSans flex" style={{'border': '2px solid #F9612E'}}>
                        <Hidden smDown>
                        <ShippingIcon color="#000000"/>
                        </Hidden>
                        
                        <p className="inlineBlock textCenter"> Shipping</p>
                        <div style={{'backgroundColor':'#F9612E'}}></div><span>{shipping}</span>
                    </div>
                    <div id="fulfilled" className="lifecycleInfo workSans flex" style={{'border': '2px solid #0DD4AA'}}>
                        <Hidden smDown>
                        <FulfilledIcon color="#000000"/>
                        </Hidden>
                        
                        <p className="inlineBlock textCenter"> Fulfilled</p>
                        <div style={{'backgroundColor':'#0DD4AA'}}></div><span>{fulfilled}</span>
                    </div>
                </div>
            </div>
        )
    }
}
export default LifecycleBar