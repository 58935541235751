import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {
    Grid,
    Hidden
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress'
import NextButton from '../NextButton';
import BackButton from '../BackButton';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { uploadFiles } from '../../../services/upload';
import { lt } from '../validator';
import Cancel from '@material-ui/icons/Clear';

class Packaging extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoadingPackaging: false,
            productsToUploadPackage: [],
            showUploadPackage: false,
            returnTo: ''
        }
    }

    componentDidMount() {
        this.productPackagingData = new FormData();
        this.checkValues()
    }

    checkValues = () => {
        const {
            valueProductsToUploadPackaging,
            valueGetPackagingFrom
        } = this.props;
        this.setState({
            returnTo: valueGetPackagingFrom,
            productsToUploadPackage: valueProductsToUploadPackaging
        })
    }

    onChange = (e) => {
        if (e.target.name === 'productpackaging') {
			this.setState({
				isLoadingPackaging: true,
			})
			const filesData = new FormData();
			for (const file of e.target.files) {
				filesData.append('files', file, file.name);
			}
			e.target.value = null;
            uploadFiles(filesData).then(data => {
                this.setState({
					productsToUploadPackage: [
						...this.state.productsToUploadPackage,
						...data.files
					],
					isLoadingPackaging : false,
				});
				this.productPackagingData.set('files', this.state.productsToUploadPackage);
            })
        }

        this.setState({
            [e.target.name]: e.target.value
        });
    }

    removeFile = (arrayName, array, position) => {
		array.splice(position, 1);
		this.setState({
			[arrayName]: array
		});
    };
    
    showUploadInputPackage = () => {
		this.setState({
			showUploadPackage: true,
		});
    }
    
    validateAndNext = () => {
        const {
            productsToUploadPackage
        } = this.state;

        if (lt(productsToUploadPackage.length, 0)) {
            return this.props.sendPackaging({
                showPackaging: false,
                showWarehouse: true,
                filesToUploadPackaging: productsToUploadPackage
            });
        }
        this.nextWithoutPackaging();
    }

    nextWithoutPackaging = () => {
        this.props.sendPackaging({
            showPackaging: false,
            showWarehouse: true,
        });
    }

    returnTo = () => {
        if (this.state.returnTo === 'Design') {
            this.props.returnTo({
                showDesign: true,
                showPackaging: false,
                complete: false
            });
        } else {
            this.props.returnTo({
                showMaking: true,
                showPackaging: false,
                complete: false
            });
        }
    }
    
    render() {
        const {
            isLoadingPackaging,
            productsToUploadPackage,
            showUploadPackage
        } = this.state;
        return(
            <Grid container id="productDesignContainer">
                <Grid item md={8} sm={12} className="grayBg p-lr-5 p-tb-2">
                    <BackButton onClick={ this.returnTo }/>
                    <Hidden smUp>
                    <span className="floatRight"><Cancel onClick={this.props.closeAll} className="cancel" /></span>
                    </Hidden>
                    <h3 className="m-tb-2">PACKAGING</h3>
                    <p>If you have packaging you want to use, please upload files below.</p>
                    {
                        isLoadingPackaging ? <div style={{width: '100px'}}><CircularProgress/></div> : productsToUploadPackage.map((file, index) => {
                                return(
                                    <div key={index}>
                                        <p className="repeaterFields">{file.name}</p>
                                        <span className="cancel">
                                            <RemoveCircle onClick={e => this.removeFile('productsToUploadPackage', productsToUploadPackage, index)} />
                                        </span>
                                    </div>
                                )
                            })
                    }
                    {
                        showUploadPackage ?
                            <FormControl className="loginForm">
                                <input
                                    type="file"
                                    id="filePackaging"
                                    multiple
                                    name="productpackaging"
                                    accept="application/pdf,image/jpeg,image/png,image/svg+xml,application/msword,image/vnd.adobe.photoshop,application/postscript,application/x-photoshop"
                                    onChange={ e => this.onChange(e) }
                                    variant='outlined'
                                />
                            </FormControl> : ''
                    }
                    <FormControl className="loginForm m-t-2">
                        <TextField
                            type="submit"
                            className="yellowSubmit"
                            variant="outlined"
                            value="UPLOAD FILE(S)"
                            onClick={ this.showUploadInputPackage }
                        />
                    </FormControl>
                    <p>If you aren't sure what packaging you want, click the button below.</p>
                    {
                        productsToUploadPackage.length > 0 ? <NextButton onClick={ this.validateAndNext } />  : <FormControl className="loginForm m-t-2">
                                <TextField
                                    type="submit"
                                    className="yellowSubmit"
                                    variant="outlined"
                                    value="I DON'T HAVE PACKAGING YET"
                                    onClick={ this.nextWithoutPackaging }
                                />
                            </FormControl>
                    }
                </Grid>
                <Hidden>
                    <Grid item md={4} className="darkBg yellowDotsBg"></Grid>
                </Hidden>
            </Grid>
        )
    }
}

export default Packaging;