import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
    Grid,
    Hidden
} from "@material-ui/core";
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import NextButton from '../NextButton';
import BackButton from '../BackButton';
import { ProductTextField } from '../styles';
import TextField from '@material-ui/core/TextField';
import { isUrl, required, lt } from '../validator';
import { toast } from 'react-toastify';
import Cancel from '@material-ui/icons/Clear';

class Making extends Component {
    constructor(props) {
        super(props)
        this.state = {
            making: [],
            producturl: '',
            errorproducturl: false,
            productdiffer: '',
            errorproductdiffer: false
        }
    }

    componentDidMount() {
        this.checkValues()
    }

    checkValues = () => {
        const {
            valuesMaking,
            productDiffer
        } = this.props;
        this.setState({
            making: valuesMaking,
            productdiffer : productDiffer
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    addSimilarProduct = () => {
        this.setState({
            errorproducturl: false,
            errorproductdiffer: false
        });
        
        if (required(this.state.producturl, 'URL')) {
            this.setState({
                errorproducturl: true
            });
            return toast.error(required(this.state.producturl, 'URL'));
        }

        if (!isUrl(this.state.producturl)) {
            return toast.error('URL Invalid')
        }

        
        const similarProduct = [
            {
                url: this.state.producturl,
            }
        ];
        let addUrlMaking = [
            ...this.state.making,
            ...similarProduct
        ] 
        //console.log(addUrlMaking, 'similar product ');
        this.setState({
            making: addUrlMaking,
            producturl: '',
        });
        
        //console.log(this.state, 'similar product function');
    }

    removeFromArray = (arrayName, array, position) => {
		array.splice(position,1);
		this.setState({
			[arrayName]: array
		});
    };
    
    validateAndNext = () => {
        const {
            making,
            productdiffer,
        } = this.state;
       
       let addUrlMaking = making;
        if (this.state.producturl !== '') {
            if (required(this.state.producturl, 'URL')) {
                this.setState({
                    errorproducturl: true
                });
                return toast.error(required(this.state.producturl, 'URL'));
            }
    
            if (!isUrl(this.state.producturl)) {
                return toast.error('URL Invalid')
            }
    
            const similarProduct = [
                {
                    url: this.state.producturl,
                }
            ];
            addUrlMaking = [
                ...this.state.making,
                ...similarProduct
            ] 
    
        }
        
        if (!lt(making.length, 0) && this.state.producturl === '') {
            return toast.error("Please provide a url of a similar product");
        }
        if (required(productdiffer, 'How would/ does your product differ?')) {
            this.setState({
                errorproductdiffer: true
            });
            return toast.error(required(productdiffer, 'How would/ does your product differ?'));
        }

        if (!lt(productdiffer.length, 2)) {
            this.setState({
                errorproductdiffer: true
            });
            return toast.error('How would/ does your product differ is too short? Invalid');
        }
      
        this.props.sendMaking({
            similarProducts: addUrlMaking,
            productdiffer: productdiffer,
            showMaking: false,
            showPackaging: true,
            getPackagingFrom: 'Making'
        });
        //console.log(this.state.making, 'making url');
    }

    returnToDesign = () => {
        this.props.returnTo({
            showDesign: true,
            showMaking: false,
            complete: false
        });
    };
    
    render() {
        const {
            making,
            producturl,
            errorproducturl,
            productdiffer,
            errorproductdiffer
        } = this.state;
      
        return(
            <Grid container>
                <Grid item md={8} sm={12} className="grayBg p-lr-5 p-tb-2">
                    <BackButton onClick={ this.returnToDesign } />
                    <Hidden smUp>
                    <span className="floatRight"><Cancel onClick={this.props.closeAll} className="cancel" /></span>
                    </Hidden>
                    <h3 className="m-tb-2 ">WHAT ARE YOU MAKING</h3>
                    <p>Tell us as much as you can about the product you want to make</p>
                    {
                        making.length ? 
                            making.map((data,index) => {
                                return(
                                    <div key={index}>
                                        <a href={data.url} rel="noopener noreferrer" target="_blank" className="linkMaking">{data.url}</a>
                                        <span className="cancel">
                                            <RemoveCircle
                                                onClick={e => this.removeFromArray('making', making, index)}
                                            />
                                        </span>
                                    </div>
                                )
                            }) : ''
                    }
                    <FormControl className="loginForm m-t-5">
                        <p className="bold">Similar product</p>
                        <p>Copy and paste a URL of a product similar to the one you want to make.</p>
                        <ProductTextField
                            type="url"
                            name='producturl'
                            value={ producturl }
                            onChange={ e => this.onChange(e)}
                            placeholder='https://'
                            variant='outlined'
                            className={ errorproducturl ? 'errorClass' : ''}
                        />
                    </FormControl>
                    <FormControl className="loginForm">
                        <p className="bold m-tb-2">How would/ does your product differ?</p>
                        <ProductTextField
                            type='text'
                            name='productdiffer'
                            multiline={true}
                            rows={4}
                            value={ productdiffer }
                            onChange={ e => this.onChange(e)}
                            variant='outlined'
                            className={ errorproductdiffer ? 'errorClass' : '' }
                        />
                    </FormControl>
                    <FormControl className="loginForm m-tb-2">
                        <TextField
                            type="submit"
                            className="yellowSubmit"
                            variant="outlined"
                            value="ADD ANOTHER SIMILAR PRODUCT"
                            onClick = {this.addSimilarProduct}
                        />
                    </FormControl>
                    <NextButton onClick={ this.validateAndNext } />
                </Grid>
                <Hidden>
                    <Grid item md={4} className="darkBg yellowDotsBg"></Grid>
                </Hidden>
            </Grid>
        )
    }
}

export default Making;