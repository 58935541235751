import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import {
    TextField,
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core';
import Back from '@material-ui/icons/KeyboardBackspace';
import { isAuthenticated } from '../../auth/index';
import { toast } from 'react-toastify';
import Hidden from '@material-ui/core/Hidden';
import Cancel from '@material-ui/icons/Clear';

class Source3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            productFinished: '',
        }
    }

    componentDidMount() {
        this.finishedProducts();
    }

    onChangeRadio = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    finishedProducts = () => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/${isAuthenticated().companyActually.slug}/list`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            }
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error);
            }
            const products = json.filter(finish => finish.status === "FULFILLED" && finish.isReorder === false);
            this.setState({
                products: products
            });
        })
        .catch(err => console.log(err));
    }

    submitFinishedProduct = () => {
        if (this.state.productFinished !== '') {
            fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/reorder/${isAuthenticated().companyActually.slug}/${this.state.productFinished}`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${isAuthenticated().token}`,
                },
                body: JSON.stringify({
                    status : 'PRE-PRODUCTION'
                }),
            })
            .then(res => res.json())
            .then(json => {
                //console.log(json);
                this.setState({
                    products: [],
                    productFinished: ''
                });
                this.props.completeProcessSource({
                    completeProcess: true,
                    start: false,
                    finishedProducts: false,
                    helpMeSource: false,
                    product_link: json.product_link,
                    product: json.product,
                    isHelpMeFind : false
                });
            })
            .catch(err => console.log(err));
           
        } else {
            toast.error('Choose a product');
        }
    }

    backScreen = () => {
        this.props.goTo({
            helpMeSource: false,
            withGembah: true,
            finishedProducts: false
        });
    }

    render() {
        const {
            products,
            productFinished
        } = this.state;
        return (
            <Grid container >
                <Grid item sm={12} className="grayBg p-lr-5 p-tb-2 helpmeSource" >
                    <span className="cancel" onClick={this.backScreen}><Back/></span>
                    <Hidden smUp>
                    <span className="floatRight"><Cancel onClick={this.props.closeAll} className="cancel" /></span>
                    </Hidden>
                    <div className="m-tb-5 m-lr-5 textCenter">
                    <h3 className="p-lr-5 ">Help me Source</h3>
                    <p className="p-lr-5 m-lr-10">The list is all the products you've manufactured
                    with Gembah. Select a product you'd like sourcing help with.
                    </p>
                    {
                        Object.keys(products).length ?
                            <div>
                                <FormControl variant="outlined" className="loginForm p-lr-5">
                                 
                                    <Select
                                        name="productFinished"
                                        variant="outlined"
                                        value={productFinished}
                                        className="loginInput selectForm productsInput workSans m-tb-5"
                                        onChange={e => this.onChangeRadio(e)}
                                    >
                                        {
                                            products.map((finish, index) => {
                                                return(
                                                    <MenuItem
                                                        key={index}
                                                        className="workSans"
                                                        value={finish.slug}
                                                    >
                                                        <span>{finish.name}</span>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl className="loginForm m-t-2 p-lr-5">
                                    <TextField
                                        type="submit"
                                        className="yellowSubmit"
                                        variant="outlined"
                                        value="NEXT"
                                        onClick={this.submitFinishedProduct}
                                    />
                                </FormControl>
                            </div> : < p className="account">Currently you don't have finished products</p>
                    }
                    </div>
                </Grid>
            </Grid>
        );
    }
};

export default Source3;