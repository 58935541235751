import React, { Component } from 'react';
import { isAuthenticated } from '../../auth';
import Assign from '../../assets/img/Group.png';
import Checked from '../../assets/img/Checked.png';
import { Link } from 'react-router-dom';
import dompurify from 'dompurify';

class TaskDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }
    
    toggleClass(status)  {
        switch (status) {
            case "DESIGN" : return 'statusDesign';
            case "PRE-PRODUCTION" : return 'statusPre';
            case "PRODUCTION" : return 'statusProd';
            case "SHIPPING" : return 'statusShipping';
            case "FULFILLED" : return 'statusFulfilled';
            default : return 'statusDesign';
        }
    }

    render() {
        const {
            task,
            onProduct
        } = this.props;
        const sanitize = dompurify.sanitize;
        return(
            <>
                {
                    onProduct ? 
                    <li className="cursorPointer">
                        <span className="assignedSquare">
                            {
                                task.isCompleted ? <img src={Checked} alt=""></img> :
                                    task.assignedTo.first_name === isAuthenticated().first_name ? <img src={Assign} alt=""/> : <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1" y="1" width="24" height="24" stroke="#DEDADA"/>
                                    </svg>
                            }
                        </span>
                        <Link
                            to={{
                                pathname: `/${isAuthenticated().companyActually.slug}/${task.productOwner.slug}/t/${task.slug}`,
                                state: { modal: true },
                            }}
                            className="link"
                        >
                            <p>{ task.name }</p>
                        </Link>
                        <div className="inlineBlock commentsOnProduct">
                            <span>
                                <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1 1H9V7H4L1 9L1 1Z" stroke="#9998AF" strokeWidth="2"/>
                                </svg>
                            </span>
                            <span className="account workSans bold ">{ (task.comments).length }</span>
                            <span className="account workSans">Comments</span>
                        </div>
                        <div className="inlineBlock p-lr-2">
                            <span>
                                <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 5H8L4 1" stroke="#9998AF" strokeWidth="2"/>
                                </svg>
                            </span>
                            <span className="account workSans">Assigned to </span>
                            <span className="account workSans bold">{ task.assignedTo.first_name }</span> 
                        </div>
                        <div className="floatRight flex commentsOnProduct">
                            {
                                task.isCompleted ? <span className="account workSans capitalize">Completed</span> : <span className="account workSans capitalize">{(task.status).toLowerCase()}</span>
                            }
                            {
                                task.isCompleted ? <div className="taskStatus statusFulfilled"></div> : <div className={`taskStatus ${this.toggleClass(task.status)}`}></div>
                            }
                        </div>
                    </li> 
                    :  
                    <li className={ task.isCompleted ? 'completedTaskSidebar ' : ''}>
                        <Link
                            to={{
                                pathname: `/${isAuthenticated().companyActually.slug}/${task.productOwner.slug}/t/${task.slug}`,
                                state: { modal: true },
                            }}
                            className="link"
                        >
                            <p dangerouslySetInnerHTML={{__html: sanitize(task.name)}}></p>
                        </Link>
                        <div className="account workSans m-tb-2">
                            {
                               task.isCompleted ? <div className="taskStatus statusFulfilled"></div> : <div className={`taskStatus ${this.toggleClass(task.status)}`}></div> 
                            }
                           
                            Created by { task.createdBy.first_name }
                        </div>
                    </li>
                }
            </>
        )
    }
}

export default TaskDetails;