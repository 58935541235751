import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Pencil from '@material-ui/icons/Create';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import {isAuthenticated} from '../../auth/index';
import { toast } from 'react-toastify';
import { MenuItem } from '@material-ui/core';

class UpdateCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersInCompany : [],
            staffInCompany:[],
            editingStaff: false,
            loadInfo : false,
            newStaffs :[],
            availableStaffs : [],
            newStaffName : '',
            addStaff: this.props.company.staff
        }
    }

    componentDidMount() {
        this.getEmployees();
        this.getStaffs();
    }

    getEmployees = () => {
        this.setState({
            loadInfo: true
        });

        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/company/${this.props.company.slug}/employees`, {
            method : 'GET',
            cors: 'no-cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error);
            }
            let users = json.filter( users => users.role === 'user');
            let staffs = json.filter( staff => staff.role === 'staff');
            this.setState({
                usersInCompany: users,
                staffInCompany: staffs,
                newStaffs: staffs,
                loadInfo: false
            });
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    removeFromArray = (arrayName, array, position) => {
		array.splice(position,1);
		this.setState({
			[arrayName]: array
		});
    };

    getStaffs = () => {
		fetch(`${process.env.REACT_APP_DOMAIN_BACK}/admin/users/staff`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
			if (json.error) {
				return toast.error(json.error);
            }
            this.setState({
                availableStaffs: json,
            });
        }).catch(err => console.log(err));
    }
    
    addStaff = () => {
        const {
            availableStaffs,
            newStaffs
        } = this.state;
        if (this.state.newStaffName !== '') {
            let checkStaff = newStaffs.filter(newStaff => newStaff.id === this.state.newStaffName);
            if (checkStaff.length > 0) {
                return toast.error('This staff has already been added');
            }
            let newavailableStaffs = availableStaffs.filter(availableStaff => availableStaff._id === this.state.newStaffName);
            const {
                _id,
                first_name,
                last_name,
                role
            } = newavailableStaffs[0];
            let newavailableStaff = {
                id: _id,
                display: first_name,
                first_name,
                last_name,
                role: role,
                full: `${first_name} ${last_name}`
            }
            this.setState({
                newStaffs: [
                    ...this.state.newStaffs,
                    newavailableStaff
                ],
                addStaff: [
                    ...this.props.company.staff,
                    this.state.newStaffName
                ],
                newStaffName: '',
            });
        }
    }

    updateCompany = () => {
        const bodyRequest = {
            staff: this.state.addStaff
        };
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/company/${this.props.company.slug}/update`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
            body: JSON.stringify(bodyRequest),
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error);
            }
            toast.success(json.message)
        }).catch(err => console.log(err));
    }

    editStaff = () => {
        this.setState(prevState => ({
            editingStaff: !prevState.editingStaff
        }));
    }

    render() {
        const { company } = this.props;
        const {
            usersInCompany,
            staffInCompany,
            loadInfo,
            editingStaff,
            newStaffs,
            newStaffName,
            availableStaffs
        } = this.state;
        return(
            <Grid container>
                <Grid item  xs={11} className="m-lr-auto m-tb-2">
                    <span className="cancel " onClick={this.props.close}>CANCEL</span>
                    <h1>Update {company.name} Information</h1>
                    <p className="bold">Owner</p>
                    {
                        company.owner ?
                        <p className="bills p-10-x">{company.owner.first_name} {company.owner.last_name}</p>
                        :
                        <p className="account">Not assigned</p>
                    }
                    <p className="bold">Users</p>
                    {
                        loadInfo ? <CircularProgress /> : usersInCompany.length !== 0 ? usersInCompany.map((employee, index) => (
                            <p key={index} className="bills p-10-x capitalize">{employee.full}</p>
                        )) : <p className="account"> This company doesn't have any user </p>
                    }
                    <p className="bold">Staff <Button className="editPreviousOrder" onClick={this.editStaff}><Pencil/></Button></p>
                    {
                        editingStaff && newStaffs.length !== 0 ? newStaffs.map((staff, index) => (
                            <p key={index} className="editAdminUser">{staff.full} <Button className="editPreviousOrder cancel floatRight" onClick={e => this.removeFromArray('newStaffs',newStaffs,index)}><RemoveCircle/></Button></p>
                        )) : null
                    }
                    {
                        !editingStaff ? loadInfo ? <CircularProgress /> : staffInCompany.length !== 0 ? staffInCompany.map((staff, index) => (
                            <p key={index} className="bills p-10-x capitalize">{staff.full}</p>
                        )) : <p className="account"> This company doesn't have any staff </p> : <FormControl className="loginForm">
                            <Select
                                className="loginInput selectForm productsInput workSans"
                                name="newStaffName"
                                value={newStaffName}
                                onChange={ e => this.onChange(e)}
                                variant="outlined"
                            >
                                {
                                    availableStaffs.map((available, index) => (
                                        <MenuItem
                                            key={index}
                                            className="workSans"
                                            value={available._id}
                                        >
                                            {available.first_name} {available.last_name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            <button variant="outlined" onClick={e => this.addStaff() } className="logOutAdmin m-t-2">Add Staff to company</button>
                        </FormControl>
                    }
                    <FormControl className="loginForm m-tb-2">
                        <TextField
                            type="submit"
                            className="yellowSubmit"
                            variant="outlined"
                            value="SAVE"
                            onClick={e => this.updateCompany()}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
}

export default UpdateCompany;