import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    CircularProgress,
    Hidden
} from '@material-ui/core';
import Back from '@material-ui/icons/KeyboardBackspace';
import { isAuthenticated } from '../../auth/index';
import { toast } from 'react-toastify';
import Cancel from '@material-ui/icons/Clear';

class Reorder1 extends Component {

    constructor(props){
        super(props)
        this.state = {
            reorderProducts : [],
            loading : false
        }
      
    }
    componentDidMount() {
        this.finishedProducts();
    }
    onChange = (e) => {
        this.props.goTo({
           selectReorder : e.target.value
        });
    }
    returnToStartMaking = () => {
        this.props.returnTo({
        helpmeReorder:true,
        finishedProducts: false
        });
    }

   
    nextScreen = () => {
        if (this.props.selectReorder !== '') {
            this.props.goTo({
                helpmeReorder:false,
                previousOrder : true,
            });
        } else {
            return toast.error('Choose a valid option');
        }
    }
    
    finishedProducts = () => {
        this.setState({
            loading:true
        });
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/${isAuthenticated().companyActually.slug}/list`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            }
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error);
            }
            //console.log(json);
            const products = json.filter(finish => finish.status === "FULFILLED" && finish.isReorder === false );
            this.setState({
                reorderProducts: products,
                loading:false
            });
            
        })
        .catch(err => console.log(err));
    }

    render(){
        const {
            reorderProducts,
            loading
        } = this.state;
        return(
            <Grid container>
                <Grid item  s={12} className="grayBg p-lr-5 p-tb-2">
                    <span className="cancel" ><Back onClick={this.returnToStartMaking}/></span>
                    <Hidden smUp>
                    <span className="floatRight"><Cancel onClick={this.props.closeAll} className="cancel" /></span>
                    </Hidden>
                    <h3 className="p-lr-5 textCenter">Help me Reorder</h3>
                    <p className="p-lr-5 m-lr-10">
                        The list is all the products you've manufactured with Gembah. Select a product you’d like sourcing help with.
                    </p>
                    {
                        loading ? <div style={{width: '100px', margin:'auto'}}><CircularProgress/></div> :
                            Object.keys(reorderProducts).length ? <FormControl variant="outlined" className="loginForm p-lr-5">
                                <Select
                                    value={this.props.selectReorder}
                                    name='reorderSelect'
                                    variant="outlined"
                                    className="loginInput selectForm productsInput workSans m-tb-5"
                                    onChange={ e => this.onChange(e) }
                                >
                                    {
                                        reorderProducts.map((fulfProducts, index) => (
                                            <MenuItem
                                                key={index}
                                                className="workSans"
                                                value={fulfProducts.slug}
                                            >
                                                {fulfProducts.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl> : <p className="textCenter account">No fulfilled products available</p>
                    }
                    
                    <FormControl className="loginForm m-t-2 p-lr-5">
                        <TextField
                            type="submit"
                            className="yellowSubmit"
                            variant="outlined"
                            value="NEXT"
                            onClick = { this.nextScreen}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
}

export default Reorder1;