import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
    Grid,
    Select,
    MenuItem
} from "@material-ui/core";
import BackButton from '../BackButton';
import { ProductTextField } from '../styles';
import NextButton from '../NextButton';
import { getCategories } from './functions';
import { required, lt } from '../validator';
import { toast } from 'react-toastify';
import Hidden from '@material-ui/core/Hidden';
import Cancel from '@material-ui/icons/Clear';

class Product extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productName: '',
            errorProductName: false,
            productCategory: '',
            errorCategory: false,
            categories: [],
        }
    }

    componentDidMount() {
        this.getCategories()
        this.checkValues()
    }

    checkValues = () => {
        const {
            valueProductName,
            valueProductCategory
        } = this.props;

        if (valueProductName !== '') {
            this.setState({
                productName: valueProductName
            })
        }

        if (valueProductCategory !== '') {
            this.setState({
                productCategory: valueProductCategory
            })
        }
    }

    onChange = (e) => {
        this.setState({
			[e.target.name]: e.target.value
		});
    }

    getCategories = () => {
        getCategories().then(data => {
            this.setState({
                categories: data
            });
        });
    }
    
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    validateAndNext = () => {
        this.setState({
            errorProductName: false,
            errorCategory: false
        });

        if (required(this.state.productName, 'Product Name')) {
            this.setState({
                errorProductName: true
            });
            return toast.error(required(this.state.productName, 'Product Name'));
        }

        if (!lt(this.state.productName.length, 2)) {
            this.setState({
                errorProductName: true
            });
            return toast.error('Product Name Invalid');
        }

        if (required(this.state.productCategory, 'Category')) {
            this.setState({
                errorCategory: true
            });
            return toast.error(required(this.state.productCategory, 'Category'))
        }
        
        const {
            productName,
            productCategory
        } = this.state;
    
        this.props.sendProduct({
            productName,
            productCategory,
            showProduct: false,
            showDetails: true,
        });
    }

    cancelProduct =() => {
        this.props.cancelProduct({
            productName: '',
            productCategory: '',
            newProductModal: false
        });
        this.props.noStart();
    }

    returnToStartMaking = () => {
        this.props.returnTo({
            startMakingModal: true,
            complete: false
        });
    };
    
    render() {
        const {
            productName,
            errorProductName,
            productCategory,
            errorCategory,
            categories
        } = this.state;
        return(
            <Grid container className="p-lr-5 p-tb-2">
                <BackButton onClick={ this.returnToStartMaking }/>
                <Hidden smUp>
                    <span style={{'position':'absolute', 'right':'10px'}}><Cancel onClick={this.props.closeAll} className=" cancel" /></span>
                </Hidden>
                <Grid item xs={12} className="grayBg  p-lr-10">
                    <section className="p-lr-10">
                        <div className="p-lr-15">
                            <div className='textCenter'>
                                <svg width="90px" height="60px" viewBox="0 0 116 70" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                                    <g id="Product-/-SKU" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="Desktop-New-Product---1" transform="translate(-663.000000, -247.000000)" stroke="#9998AF" strokeWidth="2">
                                            <g id="Group" transform="translate(295.000000, 121.000000)">
                                                <path d="M470,147 L470,195 L382,195 L382,147 M387,177 L404,177 M443,183 L454,183 M446,170 L442,170 L448.5,162 L455,170 L451,170 L451,177 L446,177 L446,170 Z M387,189 L404,189 M387,183 L407,183 M382,127 L426,127 L414,147 L370,147 L382,127 Z M426,127 L470,127 L482,147 L438,147 L426,127 Z M426,195 L426,187.5 M426,182.5 L426,178 M426,163.5 L426,146 L426,127" id="Combined-Shape"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <h3 className="textCenter m-tb-2">NEW PRODUCT</h3>
                        <p id="newProductInfo" className="textCenter">We bring your new product from idea to reality, guiding you from steps A to Z.
                        Manage the process through our platform, and see your product come to life.
                        You bring the madness, we'll bring the method.</p>
                        <p className="textCenter">Let's get started!</p>
                    
                    </section>
                    <FormControl id="newProductInput" className="loginForm">
                        <p className="bold m-tb-2">Name Your Product</p>
                        <ProductTextField
                            value={ productName }
                            type='text'
                            name='productName'
                            placeholder='eg. Steel Desk Anvil'
                            variant='outlined'
                            className={ errorProductName ? "productsInput errorClass" : "productsInput"}
                            onChange={e => this.onChange(e)}
                        />
                    </FormControl>
                    <FormControl variant="outlined" className="loginForm ">
                        <p className="bold m-tb-2">Choose a category</p>
                        <Select
                            name="productCategory"
                            variant="outlined"
                            value={productCategory}
                            className={ errorCategory ? "loginInput selectForm productsInput errorClass workSans" : "loginInput selectForm productsInput workSans"}
                            id='selectCategries'
                            onChange={e => this.onChange(e)}
                        >
                            {
                                categories.map((category, index) => {
                                    return(
                                        <MenuItem
                                            key = {category.id}
                                            className="workSans"  
                                            value={category.id}
                                        >
                                            {category.type === 'subcategory' ? <span className='spaceSub'>{category.name} </span>: category.name }
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    
                    <NextButton onClick={ this.validateAndNext } />
                </Grid>
            </Grid>
        )
    }
}

export default Product;