import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import {
    getNotifications,
    readNotification
} from './functions';
import Moment from 'react-moment';
import SocketContext from '../../socket-context';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';

class NotificationBell extends Component {
    state = {
        anchorEl: null,
        openFullNotifications: false,
        notifications: [],
        newNotifications : false
    };

    componentDidMount() {
        this.allNotifications();
        const {
            socket
        } = this.props;
        
        socket.on('newProduct', (response) => {
            toast.success(
                <p className="m-0">{response.createdBy.first_name} {response.description} <span className='bold capitalize'>{ response.item }</span></p>
            );
            let noti = [response.notification];
            this.setState({
                notifications: [
                    ...noti,
                    ...this.state.notifications
                ]
            });
        });

        socket.on('newTaskAssigned', (response) => {
            toast.success(
                <p className="m-0">{response.createdBy.first_name} {response.description} <span className='bold capitalize'>{ response.item }</span></p>
            );
            let noti = [response.notification];
            this.setState({
                notifications: [
                    ...noti,
                    ...this.state.notifications
                ]
            });
        });

        socket.on('newMentionOnTask', (response) => {
            toast.success(
                <p className="m-0">{response.createdBy.first_name} {response.description} <span className='bold capitalize'>{ response.item }</span></p>
            );
            let noti = [response.notification];
            this.setState({
                notifications: [
                    ...noti,
                    ...this.state.notifications
                ]
            });
        });

        socket.on('newCommentYourTask', (response) => {
            toast.success(
                <p className="m-0">{response.createdBy.first_name} {response.description} <span className='bold capitalize'>{ response.item }</span></p>
            );
            let noti = [response.notification];
            this.setState({
                notifications: [
                    ...noti,
                    ...this.state.notifications
                ]
            });
        });
    }

    openNotificationsFull = () => {
        this.allNotifications();
        this.setState({ openFullNotifications: true });
    };
    
    closeNotificationsFull = () => {
        this.setState({ openFullNotifications: false });
    }
    
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    allNotifications = () => {
        getNotifications().then(data => {
            this.setState({
                notifications: data
            })
        });
    }

    readN = (id) => {
        const {
            history
        } = this.props;
        readNotification(id).then(data => {
            if (data.error) {
                return toast.error(data.error);
            }
            history.push(`/${data.redirectTo}`);
            this.allNotifications();
            this.setState({ anchorEl: false });
        });
    }
    
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    
    render() {
        const {
            anchorEl,
            notifications,
        } = this.state;
        return(
            <div className="notification-header">
                <Hidden xsDown>
                    <IconButton aria-controls="menu-notifications" aria-haspopup="true" onClick={this.handleClick}>
                        <NotificationsIcon id="notificationBell"/>
                        {
                            Object.keys(notifications).length > 0 ? <div className="notificationCounter">
                                {notifications.length}
                            </div> : null
                        }
                    </IconButton>
                    <Popper
                        id="menu-notifications"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        transition
                        disablePortal
                        placement = 'bottom-end'
                    >
                        {
                            ({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    id="menu-list-grow"
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={this.handleClose}>
                                            <MenuList style={{ width: '300px' }}>
                                                {
                                                    Object.keys(notifications).length ? notifications.map((notification, index) => (
                                                        <MenuItem onClick={e => this.readN(notification._id)} className="notificationText" key={index}>
                                                            <div className="flex" style={{'whiteSpace': 'initial'}}>
                                                                <Avatar className="avatarBg" style={{'marginRight': '5px'}}>
                                                                    {notification.createdBy.first_name.charAt(0)}{notification.createdBy.last_name.charAt(0)}
                                                                </Avatar>
                                                                <p className="m-0">{notification.createdBy.first_name} {notification.description} <span className='bold capitalize'>{ notification.item }</span></p>
                                                            </div>
                                                            <p className="account m-0 textRight"><Moment fromNow>{notification.created}</Moment></p>
                                                        </MenuItem>
                                                    )) : <p className="textCenter">You don't have notifications</p>
                                                }
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )
                        }
                    </Popper>
                </Hidden>
                <Hidden smUp>
                    <IconButton aria-controls="menu-notifications"  onClick={this.openNotificationsFull}>
                        <NotificationsIcon id="notificationBell" />
                        {
                            Object.keys(notifications).length > 0 ? <div className="notificationCounter">
                                {notifications.length}
                            </div> : null
                        }
                    </IconButton>
                    <Dialog
                        open={this.state.openFullNotifications}
                        onClose={this.handleCloseFullNotifications}
                        aria-labelledby="simple-dialog-title"
                        className="grayBg"
                        id="full-notifications"
                        fullScreen
                    >
                        <div className="p-lr-5 p-tb-5 whiteBg">
                            <span className="floatLeft workSans" onClick={this.closeNotificationsFull}>X</span>
                            <h3 className="textCenter m-0">NOTIFICATIONS</h3>
                        </div>
                        {
                            Object.keys(notifications).length ? 
                                notifications.map((notification, index) => (
                                    <MenuItem onClick={e => this.readN(notification._id)} className="notificationText" key={index}>
                                        <div className="flex" style={{'whiteSpace': 'initial'}}>
                                            <Avatar className="avatarBg" style={{'marginRight': '5px'}}>
                                                {notification.createdBy.first_name.charAt(0)}{notification.createdBy.last_name.charAt(0)}
                                            </Avatar>
                                            <p className="m-0">{notification.createdBy.first_name} {notification.description} <span className='bold capitalize'>{ notification.item }</span></p>
                                        </div>
                                        <p className="account m-0 textRight"><Moment fromNow>{notification.created}</Moment></p>
                                    </MenuItem>
                                )) : <p className="textCenter">You don't have notifications</p>
                        }
                    </Dialog>
                </Hidden>
            </div>
        )
    }
}

const NotificationBellWithSocket = props => (
    <SocketContext.Consumer>
        {socket => <NotificationBell {...props} socket={socket} />}
    </SocketContext.Consumer>
)

export default withRouter(NotificationBellWithSocket);