import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
    Grid,
    Select,
    Hidden,
    MenuItem
} from "@material-ui/core";
import { ProductTextField } from '../styles';
import NextButton from '../NextButton';
import BackButton from '../BackButton';
import { required, lt, isInt } from '../validator';
import { toast } from 'react-toastify';
import Cancel from '@material-ui/icons/Clear';

class Details extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productdetails: '',
            targetprice: {
                format: 'FOB',
                value: ''
            },
            otherprice: '',
            targetquantity: '',
            errorProductDetails: false,
            errorTargetPrice: false,
            errorOtherPrice: false,
            errorTargetQuantity: false,
            errorValuePrice: false
        }
    }
    
    componentDidMount() {
        this.checkValues()
    }

    checkValues = () => {
        const {
            valueProductDetails,
            valueTargetPrice,
            valueOtherPrice,
            valueTargetQuantity
        } = this.props;
        
        if (valueProductDetails !== '') {
            this.setState({
                productdetails: valueProductDetails
            })
        }

        if (valueTargetPrice !== '') {
            this.setState({
                targetprice: valueTargetPrice
            })
        }

        if (valueOtherPrice !== '') {
            this.setState({
                otherprice: valueOtherPrice
            })
        }

        if (valueTargetQuantity !== '') {
            this.setState({
                targetquantity: valueTargetQuantity
            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onChangeOther = (e) => {
        this.setState({
            otherprice: e.target.value
        });
    }
    
    onChangeValue = (e) => {
        this.setState({
            targetprice: {
                format: this.state.targetprice.format,
                value: e.target.value
            }
        });
    }

    onChangeSelect = (e) => {
        this.setState({
            targetprice: {
                format: e.target.value,
                value: ''
            }
        });
    }

    validateAndNext = () => {
        this.setState({
            errorProductDetails: false,
            errorTargetPrice: false,
            errorOtherPrice: false,
            errorValuePrice: false,
            errorTargetQuantity: false
        });

        if (required(this.state.productdetails, 'Description')) {
            this.setState({
                errorProductDetails: true
            });
            return toast.error(required(this.state.productdetails, 'Description'));
        }

        if (!lt(this.state.productdetails.length, 3)) {
            this.setState({
                errorProductDetails: true
            });
            return toast.error('Description Invalid');
        }
        
        if (required(this.state.targetprice.format, 'Target Price')) {
            this.setState({
                errorTargetPrice: true
            });
            return toast.error(required(this.state.targetprice.format, 'Target Price'));
        }

        if (this.state.targetprice.format === 'Other' &&  required(this.state.otherprice, 'Target Other Price')) {
            this.setState({
                errorOtherPrice: true
            });
            return toast.error(required(this.state.otherprice, 'Target Other Price'));
        }
        
        if (required(this.state.targetprice.value, 'Target Price Value')) {
            this.setState({
                errorValuePrice: true
            });
            return toast.error(required(this.state.targetprice.value, 'Target Price Value'));
        }
        
        if (required(this.state.targetquantity, 'Target MOQ')) {
            this.setState({
                errorTargetQuantity: true
            });
            return toast.error(required(this.state.targetquantity, 'Target MOQ'));
        }

        if (isInt(this.state.targetquantity, 'Target MOQ')) {
            this.setState({
                errorTargetQuantity: true
            });
            return toast.error('Target MOQ Invalid');
        }

        const {
            productdetails,
            targetprice,
            otherprice,
            targetquantity
        } = this.state;
    
        this.props.sendDetails({
            productdetails,
            targetprice,
            otherprice,
            targetquantity,
            showDetails: false,
            showDesign: true,
        });
    }
    
    returnToProduct = () => {
        this.props.returnTo({
            showDetails: false,
            showProduct: true,
            complete: false
        });
    };

    render() {
        const {
            productdetails,
            errorProductDetails,
            targetprice,
            otherprice,
            errorOtherPrice,
            errorValuePrice,
            targetquantity,
            errorTargetQuantity
        } = this.state;
        return(
            <Grid container>
                <Grid item md={8} sm={12} className="grayBg p-lr-5 p-tb-2">
                    <BackButton onClick={ this.returnToProduct }/>
                    <Hidden smUp>
                    <span className="floatRight"><Cancel onClick={this.props.closeAll} className="cancel" /></span>
                    </Hidden>
                    <h3 className="m-tb-2 ">PRODUCT DETAILS</h3>
                    <p>Tell us as much as you can about what you want to make.  No detail is too small!   </p>
                  
                    <FormControl className="loginForm">
                        <p className="bold m-tb-2">Description</p>
                        <ProductTextField
                            type='text'
                            name='productdetails'
                            multiline={true}
                            rows={3}
                            value={productdetails}
                            onChange={ e => this.onChange(e)}
                            placeholder='eg. Steel Desk Anvil. If I had to describe this product in a magazine...'
                            variant='outlined'
                            className={ errorProductDetails ? "errorClass" : "" }
                        />
                    </FormControl>
                    <div id="productDetailsTargets" className="flex">
                        <FormControl id="targetPriceForm" >
                            <p className="bold ">Target Price</p>
                            <Select
                                name="targetprice"
                                variant="outlined"
                                value={ targetprice.format }
                                onChange={ e => this.onChangeSelect(e)}
                                className='loginInput selectForm productsInput'
                            >
                                <MenuItem className="workSans" value="FOB">FOB</MenuItem>
                                <MenuItem className="workSans" value="DDP">DDP</MenuItem>
                                <MenuItem className="workSans" value="EXW">EXW</MenuItem>
                                <MenuItem className="workSans" value="Other">Other</MenuItem>
                            </Select>
                            {
                                targetprice.format === 'Other' ? 
                                    <ProductTextField
                                        type='text'
                                        name='otherprice'
                                        value={ otherprice }
                                        onChange={ e => this.onChangeOther(e) }
                                        variant='outlined'
                                        placeholder='Other Price'
                                        className={ errorOtherPrice ? "errorClass m-tb-2" : "m-tb-2" }
                                    /> : null
                            }
                            <ProductTextField
                                type='text'
                                name='valueprice'
                                value={ targetprice.value }
                                onChange={ e => this.onChangeValue(e) }
                                variant='outlined'
                                placeholder='Value Price'
                                className={ errorValuePrice ? "errorClass m-tb-2" : "m-tb-2" }
                            />
                        </FormControl>
                        <FormControl>
                            <p className="bold">Target MOQ</p>
                            <ProductTextField
                                type='number'
                                name='targetquantity'
                                value={ targetquantity }
                                onChange={ e => this.onChange(e) }
                                className={ errorTargetQuantity ? "errorClass" : "" }
                                placeholder='0'
                                variant='outlined'
                            />
                        </FormControl>
                    </div>
                    <NextButton onClick={ this.validateAndNext } />
                </Grid>
                <Hidden>
                    <Grid item md={4} className="darkBg yellowDotsBg"></Grid>
                </Hidden>
            </Grid>
        )
    }
}

export default Details;