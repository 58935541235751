import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Back from '@material-ui/icons/KeyboardBackspace';
import FormControl from '@material-ui/core/FormControl';
import {ProductTextField} from '../NewProduct/styles';
import TextField from '@material-ui/core/TextField';
import {toast} from 'react-toastify';
import {isUrl, required} from './validator';
import { isAuthenticated } from '../../auth';

class HelpFind extends Component {
    constructor(props){
        super(props);
        this.state = {
            findUrl : '',
            findDescription : '',
            errorFindDetails : false,
            errorFindUrl : false,
            helpmeFind :true
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    submitFindSomethingToMake = () => {

        this.setState({
            errorFindDetails: false,
            errorFindUrl: false
        });

        if (required(this.state.findUrl, 'URL')) {
            this.setState({
                errorFindUrl: true
            });
            return toast.error(required(this.state.findUrl, 'URL'));
        }
        
        if (!isUrl(this.state.findUrl)) {
            this.setState({
                errorFindUrl: true
            });
            return toast.error('Invalid URL ')
        } 
        
        if (required(this.state.findDescription, 'Description')) {
            this.setState({
                errorFindDetails: true
            });
            return toast.error(required(this.state.findDescription, 'Description'));
        }
        const body = {
            sku_help_url: this.state.findUrl,
            sku_help_desc: this.state.findDescription
        };

        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/${isAuthenticated().companyActually.slug}/helpme`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
            body: JSON.stringify(body),
        })
        .then(res => res.json())
        .then(json => {
            this.props.completeProcessFind({
                completeProcess: true,
                isHelpMeFind : true
            });
            
            this.setState({
                helpmeFind : false,
                findUrl : '',
                findDescription : '',
                errorFindDetails : false,
                errorFindUrl : false
            });
        })
        .catch(err => console.log(err));
        
    }

    render() {
        const {
            helpmeFind,
            findUrl,
            findDescription,
            errorFindDetails,
            errorFindUrl
        } = this.state;

        return(
            <>
            {
                helpmeFind ? 
                <Grid container>
                <Grid item s={12} className="grayBg p-lr-5 p-tb-2">
                <span className="cancel" ><Back onClick={this.props.returnTostartMakingModal}/></span>
                    <h3 className="p-lr-5 textCenter">Help Me Find a Product To Make</h3>
                    <p className="p-lr-5">
                   To get the latest manufacturing trends and best sellers insider info, tell us a bit about 
                   what you are looking to make.
                    </p>
                    <div>
                        <FormControl className="loginForm m-t-2 p-lr-5">
                            <p className="bold">Similar Product</p>
                            <p>Find a product that is a similar to the one you want to build and
                            paste the URL below
                            </p>
                            <ProductTextField 
                            type="url"
                            placeholder="https://exampleproducttomake.com"
                            name="findUrl"
                            value={ findUrl}
                            className={ errorFindUrl ? "errorClass" : "" }
                            variant='outlined'
                            onChange = { e => this.onChange(e) }
                            />
                        </FormControl>
                        <FormControl className="loginForm m-t-2 p-lr-5">
                            <p className="bold">Details</p>
                            <p >Tell us more about the product you want to make. Any and all
                            information helps.
                            </p>
                        <ProductTextField
                            type='text'
                            name='findDescription'
                            multiline={true}
                            rows={4}
                            value={findDescription}
                            onChange={ e => this.onChange(e)}
                            placeholder='eg. Steel Desk Anvil'
                            variant='outlined'
                            className={ errorFindDetails ? "errorClass" : "" }
                        />
                        </FormControl>
                        <FormControl className="loginForm m-t-2 p-lr-5">
                            <TextField
                                type="submit"
                                className="yellowSubmit"
                                variant="outlined"
                                value="NEXT"
                                onClick ={ this.submitFindSomethingToMake}
                            />
                        </FormControl>
                    </div>
                </Grid>
            </Grid>
                
                : null
            }
           </>
        )
    }
}
export default HelpFind;