import React, {Component} from 'react';
import Back from '@material-ui/icons/KeyboardBackspace';

class BackButton extends Component {
    render() {
        return(
            <span className="cancel"><Back { ...this.props } /></span>
        )
    }
}

export default BackButton;