import React, { Component } from 'react';
import NewProduct from '../NewProduct';
import Source1 from './Source1';
import Source2 from './Source2';
import Source3 from './Source3';

class HelpSource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            radioSource: '',
            radioWithGembah: '',
            currentScreen: 1,
            productFinished: '',
            products: {},
            helpMeSource: true,
            newProduct: false,
            withGembah: false,
            finishedProducts: false
        }
        this.closeAll = this.closeAll.bind(this);
    }
    
    returnToStartMaking = () => {
        this.props.returnTostartMakingModal({
            startMakingModal: true,
            radioSource: '',
            radioWithGembah: '',
            currentScreen: 1
        });
    }
    
    completeProcess = (data) => {
        this.setState(data);
        this.props.completeProcessSource(data);
    }

    goToScreen = (data) => {
        this.setState(data);
    }
    closeAll = () =>{
        this.props.closeStart()
    }
    render() {
        const {
            radioWithGembah,
            currentScreen,
            helpMeSource,
            newProduct,
            withGembah,
            finishedProducts
        } = this.state;
        return(
            <>
                {
                    helpMeSource ? <Source1
                        goTo={this.goToScreen}
                        returnTo={this.returnToStartMaking}
                        closeAll = {this.closeAll}
                    /> : null
                }
                {
                    withGembah ? <Source2
                        goTo={ this.goToScreen }
                        closeAll = {this.closeAll}
                    /> : null
                }
                {
                    finishedProducts ? <Source3
                        goTo={ this.goToScreen }
                        completeProcessSource={ this.completeProcess }
                        closeAll = {this.closeAll}
                    /> : null
                }
                {
                    newProduct ? <NewProduct completeProcessProduct={this.completeProcess} returnTostartMakingModal={this.returnToStartMaking} closeStart={this.closeStartMaking}/> : null
                }
                {
                    currentScreen === 2 && radioWithGembah === 'NO' ? <NewProduct completeProcessProduct={this.completeProcess} returnTostartMakingModal={this.returnToStartMaking} closeStart={this.closeStartMaking}/> : null
                }
            </>
        )
    }
}

export default HelpSource;