import React , {Component} from 'react';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { MenuList } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ProjectSpecs from './ProjectSpecs';
import EditSku from './EditSku';
import { isAuthenticated } from '../../auth/index';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


class DotsMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            openFullSpecs: false,
            openEdit: false,
            project: '',
            productToEdit:'',
            price:'',
            openConfirmation: false,
            skuToDelete:'',
        };
        this.closeEdit = this.closeEdit.bind(this);
    }
    
    
    openProductSpec = (product) => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/${product.companyOwner.slug}/${product.slug}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            }
        })
        .then(res => res.json())
        .then( json => {
            if (json.error) {
                return toast.error(json.error);
            }
            this.setState({
                project: json,
                openFullSpecs: true
            });
        })
        .catch(err => console.log(err));
    }

    openEdit = (product) =>{
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/${product.companyOwner.slug}/${product.slug}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            }
        })
        .then(res => res.json())
        .then( json => {
            if (json.error) {
                return toast.error(json.error);
            }
            this.setState({
                productToEdit: json,
                openEdit : true,
                price : json.target_price.value
            });
        })
        .catch(err => console.log(err));
       
    }

    refreshProducts = (data) => {
        return this.props.refresh(data);
    }

    deleteProduct = () => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/archive/${isAuthenticated().companyActually.slug}/${this.state.skuToDelete.slug}`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            }
        })
        .then(res => res.json())
        .then( json => {
            if (json.error) {
                return toast.error(json.error);
            }
            toast.success('Product Deleted');
            this.closeConfirmationDelete();
            return this.props.refresh({
                company: json.product.companyOwner.slug,
                product: json.product.slug
            });
        })
        .catch(err => console.log(err));
    }
   
    moveToActive = (product) => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/active/${isAuthenticated().companyActually.slug}/${product.slug}`,{
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            }
        })
        .then(res => res.json())
        .then( json => {
            if(json.error) {
                return toast.error(json.error);
            }
            let el = document.getElementById('design');
            if(el){
                el.classList.add('activeChanged');
                setTimeout(() => el.classList.remove('activeChanged'),1500);
            }
            toast.success('The SKU : ' + product.name + ' is now active');
            return this.props.refresh({
                company: json.product.companyOwner.slug,
                product: json.product.slug
            });
        })
        .catch(err => console.log(err));
    }
   
    moveFromStatus = (currentStatus, slug) => {
        let nextStatus = this.toggleStatus(currentStatus);
        let body;
        if(nextStatus === 'FULFILLED'){
           body = {
               status : 'FULFILLED',
               isCompleted: true
           }
        }else{
            body = {
                status : nextStatus
            }
        }
       fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/edit/${isAuthenticated().companyActually.slug}/${slug}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
            body: JSON.stringify(body),
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error)
            }
            let el = document.getElementById(nextStatus.toLowerCase());
            if(el){
                el.classList.add('activeChanged');
                setTimeout(() => el.classList.remove('activeChanged'),1500);
            }
            toast.success('SKU moved to '+ nextStatus);
            return this.props.refresh({
                company: json.product.companyOwner.slug,
                product: json.product.slug
            });
           
        })
        .catch(err => console.log(err));

    }
    
    toggleStatus = (current) => {
        switch(current){
            case 'DESIGN' : return 'PRE-PRODUCTION';
            case 'PRE-PRODUCTION' : return 'PRODUCTION';
            case 'PRODUCTION': return 'SHIPPING';
            case 'SHIPPING' : return 'FULFILLED';
            default :return 'DESIGN';
        }
    }
    
    closeProductSpec = () => {
        this.setState({ openFullSpecs : false});
    }

    closeEdit = (data) => {
        this.setState(data);
    }
    
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    closeConfirmationDelete = () => {
        this.setState({
            openConfirmation: false,
            skuToDelete:''
        })
    }
    openConfirmationDelete = (skuSlug) => {
        this.setState({
            openConfirmation : true,
            skuToDelete : skuSlug
        })
    }
    
    render() {
        const {
            anchorEl, openConfirmation
        } = this.state;

        const {
            product
        } = this.props;
       // console.log(product);
        return(
            <div>
                <IconButton aria-controls="menu-dots" aria-haspopup="true" onClick={this.handleClick}>
                    <MoreHoriz />
                </IconButton>
                <Popper
                    id="menu-dots"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    transition
                    disablePortal
                >
                    {
                        ({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                id="menu-dots-grow"
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'left'}}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={ this.handleClose }>
                                        <MenuList>
                                            <MenuItem className="workSans" onClick={e => this.openProductSpec(product)}>   
                                                View SKU
                                            </MenuItem>
                                            {
                                                isAuthenticated().role === 'administrator' || isAuthenticated().role === 'staff'
                                                || isAuthenticated().role === 'owner' ?
                                                <MenuItem className="workSans" onClick={e => this.openEdit(product)}>
                                                    Edit SKU
                                                </MenuItem>
                                                :
                                                null
                                            }
                                              {
                                                (isAuthenticated().role === 'administrator' || isAuthenticated().role === 'staff') && !product.isActive ?
                                                <MenuItem  className="workSans" onClick={e => this.moveToActive(product)}>
                                                    Move SKU
                                                </MenuItem>
                                                :
                                                null
                                            } 
                                            {
                                                (isAuthenticated().role === 'administrator' || isAuthenticated().role === 'staff') && product.isActive && 
                                                product.status !== 'FULFILLED' ?
                                                <MenuItem  className="workSans" onClick={e => this.moveFromStatus(product.status, product.slug)}>
                                                    Move to {this.toggleStatus(product.status)}
                                                </MenuItem>
                                                :
                                                null
                                            } 
                                            {
                                                isAuthenticated().role === 'administrator' || isAuthenticated().role === 'staff' ?
                                                <MenuItem  className="workSans" onClick={e => this.openConfirmationDelete(product)}>
                                                    Delete SKU
                                                </MenuItem>
                                                :
                                                null
                                            }
                                                                                    
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )
                    }
                </Popper>
                <ProjectSpecs
                    ProjectIntormation={this.state.project}
                    open={this.state.openFullSpecs}
                    closeModal={this.closeProductSpec}
                />
                <EditSku
                    product = {this.state.productToEdit}
                    open={this.state.openEdit}
                    closeModal={this.closeEdit}
                    price={this.state.price}
                    refreshProducts={this.refreshProducts}
                />
                <Dialog
                    open={openConfirmation}
                    onClose={this.closeConfirmationDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">
                    <p className="m-0">Delete?</p>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description" className="workSans">
                    Are you sure you want to delete a SKU?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions  >
                    <Button onClick={this.closeConfirmationDelete}  className="actionsConfirmation">
                        Cancel
                    </Button>
                    <Button onClick={this.deleteProduct}  className="actionsConfirmation" autoFocus>
                        Delete
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default DotsMenu;