import React , {Component} from 'react';
import {
    Grid,
    FormControl,
	TextField,
	Select,
	withStyles
} from '@material-ui/core';
import { isAuthenticated } from '../../auth';
import { toast } from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';

const UserTextField = withStyles({
    root: {
		'& label.Mui-focused': {
			color: '#3BA3FF',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#3BA3FF',
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: '#3BA3FF',
				borderWidth: '2px',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#3BA3FF',
			},
		},
	},
})(TextField);

class CreateCompany extends Component {
	constructor(props) {
        super(props);
        this.state = {
			company_name: '',
			staff: '',
			staffs: []
        }
	}

	componentDidMount() {
		this.getStaffs();
	}

	getStaffs = () => {
		fetch(`${process.env.REACT_APP_DOMAIN_BACK}/admin/users/staff`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
			if (json.error) {
				return toast.error(json.error);
			}
           this.setState({
				staffs: json,
           });
        })
        .catch(err => {
            toast.error(err);
        });
	}

	createCompany = () => {
		let company = {
			name: this.state.company_name,
		}
		if (this.state.staff !== '') {
			company.staff = this.state.staff
		}
		fetch(`${process.env.REACT_APP_DOMAIN_BACK}/company/create`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
            body: JSON.stringify(company),
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error)
			}
			toast.success('Created succesfully');
			this.setState({
				company_name: '',
				staff: ''
			})
          
        })
        .catch(err => console.log(err));
	}
	
    onChange = (e) => {
		this.setState({
			[e.target.name] : e.target.value,
		});
	}

    render() {
        const {
			company_name,
			staff,
			staffs,
		} = this.state;
		
		return(
            <Grid container>
				<Grid item xs={8} className="m-lr-auto">
					<h3 className="p-lr-2">REGISTER NEW COMPANY</h3>
					<FormControl className="loginForm m-tb-2">
						<UserTextField
							name="company_name"
							type="text"
							className="loginInput"
                            variant="outlined"
                            value={company_name}
							placeholder="Company Name"
							onChange={ e => this.onChange(e)}
						/>
					</FormControl>
					<FormControl variant="outlined" className="loginForm  m-tb-2">
						<p className="bold">Staff:</p>
                        <p>This user needs to be part of the Gembah Staff, this is the person in charge of reviewing
                            everything about the company.
                        </p>
						<Select
                            name="staff"
                            value={staff}
                            variant="outlined"
							onChange={ e => this.onChange(e)}
							className="loginInput selectForm"
						>
							{
								staffs.map((staff, index) => (
									<MenuItem key={index} value={staff._id}>{staff.first_name} {staff.last_name}</MenuItem>
								))
							}
						</Select>
					</FormControl>
					<FormControl className="loginForm">
						<TextField
							id="submit-login"
							type="submit"
							className="yellowSubmit"
							variant="outlined"
							value="ADD"
							onClick={this.createCompany}
						/>
					</FormControl>
				</Grid>
			</Grid>
        )
    }

}
export default CreateCompany;