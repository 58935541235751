import React, {Component} from 'react';
import Product from './Product';
import Details from './Details';
import Design from './Design';
import Making from './Making';
import Packaging from './Packaging';
import Warehouse from './Warehouse';
import { createProduct } from './functions/index';
import { toast } from 'react-toastify';
import SocketContext from '../../socket-context';

class NewProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProduct: true,
            showDetails: false,
            showDesign: false,
            showMaking: false,
            showPackaging: false,
            showWarehouse: false,
            productName:'',
            productCategory:'',
            productdetails: '',
            getPackagingFrom: '',
            targetprice: {
                format: 'FOB',
                value: ''
            },
            otherprice: '',
            targetquantity: '',
            similarProducts: [],
            productdiffer: '',
            filesToUpload: [],
            filesToUploadPackaging: [],
            warehouse: [],
            complete: false
        }

        this.closeAll = this.closeAll.bind(this);
    }

    storageInfoProduct = (data) => {
        this.setState(data);
    }

    backTo = (data) => {
        this.setState(data);
    }
    
    submitNewProduct = () => {
        const {
            productName,
            productCategory,
            productdetails,
            targetprice,
            otherprice,
            targetquantity,
            filesToUpload,
            similarProducts,
            productdiffer,
            filesToUploadPackaging,
            warehouse,
            getPackagingFrom
        } = this.state;

        const {
            socket
        } = this.props;

        let checkSubcategory = productCategory.split('-');
		let SearchCategory = {};
		
		checkSubcategory.length > 1 ? SearchCategory = {
			id: checkSubcategory[0],
			subcategory: checkSubcategory[1]
		} : SearchCategory = {
			id: checkSubcategory[0],
		};
        
        const newProductData = {
            name: productName,
            category: SearchCategory,
            description: productdetails,
            target_price: (targetprice.format === 'Other') ? {format: otherprice, value: targetprice.value } : targetprice,
            target_quantity: targetquantity,
            filesToUpload: (getPackagingFrom === 'Design') ? filesToUpload : [],
            similarProducts: (getPackagingFrom !== 'Design') ? similarProducts : [],
            productdiffer : (getPackagingFrom !== 'Design') ? productdiffer : '',
            filesToUploadPackaging,
            warehouse
        };

        //console.log(newProductData, 'data body');
        createProduct(JSON.stringify(newProductData)).then(data => {
            if (data.error) {
                toast.error(data.error);
            } else {
                socket.emit('createdNewProduct', data);
                this.setState({
                    newProductModal: false,
                    showDetails: false,
                    showDesign: false,
                    showMaking: false,
                    showPackaging: false,
                    showWarehouse: false,
                    productName:'',
                    productCategory:'',
                    productdetails: '',
                    getPackagingFrom: '',
                    targetprice: {
                        format: 'FOB',
                        value: ''
                    },
                    otherprice: '',
                    targetquantity: '',
                    similarProducts: [],
                    productdiffer: '',
                    filesToUpload: [],
                    filesToUploadPackaging: [],
                    warehouse: [],
                    complete : false
                });
                this.props.completeProcessProduct({
                    completeProcess: true,
                    start: false,
                    product_link: data.product_link,
                    product: data.product,
                    isHelpMeFind: false,
                    complete: false
                });
            }
        }).catch(err => console.log(err));
    }
    
    returnTo = (data) => {
        this.props.returnTostartMakingModal(data);
    }
    closeAll = () =>{
        this.props.closeStart()
    }
    render() {
        const {
            complete
        } = this.state;
      
        return(
            <>
                {
                    this.state.showProduct ? <Product
                        valueProductName={this.state.productName}
                        valueProductCategory={this.state.productCategory}
                        sendProduct={this.storageInfoProduct}
                        cancelProduct={this.backTo}
                        noStart = {this.props.noStart}
                        returnTo={this.returnTo}
                        closeAll={this.closeAll}
                    /> : null
                }
                {
                    this.state.showDetails ? <Details
                        valueProductDetails={this.state.productdetails}
                        valueTargetPrice={this.state.targetprice}
                        valueOtherPrice={this.state.otherprice}
                        valueTargetQuantity={this.state.targetquantity}
                        sendDetails={this.storageInfoProduct}
                        returnTo={this.backTo}
                        closeAll={this.closeAll}
                    /> : null
                }
                {
                    this.state.showDesign ? <Design
                        valueProductsToUpload={this.state.filesToUpload}
                        sendDesign={this.storageInfoProduct}
                        returnTo={this.backTo}
                        closeAll={this.closeAll}
                    /> : null
                }
                {
                    this.state.showMaking ? <Making
                        valuesMaking={this.state.similarProducts}
                        productDiffer = {this.state.productdiffer}
                        sendMaking={this.storageInfoProduct}
                        returnTo={this.backTo}
                        closeAll={this.closeAll}
                    /> : null
                }
                {
                    this.state.showPackaging ? <Packaging
                        valueProductsToUploadPackaging={this.state.filesToUploadPackaging}
                        valueGetPackagingFrom={this.state.getPackagingFrom}
                        sendPackaging={this.storageInfoProduct}
                        returnTo={this.backTo}
                        closeAll={this.closeAll}
                    /> : null
                }
                {
                    this.state.showWarehouse ? <Warehouse
                        sendWarehouse={this.storageInfoProduct}
                        returnTo={this.backTo}
                        closeAll={this.closeAll}
                    /> : null
                }
                {
                    complete ? this.submitNewProduct()  : ''
                    
                }
            </>
        )
    }
}

const NewProductWithSocket = props => (
    <SocketContext.Consumer>
        {socket => <NewProduct {...props} socket={socket} />}
    </SocketContext.Consumer>
)

export default NewProductWithSocket;