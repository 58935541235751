import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

class MainBilling extends Component {
  
    render(){
        const option =  this.props.option; 
        return(
            <>
            {
                option === '' ?
                <Grid container>
                <Grid item xs={8} className="m-lr-auto">
                    <h3 className="p-lr-2">Billing</h3>
                    <FormControl className="billingButton m-t-2">
                        <Button name="new" className="yellowSubmit" variant="outlined" onClick={this.props.clickNew}>
                        Make new invoice
                        </Button>
                    </FormControl>
                    
                </Grid>
                </Grid>
                :
                null
            }
            </>
            
        )
    }
}
export default MainBilling