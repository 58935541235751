import React, {Component} from 'react';
import Lightbox from './Lightbox';
import Attach from '@material-ui/icons/AttachFile';

class PreviewFiles extends Component {
    constructor(props){
        super(props)
        this.state = {
            openLightbox : false
        }
        this.closeLightbox = this.closeLightbox.bind(this);
    }

    seeLightbox = () =>{
        this.setState({
            openLightbox : true
        });
    }

    closeLightbox = () =>{
        this.setState({
            openLightbox: false
        })
    }

 

    render(){
        const file = this.props.file;
        const {openLightbox} = this.state;
        let type = file.name.lastIndexOf('.') < 1 ?   null : file.name.split('.').slice(-1);
        //console.log(file,'lightbox');
        return(
            <>
            {
                this.props.onComments ?
                <span className="p-lr-2 previewName cursorPointer" onClick={this.seeLightbox} >
                    <Attach/>{file.name}
                </span>
                :
                <div className="inlineFlex m-lr-2 cursorPointer m-tb-2" style={{'alignItems': 'center'}} onClick={this.seeLightbox}>
                {
                    file.mimetype === 'image/png' || file.mimetype === 'image/jpg' || 
                    file.mimetype === 'image/jpeg' ?
                        <img src={file.path} alt='' width='50' height='50'/>
                    :
                    <div className="workSans bold defaultPreview" ><p className="textCenter">{type}</p></div>
                }
                
                <span className="p-lr-2 workSans previewName">{file.name}</span>
               
            </div>
            }
           
            {
                openLightbox ?
                <Lightbox 
                file={file}
                close={this.closeLightbox}
                />
                :
                null
            }
            </>
        )
    }

}
export default PreviewFiles;