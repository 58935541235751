import React,{Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {OrangeRadio} from '../NewProduct/styles/index';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel, FormControl ,TextField} from '@material-ui/core';
import Cancel from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import NewProduct from '../NewProduct';
import SuccessStartMaking from '../SuccessStartMaking';
import HelpSource from '../HelpSource';
import HelpReorder from '../HelpReorder';
import HelpFind from '../HelpFind';

class StartMaking extends Component {
    constructor(props){
        super(props);
        this.state = {
            startMakingModal:  false,
            radioStart: 'DESIGN',
            start: false,
            completeProcess: false,
            product_link: '',
            product: {},
            product_name: '',
            isHelpMeFind : false
        }
        
        this.noStart = this.noStart.bind(this);
        this.closeStartMaking = this.closeStartMaking.bind(this);
    }
    
    openStartMaking = () => {
        this.setState({
            startMakingModal: true
        });
    }
    
    closeStartMaking = () => {
        this.setState({
            startMakingModal: false,
            start: false,
            completeProcess: false,
        });
    }
    
    onChangeRadio = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    startMaking = () => {
        this.setState({
            start: true,
        });
    }
    
    noStart = () => {
        this.setState({
            start: false
        });
    }
    completeProcess = (data) => {
        this.setState(data)
        this.props.refreshProducts()
    }
    
    render() {
        const {
            startMakingModal,
            radioStart,
            start,
            completeProcess
        } = this.state;

        return(
            <div className="floatRight">
                <Button onClick={this.openStartMaking} variant="contained" className="newButton rift "><AddIcon/>NEW</Button>
                <Dialog
                    open={startMakingModal}
                    onClose={this.closeStartMaking}
                    aria-labelledby="simple-dialog-title"
                    className="fullDialog grayBg"
                    id="flowsStartModals"
                    maxWidth='md'
                >
                    {
                        !start && !completeProcess ? 
                            <Grid container>
                                <Grid item sm={12} className="grayBg p-lr-5 p-tb-2">
                                    <span className="cancel" onClick={ this.closeStartMaking }><Cancel/></span>
                                    <h3 className="m-tb-2 textCenter">Start Making</h3>
                                    <p id="newProductInfo" className="textCenter p-lr-10">Please choose an option from the list below.</p>
                                    <div id="startMakingInfo" className="p-lr-10">
                                        <FormControl className="loginForm m-t-2">
                                            <FormControlLabel
                                                control={
                                                    <OrangeRadio
                                                        value="DESIGN"
                                                        onChange={ e => this.onChangeRadio(e) }
                                                        checked= { radioStart === 'DESIGN'}
                                                        name="radioStart"
                                                    />
                                                }
                                                label="Help Me Design"
                                            />
                                            <p className="m-0">Getting designs ready for manufacturing</p>
                                        </FormControl>
                                        <FormControl className="loginForm m-t-2">
                                            <FormControlLabel
                                                control={
                                                    <OrangeRadio
                                                        value="SOURCE"
                                                        onChange={ e => this.onChangeRadio(e) }
                                                        checked= { radioStart === 'SOURCE'}
                                                        name="radioStart"
                                                    />
                                                }
                                                label="Help Me Source"
                                            />
                                            <p className="m-0">Find a factory to make your product</p>
                                        </FormControl>
                                        <FormControl className="loginForm m-t-2">
                                            <FormControlLabel
                                                control={
                                                    <OrangeRadio
                                                        value="REORDER"
                                                        onChange={ e => this.onChangeRadio(e) }
                                                        checked= { radioStart === 'REORDER'}
                                                        name="radioStart"
                                                    />
                                                }
                                                label="Help Me Reorder"
                                            />
                                            <p className="m-0">Place a PO on an existing SKU</p>
                                        </FormControl>
                                        <FormControl className="loginForm m-t-2">
                                            <FormControlLabel
                                                control={
                                                    <OrangeRadio
                                                        value="FIND"
                                                        onChange={ e => this.onChangeRadio(e) }
                                                        checked= { radioStart === 'FIND'}
                                                        name="radioStart"
                                                    />
                                                }
                                                label="Help Me Find a Product To Make"
                                            />
                                            <p className="m-0">Get the latest manufacturing trends and best sellers insider info</p>
                                        </FormControl>
                                        <FormControl className="loginForm m-t-2 ">
                                            <TextField
                                                type="submit"
                                                className="yellowSubmit"
                                                variant="outlined"
                                                value="NEXT"
                                                onClick={this.startMaking}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid> : ''
                    }
                    {
                        start && radioStart === 'DESIGN' ? <NewProduct completeProcessProduct={this.completeProcess} returnTostartMakingModal={this.noStart} closeStart = {this.closeStartMaking}/> : ''
                    }
                    {
                        start && radioStart === 'SOURCE' ? <HelpSource completeProcessSource={this.completeProcess} returnTostartMakingModal={this.noStart} closeStart = {this.closeStartMaking}/> :''
                    }
                    {
                        start && radioStart === 'REORDER' ? <HelpReorder completeProcessReorder={this.completeProcess} returnTostartMakingModal={this.noStart} closeStart = {this.closeStartMaking}/> : '' 
                    }
                    {
                        start && radioStart === 'FIND' ? <HelpFind completeProcessFind={this.completeProcess} returnTostartMakingModal={this.noStart}/> : ''
                    }

                    {
                        completeProcess ? <SuccessStartMaking isFind={this.state.isHelpMeFind} productName={this.state.product.name} productLink={this.state.product_link} closeThis={this.closeStartMaking}/>: ''
                    }
                </Dialog>
       </div>
        )
    }
}
export default StartMaking;