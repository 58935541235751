import React, { Component } from 'react';
import { Redirect } from 'react-router';
import AdminDashboard from '../AdminDashboard';
import {
	isAuthenticated
} from '../../auth/index';

class Main extends Component {
    render() {
        return(
            <section style={{height: '100%'}}>
                { isAuthenticated() ? <AdminDashboard/> : <Redirect to="/login"/> }
            </section>
        )
    }
}

export default Main;