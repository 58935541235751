import {setInStorage , getFromStorage} from '../services/storage';

export const signin = user => {
    return fetch(`${process.env.REACT_APP_DOMAIN_BACK}/auth/signin`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(user)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err));
};

export const reset = (resetPasswordLink, newPassword) => {
    return fetch(`${process.env.REACT_APP_DOMAIN_BACK}/auth/reset-password`, {
        method: "PUT",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            resetPasswordLink,
            newPassword
        }),
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err));
};

export const forgot = (email) => {
    return fetch(`${process.env.REACT_APP_DOMAIN_BACK}/auth/forgot-password`, {
        method: "PUT",
        mode: "cors",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({email}),
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err));
};

export const authenticate = (jwt, next) => {
    const {
        token,
        user
    } = jwt;
    const cookie = {
        id: user._id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        token,
        role: user.role,
        companies: user.companies,
        companyActually: user.companies[0]
    };
    if (typeof window !== "undefined") {
        setInStorage('gembah_user', cookie);
        next();
    }
};

export const remember = (data) => {
    if (typeof window !== "undefined") {
        setInStorage('gembah_rememberme', data);
    }
};

export const signout = next => {
    const token = isAuthenticated().token;
    if (typeof window !==  "undefined") localStorage.removeItem('gembah_user');
    next();
    return fetch(`${process.env.REACT_APP_DOMAIN_BACK}/auth/signout`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => console.log(err));
};

export const isRemembered = () => {
    if (typeof window ===  "undefined") {
        return false;
    }

    if (localStorage.getItem('gembah_rememberme')) {
        return getFromStorage('gembah_rememberme');
    } else {
        return false;
    }
};

export const isAuthenticated = () => {
    if (typeof window ===  "undefined") {
        return false;
    }

    if (localStorage.getItem('gembah_user')) {
        return getFromStorage('gembah_user');
    } else {
        return false;
    }
};