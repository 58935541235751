import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
    Grid,
    Hidden
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import BackButton from '../BackButton';
import { uploadFiles } from '../../../services/upload';
import NextButton from '../NextButton';
import { lt } from '../validator';
import Cancel from '@material-ui/icons/Clear';

class Design extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoadingDesigns: false,
            productsToUpload: [],
            showUpload: false
        }
    }

    componentDidMount() {
        this.productData = new FormData();
        this.checkValues()
    }

    checkValues = () => {
        const {
            valueProductsToUpload,
        } = this.props;
        this.setState({
            productsToUpload: valueProductsToUpload
        })
    }

    onChange = (e) => {
        if (e.target.name === 'productdesigns') {
			this.setState({
				isLoadingDesigns: true,
			})
			const filesData = new FormData();
			for (const file of e.target.files) {
				filesData.append('files', file, file.name);
			}
            e.target.value = null;
            uploadFiles(filesData).then(data => {
                this.setState({
					productsToUpload: [
						...this.state.productsToUpload,
						...data.files
					],
					isLoadingDesigns: false
				});
				this.productData.set('files', this.state.productsToUpload);
            })
        }
        
        this.setState({
			[e.target.name]: e.target.value
		});
    }

    removeFile = (arrayName, array, position) => {
		array.splice(position,1);
		this.setState({
			[arrayName]: array
		});
    };
    
    showUploadInput = () => {
        this.setState({
			showUpload: true
		});
    }

    nextWithoutDesigns = () => {
        this.props.sendDesign({
            showDesign: false,
            showMaking: true
        });
    }
    
    validateAndNext = () => {
        const {
            productsToUpload
        } = this.state;
        if (lt(productsToUpload.length, 0)) {
            return this.props.sendDesign({
                showDesign: false,
                showPackaging: true,
                filesToUpload: productsToUpload,
                getPackagingFrom: 'Design'
            });
        }
        this.nextWithoutDesigns();
    }

    returnToDetails = () => {
        this.props.returnTo({
            showDetails: true,
            showDesign: false,
            complete: false
        });
    };

    render() {
        const {
            isLoadingDesigns,
            productsToUpload,
            showUpload
        } = this.state;
        return(
            <Grid container id="productDesignContainer">
                <Grid item md={8} sm={12} className="grayBg p-lr-5 p-tb-2">
                    <BackButton onClick={ this.returnToDetails }/>
                    <Hidden smUp>
                    <span className="floatRight"><Cancel onClick={this.props.closeAll} className="cancel" /></span>
                    </Hidden>
                    <h3 className="m-tb-2 ">Design</h3>
            
                   <p > <b className="cancel">1.</b> Turn your idea into a full-fledged design. Our product designers can take an idea–as simple as a napkin scrawl or just the glimmer of a 
                       concept–and produce multiple designs for you to review.</p>
                        <p>Upload any designs you may already have, even a napkin scrawl. </p>
                    {
                        isLoadingDesigns ? <div style={{width: '100px'}}><CircularProgress/></div> : productsToUpload.map((file, index) => {
                            return(
                                <div
                                    key={index}
                                >
                                    <p className="account repeaterFields">{file.name}</p>
                                    <span className="cancel">
                                        <RemoveCircle
                                            onClick={e => this.removeFile('productsToUpload', productsToUpload, index)}
                                        />
                                    </span>
                                </div>
                            )
                        })
                    }
                    {
                        showUpload ? <FormControl className="loginForm">
                                <input
                                    type="file"
                                    id="fileDesigns"
                                    multiple
                                    name="productdesigns"
                                    accept="application/pdf,image/jpeg,image/png,image/svg+xml,application/msword,image/vnd.adobe.photoshop,application/postscript,application/x-photoshop"
                                    onChange={ e => this.onChange(e)}
                                    variant='outlined'
                                />
                            </FormControl>  : ''
                    }
                    <FormControl className="loginForm m-t-2">
                        <TextField
                            type="submit"
                            className="yellowSubmit"
                            variant="outlined"
                            value="UPLOAD FILE(S)"
                            onClick={ this.showUploadInput }
                        />
                    </FormControl>
                    <p > <b className="cancel">2.</b> If you don’t have anything designs right now, don’t worry, we can help with that. Just click the button below.</p>
                    {
                        productsToUpload.length > 0 ? <NextButton onClick={ this.validateAndNext } /> : <FormControl className="loginForm m-t-2">
                                <TextField
                                    type="submit"
                                    className="yellowSubmit"
                                    variant="outlined"
                                    value="I DON'T HAVE DESIGNS YET"
                                    onClick = { this.nextWithoutDesigns }
                                />
                            </FormControl>
                    }
                </Grid>
                <Hidden>
                    <Grid item md={4} className="darkBg yellowDotsBg"></Grid>
                </Hidden>
            </Grid>
        )
    }
}

export default Design;