import React,{Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import {
    FormControl,
    TextField,
    Select,
    MenuItem,
    InputAdornment,
    Button,
    CircularProgress,
    Avatar
} from '@material-ui/core';
import { MentionsInput, Mention } from 'react-mentions';
import {ProductTextField} from '../NewProduct/styles';
import Cancel from '@material-ui/icons/Clear';
import Add from '@material-ui/icons/Add';
import {isAuthenticated} from '../../auth/index';
import defaultStyle from '../TaskDetails/stylesDefault';
import defaultMentionStyle from '../TaskDetails/defaultMentionStyle';
import Attach from '@material-ui/icons/AttachFile';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Pencil from '@material-ui/icons/Create';
import { uploadFiles} from '../../services/upload';
import { required } from './validator';
import { toast } from 'react-toastify';
import SocketContext from '../../socket-context';

class NewTaskModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            taskName : '',
            users:[],
            description:'',
            assignTo:'',
            peopleToMentions : [],
            filesToAttach:[],
            isLoadingAttachments :false,
            showTodoInput : false,
            todoItem : '',
            todos: []
        }
    }
    
    componentDidMount() {
        this.getUsersToSuggest();
        this.commentData = new FormData();
    }

    addTodos = () => {
        if (this.state.showTodoInput) {
            if (required(this.state.todoItem, 'To-Do')) {
                return toast.error(required(this.state.todoItem, 'To-Do'))
            }

            let todo = [{
                text: this.state.todoItem
            }];
            
            this.setState({
                todos: [
                    ...this.state.todos,
                    ...todo
                ],
                todoItem: ''
            });
        } else {
            this.setState({
                showTodoInput: true,
                todoItem: ''
            });
        }
    };

    createTask = () => {
        const {
            socket
        } = this.props;
        if (this.state.todoItem !== '') {
            return toast.error('Please add To-Do');
        }

        if (required(this.state.taskName, 'Task Name')) {
            return toast.error(required(this.state.taskName, 'Task Name'))
        }
        
        if (required(this.state.description, 'Description')) {
            return toast.error(required(this.state.description, 'Description'))
        }

        if (required(this.state.assignTo, 'Assign To')) {
            return toast.error(required(this.state.assignTo, 'Assign To'))
        }
        let task = {
            name: this.state.taskName,
            description: this.state.description,
            assignedTo: this.state.assignTo,
            productOwner: this.props.productOwner,
            todo: this.state.todos,
            filesToAttach: this.state.filesToAttach
        }

        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/task/${isAuthenticated().companyActually.slug}/create`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(task)
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error);
            }
            socket.emit('createdNewTaskAssigned', {
                task: json.task,
                company: isAuthenticated().companyActually._id
            });
            socket.emit('createdNewMentionOnTask', {
                from: isAuthenticated(),
                to: this.state.peopleToMentions,
                task: json.task
            });
            this.props.close();
            this.props.refreshTask();
            this.setState({
                taskName : '',
                description:'',
                assignTo:'',
                peopleToMentions : [],
                filesToAttach:[],
                isLoadingAttachments :false,
                showTodoInput : false,
                todoItem : '',
                todos: []
            })
            return toast.success(json.message);
        })
        .catch(err => console.log(err));
    };

    hideTodoInput = () => {
        this.setState({
            showTodoInput: false,
            todoItem: ''
        });
    }

    editTodos = (text,index) => {
        this.setState({
            showTodoInput: true,
            todoItem : text
        });
        this.removeFile('todos',this.state.todos,index);
    }

    removeFile = (arrayName, array, position) => {
		array.splice(position,1);
		this.setState({
			[arrayName]: array
		});
    };
    
    uploadFile = (e) => {
        this.setState({
            isLoadingAttachments: true,
        })
        const filesData = new FormData();
        for (const file of e.target.files) {
            filesData.append('files', file, file.name);
        }
        e.target.value = null;
        uploadFiles(filesData).then(data => {
            this.setState({
                filesToAttach: [
                    ...this.state.filesToAttach,
                    ...data.files
                ],
                isLoadingAttachments: false
            });
            this.commentData.set('files', this.state.filesToAttach);
        });
    }
    
    onChange = (e) => {
        this.setState({
          [e.target.name] : e.target.value  
        })
    }
    
    onChangeComments = (e, newValue, newPlainTextValue, mentions) => {
        this.setState({
            description: e.target.value,
            peopleToMentions: mentions
        })
    }
    
    getUsersToSuggest = () => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/company/${isAuthenticated().companyActually.slug}/employees`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
            this.setState({
                users: json,
            });
        })
        .catch(err => console.log(err));
    }

    render() {
        const {
            users,
            showTodoInput,
            todoItem,
            isLoadingAttachments,
            filesToAttach,
            todos
        } = this.state;
        
        return(
            <Dialog
                open ={this.props.open}
                onClose={this.props.close}
                aria-labelledby="simple-dialog-title"
                className="fullDialog"
                maxWidth='md'
                id='newTaskModal'
            >
                <Grid container>
                    <Grid item md={8} className="p-lr-5 p-tb-2">
                    <span onClick={this.props.close} className="cancel "><Cancel /></span>
                    <h3>NEW TASK</h3>
                    <p>Enter all the necessary data to create your new task.</p>
                    <FormControl className="loginForm">
                        <p className="bold">Task Name</p>
                        <ProductTextField
                            name="taskName"
                            value={this.state.taskName}
                            onChange = {e => this.onChange(e)}
                            variant= "outlined"
                            className = "loginInput"
                        />
                    </FormControl>
                    <FormControl className="loginForm ">
                        <p className="bold">Description</p> 
                        <MentionsInput
                            value={this.state.description}
                            onChange={this.onChangeComments}
                            style={defaultStyle}
                            placeholder={"Mention people using '@'"}
                            className="comments-textarea"
                        >
                            <Mention
                                trigger="@"
                                data={users}
                                markup="<span class='taggedUser' id='__id__'>__display__</span>"
                                appendSpaceOnAdd={true}
                                renderSuggestion={
                                    (
                                        suggestion,
                                        search,
                                        highlightedDisplay,
                                        index,
                                        focused
                                    ) => (
                                        <div className={`user ${focused ? 'focused': ''}`}>
                                            {highlightedDisplay}
                                        </div>
                                    )
                                }
                                style={defaultMentionStyle}
                            />
                        </MentionsInput>
                        <Button className="attachFileTasks">
                            <input
                                type="file"
                                id="filesToAttach"
                                multiple
                                name="filesToAttach"
                                accept="application/pdf,image/jpeg,image/png,image/svg+xml,application/msword,image/vnd.adobe.photoshop,application/postscript,application/x-photoshop"
                                onChange={ e => this.uploadFile(e)}
                            />
                            <Attach/>
                        </Button>
                        </FormControl>
                        {
                            isLoadingAttachments ? <div style={{width: '100px'}}><CircularProgress/></div> : <div>
                                {
                                    filesToAttach.map((file, index) => (
                                        <div key={index}>
                                            <p className="account repeaterFields">{file.name}</p>
                                            <span className="cancel">
                                                <RemoveCircle onClick={e => this.removeFile('filesToAttach', filesToAttach, index)} />
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                        <p className="bold">To-do</p>
                        {
                            todos.map((todo, index) => (
                                <div key={index} className="m-b-2 todosItemsText">
                                    <p className="account repeaterFields">{todo.text}</p>
                                    <span className="cancel">
                                        <RemoveCircle onClick={e => this.removeFile('todos', this.state.todos, index)}/>
                                        <Pencil onClick={e => this.editTodos(todo.text, index)}/>
                                    </span>
                                </div>
                            ))
                        }
                        {
                            showTodoInput ? 
                            <FormControl className="loginForm">
                                <ProductTextField
                                    name="todoItem"
                                    value={todoItem}
                                    variant="outlined"
                                    className="todosItems loginInput"
                                    placeholder= "Task To-Do here..."
                                    onChange={e => this.onChange(e)}
                                    style={{'marginBottom': '5px'}}
                                />
                                <span className="cancel">
                                    <Cancel onClick={this.hideTodoInput} />
                                </span>
                            </FormControl> : null
                        }
                        <FormControl className="loginForm">
                            <TextField
                                type="submit"
                                variant="outlined"
                                value="ADD TO-DO"
                                className="loginInput"
                                id="addTodo"
                                onClick={this.addTodos}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment>
                                            <Add />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                        <FormControl className="loginForm">
                            <p className="bold">Assign to</p>
                            <Select
                                className="loginInput selectForm productsInput workSans"
                                name="assignTo"
                                value={this.state.assignTo}
                                onChange={ e => this.onChange(e)}
                                variant="outlined"
                            >
                                {
                                    users.map((user, index) => (
                                        <MenuItem
                                            key={user.id}
                                            className="workSans"
                                            value={user.id}
                                        >
                                            <Avatar className="avatarAssignedTo">
                                                {(user.first_name).charAt(0)}{(user.last_name).charAt(0)}
                                            </Avatar>
                                            {user.full}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl className="loginForm m-tb-2">
                            <TextField
                                type="submit"
                                className="yellowSubmit"
                                variant="outlined"
                                value="CREATE"
                                onClick={this.createTask}
                            />
                        </FormControl>
                    </Grid>
                    <Hidden>
                        <Grid item md={4} className="darkBg yellowDotsBg"></Grid>
                    </Hidden>
                </Grid>
            </Dialog>
        )
    }
}

const NewTaskModalWithSocket = props => (
    <SocketContext.Consumer>
        {socket => <NewTaskModal {...props} socket={socket} />}
    </SocketContext.Consumer>
)

export default NewTaskModalWithSocket;