import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {
    TextField,
    FormControl,
    Button,
    CircularProgress,
    Select,
    MenuItem,
    Hidden
} from '@material-ui/core';
import Pencil from '@material-ui/icons/Create'
import Back from '@material-ui/icons/KeyboardBackspace';
import Cancel from '@material-ui/icons/Clear';
import { isAuthenticated } from '../../auth/index';
import {ProductTextField} from '../NewProduct/styles';
import Moment from 'react-moment';
import {toast} from 'react-toastify';
import { getCountries, getCity } from '../NewProduct/Warehouse/functions';
import {lt, required, isInt} from './validator';

var  newWarehouse;
class Reorder2 extends Component {

   constructor(props){
       super(props)
       this.state = {
           isLoading :false,
           productData : [],
           editQuantity : false,
           editShippingType :false,
           editPackagingType :false,
           editWarehouse : false,
           errorPackagingTypeReorder :false,
           errorQuantityReorder : false,
           errorPackagingOtherReorder :false,
           errorAddressReorder:false,
           errorNewCity :false,
           errorNewState :false,
           errorNewZipcode :false,
           newQuantity:'',
           newPackagingOther : '',
           newShipping:'',
           newPackaging:'',
           newAddress:'',
           newCountry: 'US',
           newZipcode : '',
           newState:'',
           newCity:'',
           countries:[],
           price : ''
       }
    }
    componentDidMount(){
        this.getProductData();
        this.getCountries();
    }
 
    onChange = (e) => {
        if (e.target.name === 'newCountry') {
            return this.setState({
                newCountry: e.target.value,
                newCity: '',
                newState: '',
                newZipcode: '',
                newAddress: ''
            });
        }

        if (e.target.name === 'newZipcode' && lt(e.target.value.length, 4)) {
            const country = this.state.newCountry;
            const address = e.target.value;
			getCity(address, country).then(data => {
                if (data.results.length > 0) {
                    if (country === 'US') {
                        return this.setState({
                            newCity: data.results[0].address_components[3].long_name,
                            newState: data.results[0].address_components[4].short_name
                        })
                    } else {
                        return this.setState({
                            newCity: data.results[0].address_components[2].long_name,
                            newState: data.results[0].address_components[3].short_name
                        });
                    }
                } else {
                    toast.error('Results not found');
                }
            });
        }
        
        return this.setState({
            [e.target.name]: e.target.value
        });
    }
   
    backScreen = () => {
        this.props.goTo({
            helpmeReorder: true,
            previousOrder: false,       
        });
    }

    editQuantityField = () => {
        this.setState( prevState => ({
            editQuantity : !prevState.editQuantity
        }));
    }
    editShippingField = () => {
        this.setState(prevState => ({
            editShippingType : !prevState.editShippingType
        }));
    }
    editPackagingField = () => {
        this.setState( prevState => ({
            editPackagingType : !prevState.editPackagingType
        }));
    }
    editWarehouseField = () => {
        this.setState( prevState => ({
            editWarehouse : !prevState.editWarehouse
        }));
    }
   
    validateAndReorder= () => {
        var body = {};
        let shipp;
        const {
            editPackagingType,
            editQuantity,
            editShippingType,
            editWarehouse,
        } = this.state;

        /** IF THE PACKAGING TYPE WAS EDITED CHECKS FOR VALIDATIONS  */
        if(editPackagingType){
            this.setState({
                errorPackagingTypeReorder: false,   
            });

            if (required(this.state.newPackaging, 'Packaging Type')) {
                this.setState({
                    errorPackagingTypeReorder: true
                });
                return toast.error(required(this.state.newPackaging, 'Packaging Type'));
            }
            const packaging = {
                packaging_type: this.state.newPackaging
            }
            body = Object.assign(body,packaging);
          
            
        }
        /* IF THE SHIPPING TYPE WAS EDITED CHECK FOR VALIDATIONS*/ 
        if(editShippingType){
            const {newPackagingOther} = this.state;
    
            if (this.state.newShipping === 'OTHER' &&  required(this.state.newPackagingOther, 'Other option')) {
                this.setState({
                    errorPackagingOtherReorder: true
                });
                return toast.error(required(this.state.newPackagingOther, 'Other option'));
            }
            if (this.state.newShipping === 'OTHER' && !required(this.state.newPackagingOther, 'Other option') ) {
               shipp = newPackagingOther
                
            }
            else{
                 shipp = this.state.newShipping
            }
                const target = {
                    target_price : {
                        format : shipp,
                        value : this.state.price  
                        }
                }
            

           

            body = Object.assign(body,target);
        }

        /* IF THE QUANTITY WAS EDITED CHECK FOR VALIDATIONS*/ 
        if(editQuantity){
            this.setState({
                errorQuantityReorder: false,   
            });

            if (required(this.state.newQuantity, 'Quantity')) {
                this.setState({
                    errorQuantityReorder: true
                });
                return toast.error(required(this.state.newQuantity, 'Quantity'));
            }
            if (isInt(this.state.newQuantity, 'Quantity')) {
                this.setState({
                    errorQuantityReorder: true
                });
                return toast.error('Quantity Invalid');
            }

            const quantity = {
                target_quantity: this.state.newQuantity
            };

            body = Object.assign(body,quantity);
        }

        /* IF THE WAREHOUSE WAS EDITED CHECKS FOR VALIDATIONS*/ 
        if (editWarehouse) {    
            this.setState({
                errorAddressReorder: false,
                errorNewZipcode: false,
                errorNewCity: false,
                errorNewState: false,
            });

            if (required(this.state.newAddress, 'Address')) {
                this.setState({
                    errorAddressReorder: true
                });
                return toast.error(required(this.state.newAddress, 'Address'));
            }

            if (!lt(this.state.newAddress.length, 2)) {
                this.setState({
                    errorAddressReorder: true
                });
                return toast.error('Invalid Address');
            }

            if (required(this.state.newZipcode, 'Zip Code')) {
                this.setState({
                    errorNewZipcode: true
                });
                return toast.error(required(this.state.newZipcode, 'Zip Code'));
            }

            if (!lt(this.state.newZipcode.length, 2)) {
                this.setState({
                    errorNewZipcode: true
                });
                return toast.error('Invalid Zip Code');
            }
            
            if (required(this.state.newCity, 'City')) {
                this.setState({
                    errorNewCity: true
                });
                return toast.error(required(this.state.newCity, 'City'));
            }

            if (!lt(this.state.newCity.length, 2)) {
                this.setState({
                    errorNewCity: true
                });
                return toast.error('Invalid City');
            }

            if (required(this.state.newState, 'State')) {
                this.setState({
                    errorNewState: true
                });
                return toast.error(required(this.state.newState, 'State'));
            }

            if (!lt(this.state.newState.length, 1)) {
                this.setState({
                    errorNewState: true
                });
                return toast.error('Invalid State');
            }
            
            const {
                newCity,
                newState,
                newZipcode,
                newAddress,
                newCountry
            } = this.state;
        
            
              newWarehouse = {
                warehouse : [{
                    country: newCountry,
                    address: newAddress,
                    zipcode: newZipcode,
                    city: newCity,
                    state: newState,
                  }]
                }

                
                body = Object.assign(body,newWarehouse);
            
        } else {
              newWarehouse = [];
            
        }

        /**Add PRODUCTION STATUS BECAUSE ITS REORDERED */
        const  reorderedStatus = {
            status: 'PRODUCTION'
        }
        body = Object.assign(body,reorderedStatus);
      
        
        /*SEND DATA*/ 
       fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/reorder/${isAuthenticated().companyActually.slug}/${this.props.selectReorder}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
            body: JSON.stringify(body),
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error)
            }
            this.setState({
                isLoading :false,
                productData : [],
                editQuantity : false,
                editShippingType :false,
                editPackagingType :false,
                editWarehouse : false,
                errorPackagingTypeReorder :false,
                errorQuantityReorder : false,
                errorPackagingOtherReorder :false,
                errorAddressReorder:false,
                errorNewCity :false,
                errorNewState :false,
                errorNewZipcode :false,
                newQuantity:'',
                newPackagingOther : '',
                newShipping:'FOB',
                newPackaging:'',
                newAddress:'',
                newCountry: 'US',
                newZipcode : '',
                newState:'',
                newCity:'',
                countries:[]
            });
            this.props.completeProcessReorder({
                completeProcess: true,
                start: false,
                product_link: json.product_link,
                product: json.product,
                isHelpMeFind : false
            });
        })
        .catch(err => console.log(err));
    }
    

    getProductData = () => {
        this.setState({
            isLoading : true
        });
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/reorder/${isAuthenticated().companyActually.slug}/${this.props.selectReorder}`,{
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            }
        })
        .then(res => res.json())
        .then( json => {
            if (json.error) {
                return toast.error(json.error)
            }
            
            this.setState({
                productData : json,
                isLoading :false,
                price : json[0].target_price.value,
            });
            if(json[0].reordered.length){
                this.setState({
                    newShipping : json[0].reordered[0].target_price.format
                });
            }
            else{
                this.setState({
                    newShipping : json[0].target_price.format
                });
            }
        })
        .catch(err => console.log(err))
    }

    getCountries = () => {
        getCountries().then(data => {
            const filteredCountries = data.filter(aceptedCountries => aceptedCountries.region === "Europe" || aceptedCountries.name === "Mexico" || aceptedCountries.name === "United States" || aceptedCountries.name === "Canada");
			this.setState({
                countries: filteredCountries,
			});
        })
    }

    render(){
        const {
            productData,
            isLoading,
            editQuantity,
            newQuantity,
            newShipping,
            newPackaging,
            newPackagingOther,
            newCountry,
            newAddress,
            newZipcode,
            newState,
            newCity,
            errorAddressReorder,
            errorPackagingTypeReorder,
            errorPackagingOtherReorder,
            errorQuantityReorder,
            errorNewCity ,
            errorNewState,
            errorNewZipcode,
            editShippingType,
            editPackagingType,
            editWarehouse,
            countries,
        } = this.state;
     
        return(
            <Grid container>
                <Grid item  xs={12} className="grayBg p-lr-5 p-tb-2">
                    <span className="cancel" ><Back onClick={this.backScreen}/></span>
                    <Hidden smUp>
                    <span className="floatRight"><Cancel onClick={this.props.closeAll} className="cancel" /></span>
                    </Hidden>
                    <h3 className="p-lr-5 textCenter">PREVIOUS ORDER</h3>
                    <p className="p-lr-5 ">Below is the information from your most recent order of this product. Take a look and edit any information that is relevant.</p>
                    {
                        isLoading ? <div className="textCenter" style={{'width': '100px'}}><CircularProgress/></div> : Object.keys(productData).length ? productData.map((product,index) => (
                            <div key={index} id="previousOrder" className="p-lr-5">
                                <div className="nonEditableInfo m-t-2">
                                    <p className="bold">Product Name:</p>
                                    <p>{product.name}</p>
                                </div>
                                <div className="nonEditableInfo m-t-2">
                                    <p className="bold">Last Order Date:</p>
                                    <p>
                                        <Moment format="DD/MM/YYYY">
                                            {
                                                Object.keys(product.reordered).length ? product.reordered[0].created : product.created
                                            }
                                        </Moment>
                                    </p>
                                </div>
                                {
                                    isAuthenticated().role === 'administrator' ? <div className="nonEditableInfo m-t-2 ">
                                        <p className="bold">Price</p>
                                        <p>${product.target_price.value}</p>
                                    </div> : null
                                }
                                <div className="nonEditableInfo m-t-2">
                                    <p className="bold">Quantity</p>
                                    {
                                        editQuantity ? <div>
                                            <ProductTextField
                                                type="number"
                                                className={errorQuantityReorder ? "loginInput reorderInput errorClass" : "reorderInput loginInput"}
                                                variant="outlined"
                                                value={newQuantity}
                                                placeholder={
                                                    Object.keys(product.reordered).length ? (product.reordered[0].target_quantity).toString() : (product.target_quantity).toString()
                                                }
                                                name="newQuantity"
                                                onChange={e => this.onChange(e)}
                                            />
                                            <Button className="editPreviousOrder" onClick={this.editQuantityField}>
                                                <Cancel/>
                                            </Button>
                                        </div> : <p>
                                            {
                                                Object.keys(product.reordered).length ? product.reordered[0].target_quantity : product.target_quantity
                                            }
                                            <Button className="editPreviousOrder" onClick={this.editQuantityField}>
                                                <Pencil/>
                                            </Button>
                                        </p>
                                    }
                                </div>
                                <div className="nonEditableInfo m-t-2">
                                    <p className="bold">Shipping Type</p>
                                    {
                                        editShippingType ? <div id="editingShipping">
                                            <Select
                                                name="newShipping"
                                                variant="outlined"
                                                value={newShipping}
                                                onChange={ e => this.onChange(e)}
                                                className='loginInput  selectForm productsInput'
                                            >
                                                <MenuItem className="workSans"  value="FOB">FOB</MenuItem>
                                                <MenuItem className="workSans" value="DDP">DDP</MenuItem>
                                                <MenuItem className="workSans" value="EXW">EXW</MenuItem>
                                                <MenuItem className="workSans" value="OTHER">Other</MenuItem>
                                            </Select>
                                            {
                                                newShipping === 'OTHER' ? <ProductTextField
                                                    type="text"
                                                    placeholder="Other option"
                                                    className={
                                                        errorPackagingOtherReorder ? "loginInput m-t-2 errorClass" : "m-t-2 loginInput"
                                                    }
                                                    variant="outlined"
                                                    value={newPackagingOther}
                                                    name="newPackagingOther"
                                                    onChange={e => this.onChange(e)}
                                                /> : null
                                            }
                                            <Button className="editPreviousOrder absolute"  onClick={this.editShippingField}>
                                                <Cancel />
                                            </Button>
                                        </div> : <p>
                                            {
                                                Object.keys(product.reordered).length ? product.reordered[0].target_price.format : product.target_price.format
                                            }
                                            <Button className="editPreviousOrder" onClick={this.editShippingField}>
                                                <Pencil/>
                                            </Button>
                                        </p>
                                    }
                                </div>
                                <div className="nonEditableInfo m-t-2 ">
                                    <p className="bold">Packaging Type</p>
                                    {
                                        editPackagingType ? <div>
                                            <ProductTextField
                                                type="text"
                                                className={errorPackagingTypeReorder ? "loginInput reorderInput errorClass" : "reorderInput loginInput"}
                                                variant="outlined"
                                                value={newPackaging}
                                                placeholder={
                                                    Object.keys(product.reordered).length ? product.reordered[0].packaging_type : product.packaging_type
                                                }
                                                name="newPackaging"
                                                onChange={e => this.onChange(e)} 
                                            />
                                            <Button className="editPreviousOrder" onClick={this.editPackagingField}>
                                                <Cancel />
                                            </Button>
                                        </div> : <p>
                                            {
                                                Object.keys(product.reordered).length ? product.reordered[0].packaging_type : product.packaging_type
                                            }
                                            <Button className="editPreviousOrder" onClick={this.editPackagingField}>
                                                <Pencil/>
                                            </Button>
                                        </p>
                                    }
                                </div>
                                <FormControl className="loginForm m-t-2 " >
                                    <p className="bold">Warehouse</p>
                                    {
                                        editWarehouse ? <FormControl id="editWarehouse">
                                            <Select
                                                name="newCountry"
                                                variant="outlined"
                                                value={newCountry}
                                                onChange={ e => this.onChange(e)}
                                                className='loginInput selectForm productsInput'
                                                id='selectCountry'
                                            >
                                                {
                                                    countries.map((country,index) => (
                                                        <MenuItem
                                                            className="workSans"
                                                            key={index}
                                                            value={country.alpha2Code}
                                                        >
                                                            {country.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            <ProductTextField
                                                type="text"
                                                className={errorAddressReorder ? "loginInput m-t-2  errorClass" : " m-t-2 loginInput"}
                                                variant="outlined"
                                                placeholder = "Street name, number"
                                                value={newAddress}
                                                name="newAddress"
                                                onChange={e => this.onChange(e)}
                                            />
                                            <div id="warehouseDetails" className="flex">
                                                <FormControl className="loginForm m-t-2">
                                                    <ProductTextField
                                                        type="number"
                                                        name='newZipcode'
                                                        value={ newZipcode }
                                                        onChange={ e => this.onChange(e)}
                                                        variant='outlined'
                                                        placeholder='Zipcode'
                                                        className={ errorNewZipcode ? "errorClass loginInput" : "loginInput"}
                                                    />
                                                </FormControl>
                                                <FormControl className="loginForm m-t-2">
                                                    <ProductTextField
                                                        type="text"
                                                        name='newCity'
                                                        value={ newCity }
                                                        onChange={ e => this.onChange(e)}
                                                        variant='outlined'
                                                        placeholder='City'
                                                        className={ errorNewCity ? "errorClass loginInput" : "loginInput"}
                                                    />
                                                </FormControl>
                                                <FormControl className="loginForm m-t-2">
                                                    <ProductTextField
                                                        type="text"
                                                        name='newState'
                                                        value={ newState }
                                                        onChange={ e => this.onChange(e)}
                                                        variant='outlined'
                                                        placeholder='State'
                                                        className={ errorNewState ? "errorClass loginInput" : "loginInput"}
                                                    />
                                                </FormControl>
                                            </div>
                                            <Button className="editPreviousOrder absolute" onClick={this.editWarehouseField}>
                                                <Cancel />
                                            </Button>
                                        </FormControl> : Object.keys(product.reordered).length ? Object.keys(product.reordered[0].warehouse).length ? <div className="inlineBlock">
                                            <p>{product.reordered[0].warehouse[0].address}, {product.reordered[0].warehouse[0].city}, {product.reordered[0].warehouse[0].country}, {product.reordered[0].warehouse[0].zipcode} <Button className="editPreviousOrder" onClick={this.editWarehouseField}><Pencil/></Button></p>
                                        </div> : <p className="inlineBlock">No warehouse specified <Button className="editPreviousOrder" onClick={this.editWarehouseField}><Pencil/> </Button></p> : Object.keys(product.warehouse).length ? <div className="inlineBlock">
                                            <p>{product.warehouse[0].address}, {product.warehouse[0].city}, {product.warehouse[0].country}, {product.warehouse[0].zipcode} <Button className="editPreviousOrder" onClick={this.editWarehouseField}> <Pencil/> </Button></p></div> : <p className="inlineBlock">No warehouse specified  <Button className="editPreviousOrder" onClick={this.editWarehouseField}><Pencil/></Button></p> 
                                        }
                                    </FormControl>
                                <FormControl className="loginForm m-t-2 ">
                                        <TextField
                                            type="submit"
                                            className="yellowSubmit"
                                            variant="outlined"
                                            value="NEXT"
                                            onClick = {this.validateAndReorder}
                                        />
                                    </FormControl>
                                </div>
                            )) : null
                        }
                    </Grid>
                </Grid>
            )
    }
}

export default Reorder2;