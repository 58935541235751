import React, {Component} from 'react';
import Reorder1 from './Reorder1';
import Reorder2 from './Reorder2';

class HelpReorder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            helpmeReorder: true,
            previousOrder: false,
            selectReorder: ''
        }
        this.closeAll = this.closeAll.bind(this);
    }
    
    returnToStartMaking = () => {
        this.props.returnTostartMakingModal({
            startMakingModal: true
        });
    }
    
    goToScreen = (data) => {
        this.setState(data);
    }
    
    completeProcess = (data) => {
        this.setState(data);
        this.props.completeProcessReorder(data);
    }
    closeAll = () =>{
        this.props.closeStart()
    }
    
    render() {
        const {
            helpmeReorder,
            previousOrder
        } = this.state;
        return (
            <>
                {
                    helpmeReorder ? <Reorder1
                        returnTo={this.returnToStartMaking}
                        goTo= {this.goToScreen}
                        selectReorder = {this.state.selectReorder}
                        closeAll = {this.closeAll}
                    /> : null
                }
                {
                    previousOrder ? <Reorder2
                        returnTo={this.returnToStartMaking}
                        goTo={this.goToScreen}
                        selectReorder={this.state.selectReorder}
                        completeProcessReorder={this.completeProcess}
                        closeAll = {this.closeAll}
                    /> : null
                }
            </>
        )
    }
}

export default HelpReorder;