import React, { Component } from "react";
import {
    Grid,
    Dialog,
    TextField,
    FormControlLabel,
    FormControl,
    Button,
    InputAdornment,
    CircularProgress
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Cancel from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox';
import Send from '@material-ui/icons/Send';
import Done from '@material-ui/icons/Done';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Attach from '@material-ui/icons/AttachFile';
import Assign from '../../assets/img/Group.png';
import AssignDefault from '../../assets/img/litask.png';
import TaskTables from '../TaskTables';
//import { JsonTable } from 'react-json-to-html';
import {
    MentionsInput,
    Mention
} from 'react-mentions';
import Moment from 'react-moment';
import { isAuthenticated } from '../../auth/index';
import defaultStyle from './stylesDefault';
import defaultMentionStyle from './defaultMentionStyle';
import DotsTask from '../3DotsTasks';
import { uploadFiles } from '../../services/upload';
import { toast } from 'react-toastify';
import { required } from './validator';
import dompurify from 'dompurify';
import SocketContext from '../../socket-context';
import PreviewFiles from '../PreviewFiles';
import { Link } from 'react-router-dom';

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            isLoadingFiles: false,
            users: [],
            taskData: {},
            isLoading: true,
            filesToComment: []
        }
    }
    
    componentDidMount() {
        this.getTaskInfo(this.props.match.params.taskSlug);
        this.commentData = new FormData();
        
        const {
            socket
        } = this.props;

        socket.on('newCommentYourTask', (response) => {
            this.setState({
                comments: response.data.comments
            });
        });
    }

    getUsersToSuggest = () => {
        const company = isAuthenticated().companyActually.slug;
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/company/${company}/employees`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
            this.setState({
                users: json,
            });
        })
        .catch(err => console.log(err));
    }

    uploadFile = (e) => {
        this.setState({
            isLoadingFiles: true,
        });
        
        const filesData = new FormData();
        for (const file of e.target.files) {
            filesData.append('files', file, file.name);
        }
        e.target.value = null;
        uploadFiles(filesData).then(data => {
            this.setState({
                filesToComment: [
                    ...this.state.filesToComment,
                    ...data.files
                ],
                isLoadingFiles: false
            });
            this.commentData.set('files', this.state.filesToComment);
        });
    }

    getTaskInfo = (slug) => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/task/${slug}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                toast.error(json.error);
            }
            this.setState({
                taskData: json[0],
                isLoading: false
            });
            this.getUsersToSuggest();
        })
        .catch(err => console.log(err));
    }

    checkedTodo = (id, status) => {
        let statusToSend = {
            status: !status
        };
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/task/${id}/complete`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
            body: JSON.stringify(statusToSend),
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error('Something was wrong!');
            }
            this.setState({
                taskData: json.task
            });
        })
        .catch(err => console.log(err));
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    sendComment = (slug) => {
        const {
            socket
        } = this.props;

        const {
            text,
            filesToComment,
            peopleToMentions
        } = this.state;
        
        if (required(this.state.text, 'Comment')) {
            return toast.error(required(this.state.text, 'Comment'));
        }

        const comment = {
            text,
            filesToComment,
            peopleToMentions
        };

        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/task/${slug}/comment`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
            body: JSON.stringify(comment),
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error('Something was wrong!');
            }
            
            socket.emit('createdNewCommentYourTask', { from: isAuthenticated(), task: json.task });
            socket.emit('createdNewMentionOnTask', {
                from: isAuthenticated(),
                to: this.state.peopleToMentions,
                task: json.task
            });
            this.setState({
                text: '',
                taskData: json.task,
                filesToComment: [],
                peopleToMentions: []
            });

        })
        .catch(err => console.log(err));
    }

    markAsCompleted = () => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/task/complete/${this.state.taskData.slug}`,{
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.erro);
            }
            this.setState({
                taskData: json
            })
            toast.success('This task has been completed');
        })
        .catch(err => console.log(err));
    }

    onChangeComments = (e, newValue, newPlainTextValue, mentions) => {
        this.setState({
            text: e.target.value,
            peopleToMentions: mentions
        });
    }
    
    removeFile = (arrayName, array, position) => {
		array.splice(position,1);
		this.setState({
			[arrayName]: array
		});
    };
    
    reloadInfo = (data) => {
        this.setState({
            taskData: data.taskInfo[0],
        });
        this.props.refreshTasksInfo({
            reloadInfo: true
        });
    }

    render() {
        const {
            users,
            taskData,
            isLoading,
            filesToComment,
        } = this.state;
        const sanitize = dompurify.sanitize;
  
        return(
            <Dialog
                open={true}
                aria-labelledby="simple-dialog-title"
                className="fullDialog"
                maxWidth='md'
                id="taskDetailsMOdal"
            >
                {
                    isLoading ? <div style={{width: '100px',margin : 'auto'}}><CircularProgress/></div> : <Grid container>
                        <Grid item xs={12}>
                            <div className="p-lr-5 p-tb-2">
                                <Link
                                    to={{
                                        pathname: `/${isAuthenticated().companyActually.slug}/${this.props.match.params.productSlug}`,
                                        state: { modal: false },
                                    }}
                                    className="link"
                                >
                                    <span className="cancel floatLeft">
                                        <Cancel/>
                                    </span>
                                </Link>
                                {
                                    isAuthenticated().role === 'administrator' || isAuthenticated().role === 'staff' ? <TextField
                                        type="submit"
                                        className={ taskData.isCompleted ? 'successButtonTasks floatRight ' : 'yellowButtonTasks floatRight'}
                                        variant="outlined"
                                        value={ taskData.isCompleted ? 'COMPLETED' : 'MARK AS COMPLETE' }
                                        onClick={taskData.isCompleted ? null : this.markAsCompleted}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Done/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    /> : null
                                }
                                <DotsTask
                                    data={ taskData }
                                    closeTask={this.props.closeTaskDetails}
                                    reloadTask={ this.reloadInfo }
                                />
                            </div>


                            <div className="detailsTaskTitle p-lr-5">
                                <h3 className="m-0">
                                    {
                                        taskData.assignedTo.first_name === isAuthenticated().first_name ? <img src={Assign} alt=""/> : <img src={AssignDefault} alt=""/>
                                    }
                                    { taskData.name} - {taskData.productOwner.name}
                                </h3>
                                <p className="inlineBlock" id="assignedto">
                                    <span>
                                        <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 5H8L4 1" stroke="#E91E63" strokeWidth="2"/>
                                        </svg>
                                    </span>
                                    Assigned to <span className="bold">{ taskData.assignedTo.first_name }</span>
                                </p>
                                <p className="inlineBlock floatRight account" ><Moment format="D MMM YYYY" withTitle>{taskData.created}</Moment></p>
                            </div>


                            <div id="descriptionTask" className="whiteBg p-tb-2 m-0 p-lr-5">
                                <p  dangerouslySetInnerHTML={{__html: sanitize(taskData.description)}}></p>
                                 <TaskTables items={taskData.items}/>
                                 {/*
                                   <JsonTable json={taskData.items} id="tableDetails"/>
                                   */
                                 } 
                                    <div className="flex" style={{'flexWrap': 'wrap'}}>
                                        {
                                            Object.keys(taskData.files).length ? (taskData.files).map((descFiles, index) => (
                                                <PreviewFiles
                                                    key={index}
                                                    file={descFiles}
                                                    onComments={false}
                                                />
                                            )) : null
                                        }
                                    </div>
                            <section id="todosDetails" className="p-lr-5">
                                    {
                                        Object.keys(taskData.todo).length ? (taskData.todo).map((todo,index) => (
                                            <FormControl className="loginForm" key={index}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={ e => this.checkedTodo(todo._id, todo.complete) }
                                                            name={ todo._id }
                                                            checked={ todo.complete }
                                                        />
                                                    }
                                                    label={ todo.text }
                                                />
                                            </FormControl>
                                        )) : <p className="account">There are no to-do items yet</p>
                                    }
                                </section>
                            </div>


                            <div id="addComments">
                                <section id="mentions">
                                    <MentionsInput
                                        value={ this.state.text }
                                        onChange={ this.onChangeComments }
                                        style={ defaultStyle }
                                        placeholder={ "Mention people using '@'" }
                                        className="comments-textarea"
                                    >
                                        <Mention
                                            trigger="@"
                                            data={ users }
                                            markup="<span class='taggedUser' id='__id__'>__display__</span>"
                                            appendSpaceOnAdd={ true }
                                            renderSuggestion={
                                                (
                                                    suggestion,
                                                    search,
                                                    highlightedDisplay,
                                                    index,
                                                    focused
                                                ) => (
                                                    <div className={`user ${focused ? 'focused': ''}`}>
                                                        { highlightedDisplay }
                                                    </div>
                                                )
                                            }
                                            style={ defaultMentionStyle }
                                        />
                                    </MentionsInput>
                                    <Button className="attachFile">
                                        <input
                                            type="file"
                                            id="fileComments"
                                            multiple
                                            name="fileComments"
                                            accept="application/pdf,image/jpeg,image/png,image/svg+xml,application/msword,image/vnd.adobe.photoshop,application/postscript,application/x-photoshop"
                                            onChange={ e => this.uploadFile(e)}
                                        />
                                        <Attach/>
                                    </Button>
                                </section>
                                <section id="send">
                                    <Button onClick={ e => this.sendComment(taskData.slug) }>
                                        <Send/>
                                    </Button>
                                </section>
                            </div>
                           
                           
                            <div className="m-lr-5 m-b-2" id="filesAttachedComments">
                                {
                                    filesToComment.map((file, index) => (
                                        <div key={ index }>
                                            <p className="account repeaterFields">{file.name}</p>
                                            <span className="cancel">
                                                <RemoveCircle onClick={e => this.removeFile('filesToComment', filesToComment, index)} />
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>
                           
                           
                            <section id="activityDetails" className="p-tb-2 grayBg">
                                <h3 className="p-lr-5 m-0 p-tb-2">ACTIVITY</h3>
                                {
                                    Object.keys(taskData.comments).length ? taskData.comments.map((comment,index) => (
                                        <div className="p-lr-5 p-tb-2" key={index}>
                                            <Avatar className="avatarTasksActivity">
                                                { comment.commentedBy.first_name.charAt(0) }{ comment.commentedBy.last_name.charAt(0) }
                                            </Avatar>
                                            <p className="inlineBlock p-lr-2">
                                                <span className="bold">{comment.commentedBy.first_name} {comment.commentedBy.last_name} </span>commented
                                            </p>
                                            <span className="account workSans"><Moment fromNow>{comment.created}</Moment></span>
                                            <div className="p-lr-2 p-tb-2 comment">
                                                <div className="inlineBlock" dangerouslySetInnerHTML={{__html:sanitize(comment.text)}}></div>
                                                {
                                                    Object.keys(comment.files).length ? comment.files.map((file, index) => (
                                                        <PreviewFiles
                                                            key={ index }
                                                            file={ file }
                                                            onComments={ true }
                                                        />
                                                    )) : null
                                                }
                                            </div>
                                        </div>
                                    )) : <p className="account p-lr-5">No activity yet</p>
                                }
                            </section>
                        </Grid>
                    </Grid>
                }
            </Dialog>
        )
    }
}

const DetailsWithSocket = props => (
    <SocketContext.Consumer>
    {socket => <Details {...props} socket={socket} />}
    </SocketContext.Consumer>
)

export default DetailsWithSocket;
