import React, {Component} from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

class NextButton extends Component {
    render() {
        return(
            <FormControl className="loginForm m-t-2">
                <TextField
                    type="submit"
                    className="yellowSubmit"
                    variant="outlined"
                    value="NEXT"
                    { ...this.props }
                />
            </FormControl>
        )
    }
}

export default NextButton;