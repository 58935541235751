import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {
    Grid,
    Hidden,
    FormControlLabel,
    Select,
    MenuItem
} from "@material-ui/core";
import BackButton from '../BackButton';
import { ProductTextField, OrangeRadio } from '../styles';
import { getCountries, getCity } from './functions';
import { lt, required } from '../validator';
import { toast } from 'react-toastify';
import Cancel from '@material-ui/icons/Clear';

class Warehouse extends Component {
    constructor(props) {
        super(props)
        this.state = {
            radioWarehouse: 'no',
            warehouseaddress: '',
            errorwarehouseaddress: false,
            warehousezipcode: '',
            errorwarehousezipcode: false,
            warehousecity: '',
            errorwarehousecity: false,
            warehousetate: '',
            errorwarehousetate: false,
            countries: '',
            country: 'US',
            complete: false
        }
    }

    componentDidMount() {
        this.getCountries();
    }

    onChange = (e) => {
        if (e.target.name === 'country') {
            return this.setState({
                country: e.target.value,
                errorcountry: false,
                warehousecity: '',
                warehousetate: '',
                warehousezipcode: '',
                warehouseaddress: ''
            });
        }

        if (e.target.name === 'warehousezipcode' && lt(e.target.value.length, 4)) {
            const country = this.state.country;
            const address = e.target.value;
			getCity(address, country).then(data => {
                if (data.results.length > 0) {
                    if (country === 'US') {
                        return this.setState({
                            warehousecity: data.results[0].address_components[3].long_name,
                            warehousetate: data.results[0].address_components[4].short_name
                        })
                    } else {
                        return this.setState({
                            warehousecity: data.results[0].address_components[2].long_name,
                            warehousetate: data.results[0].address_components[3].short_name
                        });
                    }
                } else {
                    toast.error('Results not found');
                }
            });
        }
        
        return this.setState({
            [e.target.name]: e.target.value
        });
    }

    getCountries = () => {
        getCountries().then(data => {
            const filteredCountries = data.filter(aceptedCountries => aceptedCountries.region === "Europe" || aceptedCountries.name === "Mexico" || aceptedCountries.name === "United States" || aceptedCountries.name === "Canada");
			this.setState({
                countries: filteredCountries,
			});
        })
    }
    
    validateAndCreate = () => {
        const {
            radioWarehouse
        } = this.state;
        if (radioWarehouse === 'yes') {    
            this.setState({
                errorcountry: false,
                errorwarehouseaddress: false,
                errorwarehousezipcode: false,
                errorwarehousecity: false,
                errorwarehousetate: false,
            });

            if (required(this.state.country, 'Country')) {
                this.setState({
                    errorProductName: true
                });
                return toast.error(required(this.state.country, 'Country'));
            }

            if (required(this.state.warehouseaddress, 'Address')) {
                this.setState({
                    errorwarehouseaddress: true
                });
                return toast.error(required(this.state.warehouseaddress, 'Address'));
            }

            if (!lt(this.state.warehouseaddress.length, 2)) {
                this.setState({
                    errorwarehouseaddress: true
                });
                return toast.error('Invalid Address');
            }

            if (required(this.state.warehousezipcode, 'Zip Code')) {
                this.setState({
                    errorwarehousezipcode: true
                });
                return toast.error(required(this.state.warehousezipcode, 'Zip Code'));
            }

            if (!lt(this.state.warehousezipcode.length, 2)) {
                this.setState({
                    errorwarehousezipcode: true
                });
                return toast.error('Invalid Zip Code');
            }
            
            if (required(this.state.warehousecity, 'City')) {
                this.setState({
                    errorwarehousecity: true
                });
                return toast.error(required(this.state.warehousecity, 'City'));
            }

            if (!lt(this.state.warehousecity.length, 2)) {
                this.setState({
                    errorwarehousecity: true
                });
                return toast.error('Invalid City');
            }

            if (required(this.state.warehousetate, 'State')) {
                this.setState({
                    errorwarehousetate: true
                });
                return toast.error(required(this.state.warehousetate, 'State'));
            }

            if (!lt(this.state.warehousetate.length, 1)) {
                this.setState({
                    errorwarehousetate: true
                });
                return toast.error('Invalid State');
            }
            
            const {
                warehousecity,
                warehousetate,
                warehousezipcode,
                warehouseaddress,
                country
            } = this.state;
        
            return this.props.sendWarehouse({
                warehouse: [{
                    country,
                    address: warehouseaddress,
                    zipcode: warehousezipcode,
                    city: warehousecity,
                    state: warehousetate,
                }],
                complete: true
            });
        } else {
            return this.props.sendWarehouse({
                complete: true,
                warehouse: [],
            });
        }
    }
    
    returnToPackaging = () => {
        this.props.returnTo({
            showPackaging: true,
            showWarehouse: false,
            complete: false
        });
    };

    render() {
        const {
            radioWarehouse,
            warehouseaddress,
            errorwarehouseaddress,
            warehousezipcode,
            errorwarehousezipcode,
            warehousecity,
            errorwarehousecity,
            warehousetate,
            errorwarehousetate,
            countries,
            country
        } = this.state;
        return(
            <Grid container>
            <Grid item md={8} sm={12} className="grayBg p-lr-5 p-tb-2">
                <BackButton onClick={ this.returnToPackaging } />
                <Hidden smUp>
                    <span className="floatRight"><Cancel onClick={this.props.closeAll} className="cancel" /></span>
                </Hidden>
                <h3 className="m-tb-2 ">WAREHOUSE</h3>
                <p>Tell us what you have in mind in terms of warehousing your product.</p>
                <FormControl className="loginForm m-t-2">
                    <FormControlLabel
                        className="warehouseRadio"
                        control={
                            <OrangeRadio
                                value="yes"
                                onChange={ e => this.onChange(e) }
                                checked= { radioWarehouse === 'yes'}
                                name="radioWarehouse"
                            />
                        }
                        label="I have a warehouse"
                    />
                    {
                        radioWarehouse === 'yes' ? <div>
                            <FormControl>
                                <Select
                                    name="country"
                                    variant="outlined"
                                    value={country}
                                    onChange={ e => this.onChange(e)}
                                    className='loginInput selectForm productsInput'
                                    id='selectCountry'
                                >
                                    {
                                        countries.map((country,index) => {
                                            return(
                                                <MenuItem
                                                    className="workSans"
                                                    key={index}
                                                    value={country.alpha2Code}
                                                >
                                                    {country.name}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl className="loginForm m-t-2">
                                <ProductTextField
                                    type="text"
                                    name='warehouseaddress'
                                    value={ warehouseaddress }
                                    onChange={ e => this.onChange(e)}
                                    variant='outlined'
                                    placeholder='Enter address'
                                    className={ errorwarehouseaddress ? "errorClass" : ""}
                                />
                            </FormControl>
                            <div id="warehouseDetails" className="flex">
                                <FormControl className="loginForm m-t-2">
                                    <ProductTextField
                                        type="number"
                                        name='warehousezipcode'
                                        value={ warehousezipcode }
                                        onChange={ e => this.onChange(e)}
                                        variant='outlined'
                                        placeholder='Zipcode'
                                        className={ errorwarehousezipcode ? "errorClass" : ""}
                                    />
                                </FormControl>
                                <FormControl className="loginForm m-t-2">
                                    <ProductTextField
                                        type="text"
                                        name='warehousecity'
                                        value={ warehousecity }
                                        onChange={ e => this.onChange(e)}
                                        variant='outlined'
                                        placeholder='City'
                                        className={ errorwarehousecity ? "errorClass" : ""}
                                    />
                                </FormControl>
                                <FormControl className="loginForm m-t-2">
                                    <ProductTextField
                                        type="text"
                                        name='warehousetate'
                                        value={ warehousetate }
                                        onChange={ e => this.onChange(e)}
                                        variant='outlined'
                                        placeholder='State'
                                        className={ errorwarehousetate ? "errorClass" : ""}
                                    />
                                </FormControl>
                            </div>
                        </div> : ''
                    }
                    <FormControlLabel
                        className="warehouseRadio"
                        control={
                            <OrangeRadio
                                value="no"
                                onChange={ e => this.onChange(e) }
                                checked={ radioWarehouse === 'no'}
                                name="radioWarehouse"
                            />
                        }
                        label="I don't have a warehouse"
                    />
                </FormControl>
                <FormControl className="loginForm">
                    <TextField
                        type="submit"
                        className="yellowSubmit"
                        variant="outlined"
                        value="CREATE"
                        onClick={ this.validateAndCreate }
                    />
                </FormControl>
            </Grid>
            <Hidden>
                <Grid item md={4} className="darkBg yellowDotsBg"></Grid>
            </Hidden>
        </Grid>
        )
    }
}

export default Warehouse;