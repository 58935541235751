export const getCountries = () => {
    return fetch('https://restcountries.eu/rest/v1/', {
        method: 'GET',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err));
}

export const getCity = (address, country) => {
    return fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&components=country:${country}&key=AIzaSyDOW8RMbtB1BC_vzfC1PfdI3lc-sKbbPl8`, {
        method: 'GET',
        mode:'cors'
        
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err));
}