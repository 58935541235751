import React,{Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import {isAuthenticated} from '../../auth/index';
import { toast } from 'react-toastify';
import UpdateCompany from './UpdateCompany';

class Companies extends Component{

constructor(props){
    super(props);
    this.state = {
        companies :[],
        companyToEdit: {}
    }
}

componentDidMount(){
    this.getCompaniesData();
}

getCompaniesData = () =>{
    fetch(`${process.env.REACT_APP_DOMAIN_BACK}/admin/company/list`,{
        method: 'GET',
        mode: 'cors',
        headers: {
            Authorization: `Bearer ${isAuthenticated().token}`,
        },
    })
    .then( res => res.json())
    .then( json => {
        if(json.error){
           return toast.error( json.error);
        }
        this.setState({
            companies: json
        })
    })
    .catch(err => console.log(err))
}

editCompanyData = (company) => {
   this.setState({
        companyToEdit: company
    });
   this.props.change({
       isEditingCompany : true
   });
}

closeEditCompany = () => {
    this.props.change({
        isEditingCompany: false
    });
}

render(){
    const {companies} = this.state;
   

    if(this.props.editing){
        return <UpdateCompany company={this.state.companyToEdit} close={this.closeEditCompany}/>
    }
    return(
    <Grid container>
        <Grid item  xs={12} className="m-lr-auto">
            <h3 className="p-lr-2"> Companies</h3>
            {
                companies.length !== 0 ? <Table id="companiesListTable">
                    <TableHead>
                    <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Owner</TableCell>
                        <TableCell>Team</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            companies.map((company,index) => {
                                return(
                                    <TableRow key={index}>   
                                        <TableCell>{company.name}</TableCell>
                                        <TableCell>
                                        {
                                            company.owner ?
                                            company.owner.first_name +" "+company.owner.last_name
                                            :
                                            <p className="account">Not assigned</p>
                                        }
                                        </TableCell>
                                        <TableCell>{company.employees.length}</TableCell>    
                                        <TableCell>
                                        {
                                            isAuthenticated().role === 'staff' ? 
                                            null :
                                            <IconButton   edge="end" aria-label="Delete">
                                            <DeleteIcon  />
                                            </IconButton>
                                        }
                                       {
                                           isAuthenticated().role === 'staff' ? 
                                           null :
                                           <IconButton onClick={()=>this.editCompanyData(company)}  edge="end" aria-label="Edit">
                                        <CreateIcon/>
                                        </IconButton>
                                       }
                                        
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }   
                    </TableBody>
                </Table>
                : <p className="account"> No Companies </p> 
            }
        </Grid>
    </Grid>
    )
}

}
export default Companies