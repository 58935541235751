import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Success from '../../../assets/img/success.png';



class ThankPayment extends Component{

   
    closeSuccessPayment = () => {
        this.props.reset()
        this.props.close()
    }

    render(){
        if (this.props.step !== 3) {
            return null
        }
        return(
        <Grid container>
                <Grid item md={8} s={12} className="grayBg p-tb-2">
                    <h3 className="p-lr-5">INVOICE  PAID</h3>
                    <p className="p-lr-5">
                       The payment has been done successfully.
                    </p>
                    <img alt='success' width="150" height="150" src={Success } className="m-tb-5" style={{'display':'block', 'margin': 'auto'}}/>
                    <FormControl className="loginForm m-tb-2 m-lr-5">
                    <TextField
                    type="submit"
                    className="yellowSubmit"
                    variant="outlined"
                    value="EXIT"
                    onClick = {this.closeSuccessPayment}
                    />
                    </FormControl>
                </Grid>
                <Hidden smDown>
                    <Grid item md={4} className="darkBg yellowDotsBg"></Grid>
                </Hidden>
            </Grid>
            )
    }

}
export default ThankPayment;