import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import { Dialog} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { toast } from 'react-toastify';
import {Redirect} from 'react-router';
import queryString from 'query-string';
import '../../index.css';
import {
	signin,
	reset,
	forgot,
	authenticate,
	remember,
	isRemembered,
	isAuthenticated
} from '../../auth/index';
import logo from '../../assets/img/Gembah-VertSingleColor-Tagline0.png';
require('dotenv').config();

const LoginTextField = withStyles({
    root: {
        '& label.Mui-focused': {
			color: '#3BA3FF',
        },
        '& .MuiInput-underline:after': {
			borderBottomColor: '#3BA3FF',
        },
        '& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: '#3BA3FF',
				borderWidth: '2px',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#3BA3FF',
			},
		},
	},
})(TextField);

class Login extends Component {
	state = {
		openModalForgot: false,
		openModalReset : false,
		email :'',
		forgotemail: '',
		password :'',
		confirmPassword: '',
		redirect :false,
		resetPassToken : '',
		rememberme : false,
		errorClass : false,
		errorForgotClass: false,
		errorResetClass: false,
	};
	
	componentDidMount() {
		const query = queryString.parse(this.props.location.search);
		if (query.t) {
			this.setState({
				openModalReset : true,
				resetPassToken : query.t,
			});
		}
		
		if (isRemembered()) {
			this.setState({
				email: isRemembered().email,
				password: isRemembered().pass,
				rememberme: true,
			});
		}
	}
	
	handleClickOpenModalForgot = () => {
		this.setState({
			openModalForgot: true,
			forgotemail: ''
		});
	};
	
	handleCloseModalForgot = () => {
		this.setState({ openModalForgot: false });
	}
	
	handleClickOpenModalReset = (event) => {
		event.preventDefault();
		this.setState({
			openModalReset: true,
			openModalForgot: false
		});
	};
	
	handleCloseModalReset = () => {
		this.setState({ openModalReset: false });
	}
	
	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
			errorClass: false,
			errorForgotClass: false,
			errorResetClass: false,
		});
		const {
			email,
			password
		} = this.state;
		
		if (email || password === '') {
			this.setState({
				rememberme: false,
			});
			localStorage.removeItem('gembah_rememberme');
		}
	}
	
	rememberMe = (event) => {
		let remembercheck = event.target.checked;
		this.setState({
			rememberme: remembercheck,
		});
		const {
			email,
			password
		} = this.state;
		if (remembercheck && (email && password !== '')) {
			remember({
				email,
				pass: password
			});
		} else {
			localStorage.removeItem('gembah_rememberme');
		}
	}

	signIn = () => {
		const  { email,password } = this.state
		if (email === '' || password === '') {
			toast.error('Email and password are required');
			this.setState({
				errorClass: true,
			});
		} else {
			const user = {
				email,
				password
			};
			signin(user).then(data => {
				if (data.error) {
					toast.error(data.error);
					this.setState({
						errorClass: true,
					});	
				} else {
					authenticate(data, () => {
						this.setState({
							redirect: true,
						});
					});
				}
			});
		}
	}
	
	handleKeyPress = (e) => {
		if (e.charCode === 13) {
			this.signIn(e);
		}
	}
	
	forgotPassword = () => {
		const {
			forgotemail
		} = this.state;
		let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
		if (forgotemail === '' ) {
			this.setState({
				errorForgotClass: true,
			});
			return toast.error('Email field is required');	
		} 
		if(!re.test(String(forgotemail).toLocaleLowerCase())){
			this.setState({
				errorForgotClass: true,
			});
			return toast.error('Email provided is not valid, please enter a valid email');
		}

		if( forgotemail !== '' && re.test(String(forgotemail).toLocaleLowerCase())) {
			
			forgot(forgotemail).then(data => {
				if (data.message) {
					toast.success(data.message);
					this.handleCloseModalForgot();
				} else {
					toast.error(data.error);
					this.setState({
						errorForgotClass: true,
					});
				}
			});
		}
	}
	
	resetPassword = () => {
		const {
			password,
			confirmPassword,
			resetPassToken
		} = this.state;
		
		if (!password || !confirmPassword) {
			toast.error('Fields are required');
			this.setState({
				errorResetClass: true,
			});
		} else {
			if (password !== confirmPassword) {
				toast.error('Passwords do not match');
				this.setState({
					errorResetClass: true
				});
			} else {
				reset(resetPassToken, confirmPassword).then(data => {
					if (data.message) {
						toast.success(data.message, {
							onClose: () => window.location.href = `${process.env.REACT_APP_DOMAIN_FRONT}/`
						});
					} else {
						toast.error(data.error);
						this.setState({
							errorResetClass : true,
						});
					}	
				});
			}
		}
	}
	
	render() {
		const {
			redirect,
			errorClass,
			errorForgotClass,
			errorResetClass
		} = this.state;

		if (redirect || isAuthenticated()) {
			return <Redirect to={`/${isAuthenticated().companyActually.slug}`}/>;
		}
		
		return(
			<Grid container>
				<Grid item xs={10} sm={6} md={6} lg={3} onKeyPress={this.handleKeyPress} className="m-lr-auto">
					<div className="logoContainer">
						<img width="200" alt="logo" src={logo}/>
					</div>
					<FormControl className="loginForm m-tb-2">
						<LoginTextField
							type="email"
							className={ errorClass ? "loginInput errorClass" : "loginInput"}
							variant="outlined"
							name="email"
							placeholder="Email"
							value={this.state.email}
							onChange={ e => this.onChange(e)}
						/>
                    </FormControl>
                    <FormControl className="loginForm m-tb-2">
						<LoginTextField
							name="password"
							type="password"
							className={ errorClass ? "loginInput errorClass" : "loginInput"}
							variant="outlined"
							placeholder="Password"
							value={this.state.password}
							onChange={ e => this.onChange(e)}
							InputProps={{
								endAdornment: <InputAdornment className="forgot" position="end" onClick={this.handleClickOpenModalForgot}>Forgot?</InputAdornment>,
							}}
						/>
                    </FormControl>
					<FormControl className="loginForm m-tb-5">
						<FormControlLabel
							control={
								<Checkbox
									onChange={e => this.rememberMe(e)}
									name="rememberme"
									className="rememberme"
									checked={this.state.rememberme}
								/>
							} 
							label="Remember me"
						/>
					</FormControl>
					<FormControl className="loginForm">
						<TextField
							id="submit-login"
							type="submit"
							className="yellowSubmit"
							variant="outlined"
							value="LOG IN"
							onClick={this.signIn}
						/>
					</FormControl>
					<p className="account" >Don't have an account? <a href="mailto:support@gembah.com?subject=Sign Up into Gembah App"><span >Sign up</span></a></p>
				</Grid>
				<Dialog
					open={this.state.openModalForgot}
					onClose={this.handleCloseModalForgot}
					aria-labelledby="simple-dialog-title"
					className="fullDialog grayBg"
					maxWidth='md'
					id="forgotPassword"
				>
					<Grid container>
						<Grid item md={8} s={12} className="grayBg">
							<Hidden mdUp>
								<div className="p-lr-5 p-tb-5 whiteBg">
									<span className="cancel" onClick={this.handleCloseModalForgot}>CANCEL</span>
									<span className="regularTitle p-lr-2">FORGOT PASSWORD</span>
								</div>
							</Hidden>
							<Hidden smDown>
								<p className="p-lr-5 "><span className="cancel" onClick={this.handleCloseModalForgot}>CANCEL</span></p>
								<h3 className="p-lr-5 ">FORGOT PASSWORD</h3>
							</Hidden>
							<p className="p-lr-5">Enter your email below to receive instructions to reset your password.</p>
							<FormControl className="loginForm m-tb-2 p-lr-5">
								<LoginTextField
									type="email"
									className={ errorForgotClass ? "loginInput errorClass" : "loginInput"}
									variant="outlined"
									placeholder="Email"
									name="forgotemail"
									onChange={this.onChange}
								/>
							</FormControl>
							<FormControl className="loginForm m-tb-5 p-lr-5">
								<TextField
									type="submit"
									className="yellowSubmit"
									variant="outlined"
									value="RESET"
									onClick={ this.forgotPassword}
								/>
							</FormControl>
						</Grid>
						<Hidden smDown>
							<Grid item md={4} className="darkBg yellowDotsBg"></Grid>
						</Hidden>
					</Grid>
				</Dialog>
				<Dialog
					open={this.state.openModalReset}
					onClose={this.handleCloseModalReset}
					aria-labelledby="simple-dialog-title"
					className="fullDialog grayBg"
					maxWidth='md'
					id='resetPassword'
				>
					<Grid container>
						<Grid item md={8} s={12} className="grayBg">
							<Hidden mdUp>
								<div className="p-lr-5 p-tb-5 whiteBg">
									<span className="cancel" onClick={this.handleCloseModalReset}>CANCEL</span>
									<span className="regularTitle p-lr-2">RESET PASSWORD</span>
								</div>
							</Hidden>
							<Hidden smDown>
								<p className="p-lr-5 "><span className="cancel" onClick={this.handleCloseModalReset}>CANCEL</span></p>
								<h3 className="p-lr-5 ">RESET PASSWORD</h3>
							</Hidden>
							<p className="p-lr-5 ">Please reset your password. The new password must have a minimum of 6 characters.</p>
							<FormControl className="loginForm m-tb-2 p-lr-5">
								<LoginTextField
									type="password"
									name="password"
									className={ errorResetClass ? "loginInput errorClass" : "loginInput"}
									variant="outlined"
									placeholder="Password"
									onChange = {this.onChange}
								/>
							</FormControl>
							<FormControl className="loginForm m-tb-2 p-lr-5">
								<LoginTextField
									type="password"
									name="confirmPassword"
									className={ errorResetClass ? "loginInput errorClass" : "loginInput"}
									variant="outlined"
									placeholder="Confirm password"
									onChange={this.onChange}
								/>
							</FormControl>
							<FormControl className="loginForm m-tb-5 p-lr-5">
								<TextField
									type="submit"
									className="yellowSubmit"
									variant="outlined"
									value="RESET"
									onClick={ this.resetPassword }
								/>
							</FormControl>
						</Grid>
						<Hidden smDown>
							<Grid item md={4} className="darkBg yellowDotsBg"></Grid>
						</Hidden>
					</Grid>
				</Dialog>
			</Grid>
		)
	}
}

export default Login;
