import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import InvoicesModal from './InvoicesModal';
import PaymentMethods from './PaymentMethods';
import ThankPayment from './ThankPayment';

class Billing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step:1,
            invoiceId:''
        }

        this.showPaymentMethod = this.showPaymentMethod.bind(this);
        this.backToBilling = this.backToBilling.bind(this);
        this.showThankyou = this.showThankyou.bind(this);
        this.resetStep = this.resetStep.bind(this);
    }
    
    showPaymentMethod = (id) => {
        let step = this.state.step;
        if ( step === 1) {
            step = step + 1
        }
        this.setState({
            step: step,
            invoiceId: id
        });
    }

    showThankyou = () => {
        let step = this.state.step;
        if ( step === 2) {
            step = step + 1
        }
        this.setState({
            step: step,
        });
    }

    backToBilling = () => {
        let step = this.state.step;
        if (step === 2) {
            step = step - 1
        }
        this.setState({
            step : step
        });
    }

    resetStep = () =>{
        this.setState({
            step: 1,
            invoiceId:''
        });
    }

    render() {
        const {
            step
        } = this.state;
        
        return(
            <Dialog
                open={this.props.billingModal}
                onClose={this.props.closeBilling}
                aria-labelledby="simple-dialog-title"
                className="fullDialog grayBg"
                maxWidth='md'
                id='billing'
            >
                <InvoicesModal 
                    step={step} 
                    closeBilling={this.props.closeBilling}
                    showPayments={this.showPaymentMethod}
                />
                <PaymentMethods
                    step={step}
                    backBilling = {this.backToBilling}
                    invoiceId = {this.state.invoiceId}
                    thankyou = {this.showThankyou}
                />
                <ThankPayment
                    step={step}
                    invoiceId = {this.state.invoiceId}
                    close ={ this.props.closeBilling}
                    reset = {this.resetStep}
                />
            </Dialog>
        )
    }
}

export default Billing;