import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import {
    TextField,
    FormControl
} from '@material-ui/core';
import { OrangeRadio } from '../NewProduct/styles';
import Back from '@material-ui/icons/KeyboardBackspace';
import { required } from './validator';
import { toast } from 'react-toastify';
import Hidden from '@material-ui/core/Hidden';
import Cancel from '@material-ui/icons/Clear';

class Source2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            radioWithGembah: '',
        }
    }

    onChangeRadio = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    nextScreen = () => {

        if (required(this.state.radioWithGembah, 'Option')) {
            return toast.error(required(this.state.radioWithGembah, 'Option'))
        }
        
        if (this.state.radioWithGembah === 'YES') {
            this.props.goTo({
                newProduct: false,
                withGembah: false,
                finishedProducts: true
            });
        } else if (this.state.radioWithGembah === 'NO') {
            this.props.goTo({
                newProduct: true,
                withGembah: false,
                finishedProducts: false
            });
        } else {
            return toast.error('Choose a valid option');
        }
    }
    backScreen = () => {
        this.props.goTo({
            helpMeSource: true,
            withGembah: false,
            finishedProducts: false
        });
    }
    render() {
        const {
            radioWithGembah
        } = this.state;
        return(
            <Grid container >
                <Grid item sm={12} className="grayBg p-lr-5 p-tb-2 helpmeSource">
                    <span className="cancel" onClick={this.backScreen}><Back/></span>
                    <Hidden smUp>
                    <span className="floatRight"><Cancel onClick={this.props.closeAll} className="cancel" /></span>
                    </Hidden>
                    <div className="m-tb-5">
                    <h3 className="m-tb-2 textCenter">Help me Source</h3>
                    <p className="p-lr-5">Did Gembah help you manufactured this?</p>
                    <div className=" p-lr-5">
                        <FormControl className="loginForm m-t-2"> 
                            <OrangeRadio
                                value="YES"
                                onChange={ e => this.onChangeRadio(e) }
                                checked= { radioWithGembah === 'YES'}
                                name="radioWithGembah"
                            />
                            <p className="inlineBlock relative " style={{'top':'2px'}}><span className="bold">Yes</span>, Gembah helped me</p>
                        </FormControl>
                        <FormControl className="loginForm m-t-2">
                            <OrangeRadio
                                value="NO"
                                onChange={ e => this.onChangeRadio(e) }
                                checked= { radioWithGembah === 'NO'}
                                name="radioWithGembah"
                            />
                           <p className="inlineBlock relative " style={{'top':'2px'}}><span className="bold">No</span>, someone else helped me</p>
                        </FormControl>
                        <FormControl className="loginForm m-t-2">
                            <TextField
                                type="submit"
                                className="yellowSubmit"
                                variant="outlined"
                                value="NEXT"
                                onClick={ this.nextScreen }
                            />
                        </FormControl>
                    </div>
                    </div>
                  
                </Grid>
            </Grid>
        );
    };
};

export default Source2;