import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Hidden from '@material-ui/core/Hidden';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isAuthenticated } from '../../auth/index';
import TaskDetails from '../TaskDetails';
import SocketContext from '../../socket-context';
import { toast } from 'react-toastify';

const TaskSearchField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#3BA3FF',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#3BA3FF',
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#3BA3FF',
                borderWidth: '2px',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#3BA3FF',
            },
        },
    },
})(TextField);

class TasksSidebar extends Component {
    constructor(){
        super();
        this.state = {
            openFullTasks : false,
            tasksList : [],
            loading : false,
        }
    }
    
    componentDidMount() {
        this.fetch();
        const {
            socket
        } = this.props;
        socket.on('newTaskAssigned', (response) => {
            let data = [response.data];
            this.setState({
                tasksList: [
                    ...data,
                    ...this.state.tasksList
                ]
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.reloadInfo) {
            this.fetch();
        }
    }

    
    openTasksFull = () => {
        this.setState({
            openFullTasks: true
        });
    };

    closeTasksFull = () => {
        this.setState({ openFullTasks: false });
    };
    
    fetch() {
        this.setState({
            loading : true,
        });
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/task/${isAuthenticated().companyActually.slug}/myTasks`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                toast.error(json.error);
            }
            this.setState({
                tasksList: json,
                loading: false,
            });
        })
        .catch(err => console.log(err));
    }

    searchTask = (e) => {
        const search = e.target.value;
        if (search.length >= 3) {
            fetch(`${process.env.REACT_APP_DOMAIN_BACK}/task/${isAuthenticated().companyActually.slug}/search`, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    search: search
                }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${isAuthenticated().token}`,
                },
            })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    tasksList: json
                });
            })
            .catch(err => console.log(err));
        } else {
            this.fetch();
        }
    }

    toggleClass(status)  {
        switch (status) {
            case "DESIGN" : return 'statusDesign';
            case "PRE-PRODUCTION" : return 'statusPre';
            case "PRODUCTION" : return 'statusProd';
            case "SHIPPING" : return 'statusShipping';
            case "FULFILLED" : return 'statusFulfilled';
            default : return 'statusDesign';
        }
    }

    render() {
        const {
            tasksList,
            loading
        } = this.state;

        return(
            <div className="task-sidebar">
                <h2 className="inlineBlock">{isAuthenticated().first_name}'S TASKS</h2>
                <Hidden xsDown>
                    <FormControl className="searchTasks m-tb-2">
                        <TaskSearchField
                            placeholder = "Search your tasks..."
                            type = "text"
                            onChange={this.searchTask}
                            variant="outlined"
                            InputProps = {{
                                startAdornment: <InputAdornment position="start" ><SearchIcon/></InputAdornment>,
                            }}
                        />
                    </FormControl>
                    <div>
                        <ul className="taskList p-tb-5">
                            {
                                loading ? 
                                <div style={{width: '100px',margin : 'auto'}}><CircularProgress/></div>
                                 :
                                Object.keys(tasksList).length ?
                                    tasksList.map((task,index) => (
                                        <TaskDetails
                                            onProduct={false}
                                            key={task._id}
                                            task={task}
                                        />
                                    )) : <p className="account">No tasks assigned to you</p>
                            }
                        </ul>
                    </div>
                </Hidden>
                <Hidden smUp>
                    <div>
                        <ul className="taskList p-tb-5">
                            {
                                loading ? 
                                <div style={{width: '100px',margin : 'auto'}}><CircularProgress/></div>
                                 :
                                Object.keys(tasksList).length ?
                                    tasksList.slice(0,5).map((task,index) => (
                                        <TaskDetails
                                            onProduct={false}
                                            key={task._id}
                                            task={task}
                                        />
                                    )) : <p className="account">No tasks assigned to you</p>
                            }
                        </ul>
                        {
                            Object.keys(tasksList).length > 5 ?   
                            <div className="forgot " id="moreTasks" onClick={this.openTasksFull}>
                                <p><AddIcon />More tasks</p>
                            </div> : ''
                        }
                    </div>
                </Hidden>
                <Dialog
                    open={this.state.openFullTasks}
                    onClose={this.closeTasksFull}
                    aria-labelledby="simple-dialog-title"
                    className = "grayBg"
                    id="full-tasks"
                    fullScreen
                >
                    <div className="p-lr-5 p-tb-5 whiteBg">
                        <span className="floatLeft workSans" onClick={this.closeTasksFull}>X</span>
                        <h3 className="textCenter m-0">YOUR TASKS</h3>
                        <FormControl className="searchTasks m-tb-2">
                            <TaskSearchField
                                placeholder = "Search your tasks..."
                                type="text"
                                variant="outlined"
                                onChange={this.searchTask}
                                InputProps = {{
                                    startAdornment: <InputAdornment position="start" ><SearchIcon/></InputAdornment>
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="p-lr-2">
                        <ul className="taskList p-tb-5">
                            {
                                loading ? 
                                <div style={{width: '100px',margin : 'auto'}}><CircularProgress/></div>
                                 :
                                Object.keys(tasksList).length ?
                                    tasksList.map((task, index) => (
                                        <TaskDetails
                                            onProduct={false}
                                            key={task._id}
                                            task={task}
                                        />
                                    )) : <p className="account">No tasks assigned to you</p>
                            }
                        </ul>
                    </div>
                </Dialog>
            </div>
        )
    }
}

const TasksSidebarWithSocket = props => (
    <SocketContext.Consumer>
        {socket => <TasksSidebar {...props} socket={socket} />}
    </SocketContext.Consumer>
  )

export default TasksSidebarWithSocket;