import React , {Component } from 'react';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { isAuthenticated } from '../../auth/index';
import UpdateUser from './UpdateUser';
import {toast} from 'react-toastify';
import Moment from 'react-moment';

class UsersList extends Component {
    constructor(props){
        super(props);
        this.state = {
            users: [],
            isLoading: false,
            editUser : '',
            openConfirmation:false,
            userToDelete:'',
            ownerWarning : false
        }
    }
    

    showUsersList = () => {
        this.setState({ isLoading: true });
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/user/list`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
           this.setState({
               users : json,
               isLoading: false,
           });
        })
        .catch(err => {
            this.setState({ isLoading: false });
            toast.error(err);
        });
    }
    
    componentDidMount(){
        this.showUsersList();
    }
   
    deleteUser = () => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/user/${this.state.userToDelete}/delete`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error);
            }
            toast.success(json.message);
            this.handleClose();
            this.showUsersList();
        })
        .catch(err => console.log(err));
    }

    editUser = (id) => {
        const { users } = this.state;
        for (var i = 0; i < users.length; i++) {
            if (users[i]._id === id) {
                let userToEdit = users[i];
                this.setState({
                    editUser: userToEdit
                  
                });
                this.props.change({
                    isEditing: true
                });
            }
        }
    }

    changeView = () => {
        this.props.change({
            isEditing: false
        });
        this.showUsersList();
    }
    handleClose = () => {
        this.setState({
            openConfirmation: false,
            userToDelete:''
        })
    }
    closeOwnerWarning = () => {
        this.setState({
            ownerWarning: false
        })
    }
    openConfirmationDelete = (userId, role) => {

        if(role === 'owner'){
            this.setState({
                ownerWarning: true
            })
        }else{
            this.setState({
                openConfirmation : true,
                userToDelete : userId
            })
        }
        
    }

    render(){
   
        const {users, editUser, openConfirmation, ownerWarning } = this.state;
        if(this.props.editing){
            return <UpdateUser user={editUser} view={this.changeView.bind(this)}/>
        }
        ;
        return (
            <>
           <Grid container>
               <Grid item xs={12} className="m-lr-auto">
                   <h3 className="p-lr-2">Registered Users </h3>
                   <Table id="usersListTable">
                    <TableHead>
                    <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Creation Date</TableCell>
                        <TableCell>Last Updated</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                    users.map((user,index) => {
                        return(
                            <TableRow key={index}>   
                                <TableCell>{user.first_name}</TableCell>
                                <TableCell>{user.last_name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.role}</TableCell>
                                <TableCell><Moment format="DD/MM/YYYY">{user.created}</Moment></TableCell>
                                <TableCell><Moment format="DD/MM/YYYY">{user.updated}</Moment></TableCell>       
                                <TableCell>
                                <IconButton  onClick={()=>this.openConfirmationDelete(user._id, user.role)} edge="end" aria-label="Delete">
                                <DeleteIcon  />
                                </IconButton>
                                <IconButton onClick={()=>this.editUser(user._id)}  edge="end" aria-label="Edit">
                                <CreateIcon/>
                                </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    })     
                    }   
                    </TableBody>
                  </Table>
               </Grid>
            </Grid>
            <Dialog
                open={openConfirmation}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                <p className="m-0">Delete?</p>
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description" className="workSans">
                   Are you sure you want to delete a user from the list?
                </DialogContentText>
                </DialogContent>
                <DialogActions  >
                <Button onClick={this.handleClose}  className="actionsConfirmation">
                    Cancel
                </Button>
                <Button onClick={this.deleteUser}  className="actionsConfirmation" autoFocus>
                    Delete
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog
            open={ownerWarning}
            onClose={this.closeOwnerWarning}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                <DialogContentText id="alert-dialog-description" className="workSans">
                <p> We cannot delete this user because they are listed as the owner of this company. 
                Every company must have 1 owner assigned to it. </p>
                <p>If you want to proceed with the deletion of this user, 
                contact us at <a href="mailto:support@gembah.com">support@gembah.com</a></p>
                </DialogContentText>
                </DialogContent>
                <DialogActions  >
                <Button onClick={this.closeOwnerWarning}  className="actionsConfirmation">
                    ok
                </Button>
                </DialogActions>
            </Dialog>
            </>
        );
    }
}
export default UsersList
