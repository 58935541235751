import React, { Component } from 'react';

class PreProductionIcon extends Component {
    render() {
        return(
            <svg version="1.1" height='40px' width='40px' fill={this.props.color} id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-429 231 100 100" >
                <path d="M-408.1,299.7l2-3.1c4.9,2,10.5,1.5,15-1.4l8.5,11c-2,3.3-0.5,7.8,3.5,9c1.5,0.4,3.1,0.3,4.5-0.5c2.7-1.5,3.7-4.6,2.8-7.3
                    h0.1l19.3-10.3c0.1-0.1,0.2-0.1,0.2-0.2c4.5,4.8,12.4,4.5,16.4-1.2c1.6-2.2,2.2-5.2,1.6-8c-1-4.5-4.6-7.6-8.7-8.2v-16.3
                    c-0.9,0-2,0-3,0v16.3c-0.8,0.1-1.7,0.3-2.5,0.7c0-0.1,0-0.1-0.1-0.2l-9-13.3c0,0-0.1,0-0.1-0.1c2.3-2.4,2.3-6.2-0.1-8.5
                    c-2.4-2.3-6.2-2.3-8.5,0.1c-0.3,0.3-0.5,0.6-0.7,0.9c-0.1-0.1-0.1-0.1-0.2-0.2l-32.9-12v-0.3c0-2.9-1.9-5.5-4.8-6.1
                    c-3.9-0.8-7.2,2.2-7.2,5.9c0,3.3,2.7,6,6,6l3,12.8c-9,1.7-14.9,10.3-13.2,19.3c0.8,4.5,3.5,8.4,7.3,10.8l-1.6,2.5 M-364.8,261.5
                    c0.4-1.2,1.7-2,3-2c1,0,1.9,0.5,2.4,1.4c1.2,2.3-0.4,4.6-2.6,4.6C-363.9,265.5-365.5,263.6-364.8,261.5z M-406,249.4
                    c-1.7,0-3-1.3-3-3c0-1.7,1.3-3,3-3s3,1.3,3,3C-403,248.1-404.3,249.4-406,249.4z M-403.4,251.9c1-0.5,1.9-1.2,2.5-2.1l32.9,12.1h0.2
                    v0.6c0,0.3,0,0.7,0.1,1l-17.8,10c-2.9-5.2-8.4-8.5-14.4-8.5l-3-13.2L-403.4,251.9z M-397.7,294.7c-9.2,1.5-17.1-6.4-15.6-15.6
                    c0.9-5.6,5.4-10.1,11-11c9.2-1.5,17.1,6.4,15.6,15.6C-387.6,289.3-392.1,293.8-397.7,294.7z M-377.5,312.4c-1.7,0-3-1.3-3-3
                    c0-1.7,1.3-3,3-3s3,1.3,3,3C-374.5,311.1-375.8,312.4-377.5,312.4z M-373.1,304.8c-0.1,0.1-0.2,0.1-0.3,0.2
                    c-1.9-1.8-4.8-2.2-7.1-0.8l-8.3-10.7c2.3-2.2,4-5,4.8-8.1l29,4.6c0,1.6,0.4,3.1,1.1,4.5L-373.1,304.8z M-354.6,287l-28.9-4.6v-1
                    c0-1.8-0.3-3.6-0.9-5.3l17.7-10c1.5,2,4.2,2.8,6.6,1.9c0,0.1,0.1,0.2,0.1,0.2l9,13.4C-352.7,283-354,284.9-354.6,287z M-337.1,291.1
                    c-0.5,3.2-3.1,5.7-6.2,6.2c-5.1,0.8-9.4-3.5-8.6-8.6c0.5-3.2,3.1-5.7,6.2-6.2C-340.6,281.7-336.3,286-337.1,291.1z"/>
                <path  d="M-398.9,322.3c-8.4,0-16.7,0-25.1,0c0-7.4,0-14.8,0-22.3c1.2,0,2.4,0,3.6,0c0,4.8,0,9.6,0,14.5
                    c2.4-1.3,4.8-2.7,7.2-4c0,1.4,0,2.7,0,4.1c2.4-1.3,4.8-2.7,7.2-4c0,1.3,0,2.6,0,4c2.4-1.3,4.8-2.6,7.2-4
                    C-398.9,314.5-398.9,318.4-398.9,322.3z"/>
                <g>
                    <path d="M-391.1,278.5c-2.6-2.5-5.6-3.8-9.2-3.8c-3.5,0-6.6,1.3-9.1,3.8c-0.4-0.4-0.8-0.8-1.1-1.1c5.2-5.5,14.7-5.9,20.5,0
                        C-390.3,277.7-390.7,278.1-391.1,278.5z"/>
                    <path d="M-392.8,280.2c-0.4,0.4-0.8,0.7-1.1,1.1c-1.7-1.7-3.8-2.6-6.3-2.6s-4.6,0.9-6.3,2.7c-0.4-0.4-0.7-0.8-1.1-1.1
                        C-403.9,276.2-396.9,275.9-392.8,280.2z"/>
                    <path d="M-395.6,283c-0.4,0.4-0.8,0.7-1.1,1.1c-2.3-2-4.7-2-7,0c-0.4-0.4-0.7-0.7-1.1-1.1C-402.7,280.6-398.3,280.2-395.6,283z"/>
                </g>
                <path  d="M-335.3,239.7c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.5,0.3,0.7c0,0.1,0,0.1,0,0.2c0,4.1,0,8.3,0,12.4
                    c0,0.4-0.1,0.8-0.4,1c-0.2,0.2-0.4,0.2-0.6,0.3c-0.1,0-0.1,0-0.2,0c-6.1,0-12.3,0-18.4,0c-0.4,0-0.8-0.2-1-0.5
                    c-0.1-0.2-0.2-0.3-0.2-0.5c0-4.3,0-8.7,0-13.1c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.4-0.4,0.7-0.5C-348,239.7-341.7,239.7-335.3,239.7
                    z M-354,241c0,4.1,0,8.3,0,12.4c6.1,0,12.2,0,18.3,0c0-4.1,0-8.3,0-12.4C-341.8,241-347.9,241-354,241z"/>
                <g>
                    <line  x1="-339.1" y1="259.2" x2="-350.9" y2="259.2"/>
                    <path d="M-346.8,255.1c1.3,0,2.6,0,3.9,0c0,0.1,0,0.1,0,0.2c0,0.6,0,1.2,0,1.8c0,0.7,0.6,1.2,1.3,1.2c0.4,0,0.9,0,1.3,0
                        c0.5,0,0.8,0.5,0.6,1c-0.1,0.2-0.3,0.3-0.5,0.3c0,0-0.1,0-0.2,0c-3,0-6,0-9,0c-0.3,0-0.5-0.1-0.7-0.4c-0.2-0.4,0.1-0.9,0.6-0.9
                        c0.4,0,0.8,0,1.2,0c0.9,0,1.4-0.5,1.4-1.4c0-0.5,0-1.1,0-1.6C-346.8,255.2-346.8,255.2-346.8,255.1z"/>
                </g>
                <circle cx="-400" cy="286.9" r="2.1"/>
                <path  d="M-331.6,349.6"/>
                <path  d="M-406.5,302"/>
                <line  x1="-407.9" y1="299.4" x2="-407.1" y2="300"/>
                <line  x1="-411.6" y1="296.5" x2="-407.9" y2="299.4"/>
                <path  d="M-342.3,263.5"/>
                <line  x1="-343.7" y1="263.5" x2="-342.9" y2="263.5"/>
                <line  x1="-344.1" y1="263.5" x2="-343.7" y2="263.5"/>
                <line  x1="-345.9" y1="263.5" x2="-344.1" y2="263.5"/>
                <line  x1="-346.7" y1="263.5" x2="-345.9" y2="263.5"/>
            </svg>
        )
    }
}

export default PreProductionIcon;