import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';

class Footer extends Component {

    render(){
        let date = new Date();
        return(
            <Grid item xs={12} className="darkBg">
                <p className="textCenter account">© {date.getFullYear()} ALL RIGHTS RESERVED, GEMBAH.</p>
                <p className="textCenter account "> 
                    <a className="link" rel="noopener noreferrer" href="https://gembah.com" target="_blank">
                        www.gembah.com
                    </a>
                </p>
            </Grid>
        )
    }

}
export default Footer;