import React,{Component} from 'react';



class ProductCycle extends Component{

    activeColor(status){
        let design= false;
        let pre = false;
        let prod = false;
        let shipping = false;
        let fulf = false;
        switch (status) {
            case "DESIGN" : design = true;
                            break;
            case "PRE-PRODUCTION" :  design= true;
                                     pre = true;
                                    break; 
            case "PRODUCTION" :  design = true;
                                 pre = true;
                                 prod = true;
                                break;
            case "SHIPPING" :  design = true;
                               pre = true;
                               prod = true;
                               shipping = true; 
                                break;
            case "FULFILLED" : design = true;
                               pre = true;
                               prod = true;
                               shipping = true;
                               fulf = true;
                                break;
            default : return design = true;;
        }

        return(
            <div>
               
            <ul id="progressStatus">
                <li alt="Design" className ={ design ? 'stepDesign' : ''}><span className="tooltiptext">Design</span></li>
                <li alt="Pre-production" className = { pre ? 'stepPre' : ''}><span className="tooltiptext">Pre-Production</span></li>
                <li alt="Production" className = { prod ? 'stepProd' : ''}><span className="tooltiptext">Production</span></li>
                <li alt="Shipping" className = { shipping ? 'stepShipping' : ''}><span className="tooltiptext">Shipping</span></li>
                <li alt="Fulfilled" className = { fulf ? 'stepFulf' : ''}><span className="tooltiptext">Fulfilled</span></li>
            </ul>
        </div>
        );
    }
 
    toggleClass(status)  {
        switch (status) {
            case "DESIGN" : return 'statusDesign';
            case "PRE-PRODUCTION" : return 'statusPre';
            case "PRODUCTION" : return 'statusProd';
            case "SHIPPING" : return 'statusShipping';
            case "FULFILLED" : return 'statusFulfilled';
            default : return 'statusDesign';
        }
    };

    render(){
       
        const status = this.props.status;
        let string = `${status}`;
       
        return(
            <section>
               
                {
                    this.activeColor(this.props.status)
                }
                <div className='p-lr-5 flex' id="productLifecycleInfo">
                    <p>Phase:</p> 
                    <div className={`m-lr-2 taskStatus ${this.toggleClass(this.props.status)}`}></div>
                    <p className="bold capitalize">{string.toLowerCase()}</p>
                </div>
            </section>
            
        )
    }

}
export default ProductCycle;