import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {
    TextField,
    FormControl,
    Button,
    CircularProgress,
    Select,
    MenuItem,
    Dialog
} from '@material-ui/core';
import Pencil from '@material-ui/icons/Create'
import Cancel from '@material-ui/icons/Clear';
import { isAuthenticated } from '../../../auth/index';
import { ProductTextField } from '../../NewProduct/styles';
import { toast } from 'react-toastify';
import {
    getCountries,
    getCity
} from '../../NewProduct/Warehouse/functions';
import {
    lt,
    required,
    isInt
} from '../../HelpReorder/validator';

var newWarehouse;
class EditSku extends Component {
    constructor(props) {
       super(props)
       this.state = {
           isLoading: false,
           editName: false,
           editQuantity: false,
           editShippingType: false,
           editPackagingType: false,
           editWarehouse: false,
           errorPackagingTypeReorder: false,
           errorQuantityReorder: false,
           errorPackagingOtherReorder: false,
           errorAddressReorder: false,
           errorNewCity: false,
           errorNewState: false,
           errorNewZipcode: false,
           errorName: false,
           newName: '',
           newQuantity: '',
           newPackagingOther: '',
           newShipping: '',
           newPackaging: '',
           newAddress: '',
           newCountry: 'US',
           newZipcode: '',
           newState: '',
           newCity: '',
           countries: [],
        }
    }
 
    onChange = (e) => {
        if (e.target.name === 'newCountry') {
            return this.setState({
                newCountry: e.target.value,
                newCity: '',
                newState: '',
                newZipcode: '',
                newAddress: ''
            });
        }

        if (e.target.name === 'newZipcode' && lt(e.target.value.length, 4)) {
            const country = this.state.newCountry;
            const address = e.target.value;
			getCity(address, country).then(data => {
                if (data.results.length > 0) {
                    if (country === 'US') {
                        return this.setState({
                            newCity: data.results[0].address_components[3].long_name,
                            newState: data.results[0].address_components[4].short_name
                        })
                    } else {
                        return this.setState({
                            newCity: data.results[0].address_components[2].long_name,
                            newState: data.results[0].address_components[3].short_name
                        });
                    }
                } else {
                    toast.error('Results not found');
                }
            });
        }
        
        return this.setState({
            [e.target.name]: e.target.value
        });
    }
   
    editQuantityField = () => {
        this.setState( prevState => ({
            editQuantity: !prevState.editQuantity
        }));
    }

    editShippingField = () => {
        this.setState(prevState => ({
            editShippingType: !prevState.editShippingType
        }));
    }

    editPackagingField = () => {
        this.setState( prevState => ({
            editPackagingType: !prevState.editPackagingType
        }));
    }

    editNameField = () => {
        this.setState( prevState => ({
            editName: !prevState.editName
        }));
    }
    editWarehouseField = () => {
        this.getCountries()
        this.setState(prevState => ({
            editWarehouse: !prevState.editWarehouse
        }));
    }

    validateAndEdit= () => {
        let body = {};
        let shipp;
        const {
            editPackagingType,
            editQuantity,
            editShippingType,
            editWarehouse,
            editName
        } = this.state;

        /** IF THE PACKAGING TYPE WAS EDITED CHECKS FOR VALIDATIONS  */
        if (editPackagingType) {
            this.setState({
                errorPackagingTypeReorder: false
            });

            if (required(this.state.newPackaging, 'Packaging Type')) {
                this.setState({
                    errorPackagingTypeReorder: true
                });
                return toast.error(required(this.state.newPackaging, 'Packaging Type'));
            }
            const packaging = {
                packaging_type: this.state.newPackaging
            }
            body = Object.assign(body,packaging);
        }

        /** IF THE NAME WAS EDITED CHECKS FOR VALIDATIONS  */
        if (editName) {
            this.setState({
                errorName: false,   
            });

            if (required(this.state.newName, 'Product Name')) {
                this.setState({
                    errorName: true
                });
                return toast.error(required(this.state.newName, 'Product Name'));
            }
            const name = {
              name:  this.state.newName
            }
            
            body = Object.assign(body,name);
        }
        
        /* IF THE SHIPPING TYPE WAS EDITED CHECK FOR VALIDATIONS*/ 
        if (editShippingType) {
            const {
                newPackagingOther
            } = this.state;
    
            if (this.state.newShipping === 'OTHER' &&  required(this.state.newPackagingOther, 'Other option')) {
                this.setState({
                    errorPackagingOtherReorder: true
                });
                return toast.error(required(this.state.newPackagingOther, 'Other option'));
            }

            if (this.state.newShipping === 'OTHER' && !required(this.state.newPackagingOther, 'Other option') ) {
               shipp = newPackagingOther
            } else {
                shipp = this.state.newShipping
            }
            
            const target = {
                target_price: {
                    format: shipp,
                    value: this.props.price
                }
            }
            body = Object.assign(body,target);
        }
        
        /* IF THE QUANTITY WAS EDITED CHECK FOR VALIDATIONS*/ 
        if (editQuantity) {
            this.setState({
                errorQuantityReorder: false,   
            });

            if (required(this.state.newQuantity, 'Quantity')) {
                this.setState({
                    errorQuantityReorder: true
                });
                return toast.error(required(this.state.newQuantity, 'Quantity'));
            }

            if (isInt(this.state.newQuantity, 'Quantity')) {
                this.setState({
                    errorQuantityReorder: true
                });
                return toast.error('Quantity Invalid');
            }

            const quantity = {
                target_quantity: this.state.newQuantity
            };

            body = Object.assign(body,quantity);
        }

        /* IF THE WAREHOUSE WAS EDITED CHECKS FOR VALIDATIONS*/ 
        if (editWarehouse) {    
            this.setState({
                errorAddressReorder: false,
                errorNewZipcode: false,
                errorNewCity: false,
                errorNewState: false,
            });

            if (required(this.state.newAddress, 'Address')) {
                this.setState({
                    errorAddressReorder: true
                });
                return toast.error(required(this.state.newAddress, 'Address'));
            }

            if (!lt(this.state.newAddress.length, 2)) {
                this.setState({
                    errorAddressReorder: true
                });
                return toast.error('Invalid Address');
            }

            if (required(this.state.newZipcode, 'Zip Code')) {
                this.setState({
                    errorNewZipcode: true
                });
                return toast.error(required(this.state.newZipcode, 'Zip Code'));
            }

            if (!lt(this.state.newZipcode.length, 2)) {
                this.setState({
                    errorNewZipcode: true
                });
                return toast.error('Invalid Zip Code');
            }
            
            if (required(this.state.newCity, 'City')) {
                this.setState({
                    errorNewCity: true
                });
                return toast.error(required(this.state.newCity, 'City'));
            }

            if (!lt(this.state.newCity.length, 2)) {
                this.setState({
                    errorNewCity: true
                });
                return toast.error('Invalid City');
            }

            if (required(this.state.newState, 'State')) {
                this.setState({
                    errorNewState: true
                });
                return toast.error(required(this.state.newState, 'State'));
            }

            if (!lt(this.state.newState.length, 1)) {
                this.setState({
                    errorNewState: true
                });
                return toast.error('Invalid State');
            }
            
            const {
                newCity,
                newState,
                newZipcode,
                newAddress,
                newCountry
            } = this.state;
            
            newWarehouse = {
                warehouse : [
                    {
                        country: newCountry,
                        address: newAddress,
                        zipcode: newZipcode,
                        city: newCity,
                        state: newState,
                    }
                ]
            }
            
            body = Object.assign(body,newWarehouse);
        } else {
            newWarehouse = [];
        }
        
        /*SEND DATA*/
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/edit/${isAuthenticated().companyActually.slug}/${this.props.product.slug}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
            body: JSON.stringify(body),
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                return toast.error(json.error)
            }
            this.close();
            toast.success('Edited succesfully');
            return this.props.refreshProducts({
                company: json.product.companyOwner.slug,
                product: json.product.slug
            });
        }).catch(err => console.log(err));
    }

    getCountries = () => {
        getCountries().then(data => {
            const filteredCountries = data.filter(aceptedCountries => aceptedCountries.region === "Europe" || aceptedCountries.name === "Mexico" || aceptedCountries.name === "United States" || aceptedCountries.name === "Canada");
			this.setState({
                countries: filteredCountries,
			});
        })
    }

    close = () => {
        this.setState({
            isLoading: false,
            editQuantity: false,
            editShippingType: false,
            editPackagingType: false,
            editWarehouse: false,
            errorPackagingTypeReorder: false,
            errorQuantityReorder: false,
            errorPackagingOtherReorder: false,
            errorAddressReorder: false,
            errorNewCity: false,
            errorNewState: false,
            errorNewZipcode: false,
            newQuantity: '',
            newPackagingOther: '',
            newShipping: 'FOB',
            newPackaging: '',
            newAddress: '',
            newCountry: 'US',
            newZipcode: '',
            newState: '',
            newCity: '',
            countries: []
        });

        this.props.closeModal({
            openEdit : false,
        });
    }

    render() {
        const {
            isLoading,
            editQuantity,
            newName,
            newQuantity,
            newShipping,
            newPackaging,
            newPackagingOther,
            newCountry,
            newAddress,
            newZipcode,
            newState,
            newCity,
            errorName,
            errorAddressReorder,
            errorPackagingTypeReorder,
            errorPackagingOtherReorder,
            errorQuantityReorder,
            errorNewCity ,
            errorNewState,
            errorNewZipcode,
            editName,
            editShippingType,
            editPackagingType,
            editWarehouse,
            countries,
        } = this.state;

        const {
            product
        } = this.props;
        
        return(
            <Dialog
                open={this.props.open}
                onClose={this.props.closeModal}
                aria-labelledby="simple-dialog-title"
                className="fullDialog grayBg"
                id="full-specs"
                maxWidth='md'
            >
                <Grid container>
                    <Grid item xs={12} className="grayBg p-lr-5 p-tb-2">
                        <span className="cancel"><Cancel onClick={this.close}/></span>
                        <h3 className="p-lr-5 textCenter">EDIT SKU</h3>
                        <p className="p-lr-5 ">Below is the information from this product. Take a look and edit any information that is relevant.</p>
                        {
                            isLoading ? <div className="textCenter" style={{'width': '100px'}}><CircularProgress/></div> : <div id="previousOrder" className="p-lr-5">
                                <div className="nonEditableInfo m-t-2">
                                    <p className="bold">Product Name:</p>
                                    {
                                        editName ? <div>
                                            <ProductTextField
                                                type="text"
                                                className={errorName ? "loginInput reorderInput errorClass" : "reorderInput loginInput"}
                                                variant="outlined"
                                                value={newName}
                                                placeholder={ product.name }
                                                name="newName"
                                                onChange={e => this.onChange(e)}
                                            />
                                            <Button className="editPreviousOrder" onClick={this.editNameField}>
                                                <Cancel/>
                                            </Button>
                                        </div> : <p>
                                            { product.name }
                                            <Button className="editPreviousOrder" onClick={this.editNameField}>
                                                <Pencil/>
                                            </Button>
                                        </p>
                                    }
                                </div>
                                {
                                    isAuthenticated().role === 'administrator' || isAuthenticated().role === 'staff'? <div className="nonEditableInfo m-t-2 ">
                                        <p className="bold">Price</p>
                                        <p>${product.target_price !== undefined ? product.target_price.value : null}</p>
                                    </div> : null
                                }
                                <div className="nonEditableInfo m-t-2">
                                    <p className="bold">Quantity</p>
                                    {
                                        editQuantity ? <div>
                                            <ProductTextField
                                                type="number"
                                                className={errorQuantityReorder ? "loginInput reorderInput errorClass" : "reorderInput loginInput"}
                                                variant="outlined"
                                                value={newQuantity}
                                                placeholder={
                                                    (product.target_quantity).toString()
                                                }
                                                name="newQuantity"
                                                onChange={e => this.onChange(e)}
                                            />
                                            <Button className="editPreviousOrder" onClick={this.editQuantityField}>
                                                <Cancel/>
                                            </Button>
                                        </div> : <p>
                                            { product.target_quantity }
                                            <Button className="editPreviousOrder" onClick={this.editQuantityField}>
                                                <Pencil/>
                                            </Button>
                                        </p>
                                    }
                                </div>
                                <div className="nonEditableInfo m-t-2">
                                    <p className="bold">Shipping Type</p>
                                    {
                                        editShippingType ? <div id="editingShipping">
                                            <Select
                                                name="newShipping"
                                                variant="outlined"
                                                value={newShipping}
                                                onChange={ e => this.onChange(e)}
                                                className='loginInput  selectForm productsInput'
                                            >
                                                <MenuItem className="workSans"  value="FOB">FOB</MenuItem>
                                                <MenuItem className="workSans" value="DDP">DDP</MenuItem>
                                                <MenuItem className="workSans" value="EXW">EXW</MenuItem>
                                                <MenuItem className="workSans" value="OTHER">Other</MenuItem>
                                            </Select>
                                            {
                                                newShipping === 'OTHER' ? <ProductTextField
                                                    type="text"
                                                    placeholder="Other option"
                                                    className={
                                                        errorPackagingOtherReorder ? "loginInput m-t-2 errorClass" : "m-t-2 loginInput"
                                                    }
                                                    variant="outlined"
                                                    value={newPackagingOther}
                                                    name="newPackagingOther"
                                                    onChange={e => this.onChange(e)}
                                                /> : null
                                            }
                                            <Button className="editPreviousOrder absolute" onClick={this.editShippingField}>
                                                <Cancel />
                                            </Button>
                                        </div> : <p>{ product.target_price !== undefined ? product.target_price.format : null } <Button className="editPreviousOrder" onClick={this.editShippingField}><Pencil/></Button></p>
                                    }
                                </div>
                                <div className="nonEditableInfo m-t-2">
                                    <p className="bold">Packaging Type</p>
                                    {
                                        editPackagingType ? <div>
                                            <ProductTextField
                                                type="text"
                                                className={errorPackagingTypeReorder ? "loginInput reorderInput errorClass" : "reorderInput loginInput"}
                                                variant="outlined"
                                                value={newPackaging}
                                                placeholder={product.packaging_type }
                                                name="newPackaging"
                                                onChange={e => this.onChange(e)}
                                            />
                                            <Button className="editPreviousOrder" onClick={this.editPackagingField}>
                                                <Cancel />
                                            </Button>
                                        </div> : <p>{ product.packaging_type } <Button className="editPreviousOrder" onClick={this.editPackagingField}><Pencil/></Button></p>
                                    }
                                </div>
                                <FormControl className="loginForm m-t-2">
                                    <p className="bold">Warehouse</p>
                                    {
                                        editWarehouse ? <FormControl id="editWarehouse">
                                            <Select
                                                name="newCountry"
                                                variant="outlined"
                                                value={newCountry}
                                                onChange={ e => this.onChange(e)}
                                                className='loginInput selectForm productsInput'
                                                id='selectCountry'
                                            >
                                                {
                                                    countries.map((country,index) => (
                                                        <MenuItem
                                                            className="workSans"
                                                            key={index}
                                                            value={country.alpha2Code}
                                                        >
                                                            {country.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            <ProductTextField
                                                type="text"
                                                className={errorAddressReorder ? "loginInput m-t-2  errorClass" : " m-t-2 loginInput"}
                                                variant="outlined"
                                                placeholder = "Street name, number"
                                                value={newAddress}
                                                name="newAddress"
                                                onChange={e => this.onChange(e)}
                                            />
                                            <div id="warehouseDetails" className="flex">
                                                <FormControl className="loginForm m-t-2">
                                                    <ProductTextField
                                                        type="number"
                                                        name='newZipcode'
                                                        value={ newZipcode }
                                                        onChange={ e => this.onChange(e)}
                                                        variant='outlined'
                                                        placeholder='Zipcode'
                                                        className={ errorNewZipcode ? "errorClass loginInput" : "loginInput"}
                                                    />
                                                </FormControl>
                                                <FormControl className="loginForm m-t-2">
                                                    <ProductTextField
                                                        type="text"
                                                        name='newCity'
                                                        value={ newCity }
                                                        onChange={ e => this.onChange(e)}
                                                        variant='outlined'
                                                        placeholder='City'
                                                        className={ errorNewCity ? "errorClass loginInput" : "loginInput"}
                                                    />
                                                </FormControl>
                                                <FormControl className="loginForm m-t-2">
                                                    <ProductTextField
                                                        type="text"
                                                        name='newState'
                                                        value={ newState }
                                                        onChange={ e => this.onChange(e)}
                                                        variant='outlined'
                                                        placeholder='State'
                                                        className={ errorNewState ? "errorClass loginInput" : "loginInput"}
                                                    />
                                                </FormControl>
                                            </div>
                                            <Button className="editPreviousOrder absolute" onClick={this.editWarehouseField}>
                                                <Cancel />
                                            </Button>
                                        </FormControl> : product.warehouse !== undefined ? Object.keys(product.warehouse).length ? <div className="inlineBlock">
                                            <p>{product.warehouse[0].address}, {product.warehouse[0].city}, {product.warehouse[0].country}, {product.warehouse[0].zipcode} <Button className="editPreviousOrder" onClick={this.editWarehouseField}><Pencil/></Button></p>
                                        </div> : <p className="inlineBlock">No warehouse specified <Button className="editPreviousOrder" onClick={this.editWarehouseField}><Pencil/></Button></p> : null
                                    }
                                </FormControl>
                                <FormControl className="loginForm m-t-2">
                                    <TextField
                                        type="submit"
                                        className="yellowSubmit"
                                        variant="outlined"
                                        value="EDIT SKU"
                                        onClick = {this.validateAndEdit}
                                    />
                                </FormControl>
                            </div>
                        }
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
}

export default EditSku;