import { isAuthenticated } from '../../auth/index';

export const getNotifications = () => {
    return fetch(`${process.env.REACT_APP_DOMAIN_BACK}/notification/${isAuthenticated().companyActually.slug}/list`,{
        method: "GET",
        headers: {
            Accept: "application/json", 
            Authorization: `Bearer ${isAuthenticated().token}`,
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err));
};


export const readNotification = (id) => {
    return fetch(`${process.env.REACT_APP_DOMAIN_BACK}/notification/${id}/read`,{
        method: "GET",
        headers: {
            Accept: "application/json", 
            Authorization: `Bearer ${isAuthenticated().token}`,
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err));
};