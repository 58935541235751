import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ToastContainer } from 'react-toastify';

import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.min.css';

import SocketContext from './socket-context';
import * as io from 'socket.io-client';

const socket = io(process.env.REACT_APP_DOMAIN_BACK, {
    secure: true,
    rejectUnauthorized: false
});

ReactDOM.render(  
    <SocketContext.Provider value={socket}>
        <div style={{height: '100%'}}>
            <Routes/>
            <ToastContainer autoClose={4000} position="top-center"/>
        </div>
    </SocketContext.Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
