import React, {Component} from 'react';
import dompurify from 'dompurify';

class TaskTables extends Component {
  

    render(){
        if(this.props.items){
            let items = Object.keys(this.props.items);
            let values = Object.values(this.props.items);

            const sanitize = dompurify.sanitize;
        
            return(
                <div  id="tableDetails">
                    {
                      
                     items.map( (item,index )=> {
                        return(
                            <div key={index} className="flex row">
                                <div className="workSans bold keys">{item}</div>
                            
                                {
                                    typeof values[index] === 'object' && values[index] !== null ?
                                    <div className="workSans values" >
                                        
                                        {
                                       
                                         values[index].map((vals,i) => {
                                                return(
                                                    <p className="m-0" key={i} dangerouslySetInnerHTML={{__html: sanitize(vals)}}></p>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <div className="workSans values" dangerouslySetInnerHTML={{__html: sanitize(values[index])}}></div>
                                }
                               
                                
                            </div>
                        )
                     })
                      
                    }
                  
                </div>
            )
        }
        else{
            return null
        }
    }
}
export default TaskTables;