import React, {Component } from 'react';
import Grid from '@material-ui/core/Grid';
import {TextField, FormControl, Button} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {toast} from 'react-toastify';
import { isAuthenticated } from '../../auth/index';
import Pencil from '@material-ui/icons/Create'
import Cancel from '@material-ui/icons/Clear';

require('dotenv').config();

const UserTextField = withStyles({
    root: {
        '& label.Mui-focused': {
			color: '#3BA3FF',
        },
        '& .MuiInput-underline:after': {
			borderBottomColor: '#3BA3FF',
        },
        '& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: '#3BA3FF',
				borderWidth: '2px',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#3BA3FF',
			},
		},
	},
  })(TextField);

class UpdateUser extends Component {
    state = {
      first_name: '',
      last_name: '',
	  email: '',
	  editName: false,
	  editLast: false,
	  editEmail: false,
	  editRole: false,
	  errorNameClass: false,
	  errorLastClass:false,
	  errorEmailClass: false,
	  errorRoleClass: false,
      role: this.props.user.role,
    }
    
    onChange = (e) => {
        this.setState({
			[e.target.name] : e.target.value,
			errorNameClass: false,
			errorLastClass:false,
			errorEmailClass: false,
			errorRoleClass: false,
		});
		
	
    }

    closeEditMode = () => {
		this.props.view();
	}
	showEditName = () => {
        this.setState( prevState => ({
            editName: !prevState.editName
        }));
	}
	showEditLast = () => {
        this.setState( prevState => ({
            editLast: !prevState.editLast
        }));
	}
	showEditEmail = () => {
        this.setState( prevState => ({
            editEmail: !prevState.editEmail
        }));
	}
	showEditRole = () => {
		this.setState(prevState => ({
				editRole: !prevState.editRole
			})
		)
	}

    sendEditUser = () => {
        const  {
			first_name,
			last_name,
			email,
			role,
			editName,
			editEmail,
			editLast,
			editRole
		} = this.state;

		let body={};
		
		const id = this.props.user._id;
		/*let c='';
		if(this.props.user.company.length){
			this.props.user.company.map((comp,index) => {
				return(
				c = c + ' '+ comp.name
				)
							
			})
		}
	
		if(this.props.user.role === 'owner' && role==='user'){
			this.setState({
				errorRoleClass : true,
				role: this.props.user.role
			});
			return toast.error('Error: This user belongs to  '+ c + ' and can not be changed');
		}
		if(this.props.user.role === 'user' && role==='owner'){
			this.setState({
				role: this.props.user.role,
				errorRoleClass : true
			});
			return toast.error('Error: The company ' +c+' already have an Owner');
		}
		*/
		if(this.props.user.role === 'administrator' || this.props.user.role === 'staff'){
			if(!editName && !editLast && !editEmail){
				return toast.error("Can't save, nothing is being edited");
			}
		}
		else{
			if(!editName && !editLast && !editEmail && !editRole){
				return toast.error("Can't save, nothing is being edited ");
			}
		}
		
		if(editName){
			if (first_name === '') {
				this.setState({
					errorNameClass : true
				});
                return toast.error('User name is required');
            }
            const nameBody = {
                first_name : first_name
            }
            body = Object.assign(body,nameBody);
		}

		if(editLast){
			if (last_name === '') {
				this.setState({
					errorLastClass : true
				});
                return toast.error('Last Name is required');
            }
            const last = {
                last_name : last_name
            }
            body = Object.assign(body,last);
		}

		if(editEmail){
			if (email === '') {
				this.setState({
					errorEmailClass : true
				});
                return toast.error('Email is required');
            }
            const mail = {
                email : email
            }
            body = Object.assign(body,mail);
		}
		
		if(editRole){
			const rol = {
                role : role
            }
            body = Object.assign(body,rol);
		}

		const comp ={
			company : this.props.user.company[0]._id
		}
		
		body = Object.assign(body, comp);
		//console.log(body," body");
		fetch(`${process.env.REACT_APP_DOMAIN_BACK}/user/${id}/update`, {
					method: 'PUT',
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${isAuthenticated().token}`,
					},
					body: JSON.stringify(body),
				})
				.then(res => res.json())
				.then(json => {
					if (json.message) {
						toast.success('User edited succesfully');
						this.props.view();
					} else {
						toast.error(json.error)
					}
				})
				.catch(err => console.log(err));
		
	}
	
	render() {
		const {
			first_name,
			last_name,
			email,
			role,
			editEmail,
			editLast,
			editName,
			editRole,
			errorLastClass,
			errorEmailClass,
			errorNameClass,
		} = this.state;
		
		return(
			<Grid container>
				<Grid item xs={8} className="m-lr-auto m-tb-2">
					<span className="cancel m-tb-2" onClick={this.closeEditMode}>CANCEL</span>
					<h3>Update User</h3>
					
					<p className="bold">Name</p>
						{
							editName ?
							<FormControl className=" editAdminUser updateUser m-tb-2">
								<UserTextField
								name="first_name"
								type="text"
								placeholder={this.props.user.first_name}
								className={ errorNameClass ? "loginInput errorClass" : "loginInput"}
								variant="outlined"
								value={first_name}
								onChange = { e => this.onChange(e)} 
								/>
								<Button className="editPreviousOrder cancel floatRight" onClick={this.showEditName}>
									<Cancel />
								</Button>
							</FormControl>
                        
						:
							<p className="editAdminUser">{this.props.user.first_name}
							<Button className="editPreviousOrder" onClick={this.showEditName}>
									<Pencil/>
							</Button>
							</p>
						}
					<p className="bold">Last Name</p>
					{
						editLast ?
						<FormControl className="editAdminUser updateUser m-tb-2">
						<UserTextField
							name="last_name"
							type="text"
							placeholder={this.props.user.last_name}
							className={ errorLastClass ? "loginInput errorClass" : "loginInput"}
							variant="outlined"
							value={last_name}
							onChange = { e => this.onChange(e)}
                        />
						<Button className="editPreviousOrder cancel floatRight" onClick={this.showEditLast}>
							<Cancel />
						</Button>
						</FormControl>
						:
						<p className="editAdminUser">{this.props.user.last_name}
						<Button className="editPreviousOrder " onClick={this.showEditLast}>
							<Pencil/>
						</Button>
						</p>

					}
					<p className="bold">Email</p>
					{
						editEmail ?
						<FormControl className="editAdminUser updateUser m-tb-2">
						<UserTextField
							name="email"
							type="email"
							className={ errorEmailClass ? "loginInput errorClass" : "loginInput"}
							placeholder={this.props.user.email}
							variant="outlined"
							value={email}
							onChange={ e => this.onChange(e)}
						/>
						<Button className="editPreviousOrder cancel floatRight" onClick={this.showEditEmail}>
							<Cancel />
						</Button>
						</FormControl>
						:
						<p className="editAdminUser">
						{this.props.user.email}	
						<Button className="editPreviousOrder " onClick={this.showEditEmail}>
							<Pencil/>
						</Button>
						</p>
					}
					<p className="bold">Role:</p>
					{
						
							this.props.user.role === 'administrator' || this.props.user.role === 'staff' ?
								<p className="account capitalize">{this.props.user.role}</p>
							:
							editRole ?
							<FormControl variant="outlined" className="loginForm  m-tb-2">
							{
						
								<Select
									name="role"
									value={role}
									variant="outlined"
									onChange={ e => this.onChange(e)}
									className="loginInput selectFormAdmin " 
								>
									<MenuItem value='owner'>Owner</MenuItem>
									<MenuItem value='user'>User</MenuItem>
								</Select>
							}
							</FormControl>

							:
							<p className="editAdminUser capitalize">{this.props.user.role}
							<Button className="editPreviousOrder " onClick={this.showEditRole}>
								<Pencil/>
							</Button>
							</p>
					}
					
					
					
					
					<FormControl className="loginForm  m-tb-2">
					<p className="bold">Company:</p>
					{
						this.props.user.role === 'administrator' ?
						<p className="account">Role Administrator doesn't have a company assigned, because this role has access to everything</p>
						:
						this.props.user.company.length ?
						this.props.user.company.map((comp,index) => {
							return(
								<p key={index} className="account">{comp.name}</p>
							)
							
						})
						:
						<p className="account">This user doesn't have a company assigned</p>
					}
					
					</FormControl>
					<p className="account" style={{'fontStyle':'italic'}}>Note: This company only has one user (role = owner). 
						Please add a separate user if you want to add a new role.</p>
					
					<FormControl className="loginForm">
						<TextField
							id="submit-login"
							type="submit"
							className="yellowSubmit"
							variant="outlined"
							value="SAVE"
							onClick={this.sendEditUser}
						/>
					</FormControl>
				</Grid>
			</Grid>
		);
	}
}

export default UpdateUser;