import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import NewTaskModal from './NewTaskModal';

class NewTask extends Component{
    constructor(props){
        super(props);
        this.state = {
            openNewTask : false,
            productOwner: ''
        }
        this.closeNewTask = this.closeNewTask.bind(this);
    }

    closeNewTask = () => {
        this.setState({
            openNewTask : false,
            productOwner: ''
        });
    }

    openNewTask = (product) => {
        this.setState({
            openNewTask: true,
            productOwner: product
        });
    }
    refreshTask = () => {
        this.props.refreshTask()
    }

    render(){
        const active = this.props.active;
        return(
            <>
                <div className="floatRight">
                    <Button  
                    variant="contained" 
                    disabled = { active ? false : true}
                    className="newButton rift" 
                    onClick= { active ? e => this.openNewTask(this.props.product) : null}    
                    >
                        <AddIcon/>NEW
                    </Button>
                </div>
                <NewTaskModal
                    open={this.state.openNewTask}
                    close={this.closeNewTask}
                    productOwner={this.state.productOwner}
                    refreshTask={this.refreshTask }
                />
            </>   
        )
    }

}
export default NewTask;