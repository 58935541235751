import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Main from '../containers/Main';
import Login from '../containers/Login';
import DashboardWithSocket from '../containers/Dashboard';
import PrivateRoute from '../auth/PrivateRoute';
import ProductView from '../containers/ProductView';
import withAuth from '../services/withAuth';

class Routes extends Component {
    render() {
        return(
            <Router>
                <Route exact path='/' component={Main}/>
                <Route exact path='/login' component={Login}/>
                <PrivateRoute exact path='/:companySlug' component={withAuth(DashboardWithSocket)}/>
                <Switch>
                    <PrivateRoute exact path='/:companySlug/:productSlug' component={withAuth(ProductView)}/>
                    <PrivateRoute exact path='/:companySlug/:productSlug/t/:taskSlug' component={withAuth(ProductView)}/>
                </Switch>
            </Router>
        )
    }
}

export default Routes;