import React, { Component } from 'react';

class ProductionIcon extends Component {
    render() {
        return(
            <svg height='40px' width='40px'  fill={this.props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" x="0px" y="0px">
                <g>
                <path d="M57,8H7a5.006,5.006,0,0,0-5,5V45a5.006,5.006,0,0,0,5,5H25.867l-.75,6H22a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H42a1,1,0,0,0,1-1V57a1,1,0,0,0-1-1H38.883l-.75-6H57a5.006,5.006,0,0,0,5-5V13A5.006,5.006,0,0,0,57,8ZM7,10H57a3,3,0,0,1,3,3V40H4V13A3,3,0,0,1,7,10ZM41,60H23V58H41Zm-4.133-4H27.133l.75-6h8.234ZM57,48H7a3,3,0,0,1-3-3V42H60v3A3,3,0,0,1,57,48Z"></path>
                <path d="M54.29,44.29A1.045,1.045,0,0,0,54,45a1,1,0,1,0,2,0,1,1,0,0,0-1.71-.71Z"></path>
                <path d="M9,28h2.584l.051.123L9.808,29.95a1,1,0,0,0,0,1.414l2.828,2.828a1,1,0,0,0,1.414,0l1.827-1.827.123.051V35a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V32.416l.123-.051,1.827,1.827a1,1,0,0,0,1.414,0l2.828-2.828a1,1,0,0,0,0-1.414l-1.827-1.827L26.416,28H29a1,1,0,0,0,1-1V23a1,1,0,0,0-1-1H26.416l-.051-.123,1.827-1.827a1,1,0,0,0,0-1.414l-2.828-2.828a1,1,0,0,0-1.414,0l-1.827,1.827L22,17.584V15a1,1,0,0,0-1-1H17a1,1,0,0,0-1,1v2.584l-.123.051L14.05,15.808a1,1,0,0,0-1.414,0L9.808,18.636a1,1,0,0,0,0,1.414l1.827,1.827L11.584,22H9a1,1,0,0,0-1,1v4A1,1,0,0,0,9,28Zm1-4h2.294a1,1,0,0,0,.958-.714,5.967,5.967,0,0,1,.469-1.139,1,1,0,0,0-.172-1.184l-1.62-1.62,1.414-1.414,1.62,1.62a1,1,0,0,0,1.184.172,5.967,5.967,0,0,1,1.139-.469A1,1,0,0,0,18,18.294V16h2v2.294a1,1,0,0,0,.714.958,5.967,5.967,0,0,1,1.139.469,1,1,0,0,0,1.184-.172l1.62-1.62,1.414,1.414-1.62,1.62a1,1,0,0,0-.172,1.184,5.967,5.967,0,0,1,.469,1.139,1,1,0,0,0,.958.714H28v2H25.706a1,1,0,0,0-.958.714,5.967,5.967,0,0,1-.469,1.139,1,1,0,0,0,.172,1.184l1.62,1.62-1.414,1.414-1.62-1.62a1,1,0,0,0-1.184-.172,5.967,5.967,0,0,1-1.139.469,1,1,0,0,0-.714.958V34H18V31.706a1,1,0,0,0-.714-.958,5.967,5.967,0,0,1-1.139-.469,1,1,0,0,0-1.184.172l-1.62,1.62-1.414-1.414,1.62-1.62a1,1,0,0,0,.172-1.184,5.967,5.967,0,0,1-.469-1.139A1,1,0,0,0,12.294,26H10Z"></path>
                <path d="M19,29a4,4,0,1,0-4-4A4,4,0,0,0,19,29Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,19,23Z"></path>
                <path d="M35.414,17H41V15H35a1,1,0,0,0-.707.293l-3,3,1.414,1.414Z"></path>
                <rect x="43" y="13" width="2" height="2"></rect>
                <rect x="47" y="13" width="4" height="2"></rect>
                <rect x="43" y="17" width="8" height="2"></rect>
                <path d="M35,33h6V31H35.414l-2.707-2.707-1.414,1.414,3,3A1,1,0,0,0,35,33Z"></path>
                <rect x="43" y="29" width="6" height="2"></rect>
                <rect x="43" y="33" width="2" height="2"></rect>
                <rect x="47" y="33" width="5" height="2"></rect>
                </g>
            </svg>
        )
    }
}

export default ProductionIcon;