import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
    Radio
} from "@material-ui/core";

export const ProductTextField = withStyles({
    root: {
        '& label.Mui-focused': {
			color: '#3BA3FF',
        },
        '& .MuiInput-underline:after': {
			borderBottomColor: '#3BA3FF',
        },
        '& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: '#3BA3FF',
				borderWidth: '2px',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#3BA3FF',
			},
		},
	},
})(TextField);

export const OrangeRadio = withStyles({
	root: {
		color: '#ff6700',
		'&$checked': {
			color: '#ff6700',
		},
	},
	checked: {},
})(props => <Radio color="default" {...props} />);