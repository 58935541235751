import { isAuthenticated } from '../../../auth/index';

export const createProduct = (product) => {
    return fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/${isAuthenticated().companyActually.slug}/create`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${isAuthenticated().token}`,
        },
        body: product,
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err));
}