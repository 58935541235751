import React, { Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Back from '@material-ui/icons/KeyboardBackspace';
import Hidden from '@material-ui/core/Hidden';
import FormStripe from '../../Stripe/FormStripe';
import {Elements, StripeProvider} from 'react-stripe-elements';

class PaymentMethods extends Component {

    render() {
        //console.log(this.props.invoiceId, 'id');

        if (this.props.step !== 2) {
            return null
        }

        return(
            <Grid container>
                <Grid item md={8} s={12} className="grayBg p-tb-2">
                    <span className="cancel p-lr-5 " onClick={this.props.backBilling}>
                        <Back />
                    </span>
                    <h3 className="p-lr-5">PAYMENT METHOD</h3>
                    <p className="p-lr-5">
                    Enter your payment card information below.
                    </p>
                    <div className="p-lr-5">

                            <StripeProvider apiKey={process.env.REACT_APP_STRIPE}>
                            <div className="example m-tb-2">
                                <Elements>
                                    <FormStripe 
                                    invoiceID={this.props.invoiceId}
                                    thankyou = {this.props.thankyou}
                                    />
                                </Elements>
                            </div>
                            </StripeProvider>     
                    </div>
                </Grid>
                <Hidden smDown>
                    <Grid item md={4} className="darkBg yellowDotsBg"></Grid>
                </Hidden>
            </Grid>
           
        )
    }
}

export default PaymentMethods;