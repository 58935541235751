export function setInStorage(key, obj) {
	if (!key) {
		console.error('Key is missing');
	}
	try {
		localStorage.setItem(key, JSON.stringify(obj));
	} catch (err) {
		console.error(err);
	}
}

export function getFromStorage(key) {
	if (!key) {
		return null;
	}
	try {
		const valueStr = localStorage.getItem(key);
		if (valueStr) {
			return JSON.parse(valueStr);
		}
		return null;
	} catch (err) {
		return null;
	}
}

export function updateStorage(key, user) {
	const token = getFromStorage('gembah_user').token;
	if (localStorage.getItem(key)) {
		let auth = JSON.parse(localStorage.getItem(key));
		auth.id = user.id;
		auth.first_name = user.first_name;
		auth.last_name = user.last_name;
		auth.email = user.email;
		auth.token = token;
		auth.role = user.role;
		auth.companies = user.companies;
		auth.companyActually = user.companyActually !== undefined ? user.companyActually : user.companies[0];
		localStorage.setItem(key, JSON.stringify(auth));
	}
}