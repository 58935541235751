import React, {Component} from  'react';
import Dialog from '@material-ui/core/Dialog';
import Cancel from '@material-ui/icons/Clear';
import PreviewFiles from '../../PreviewFiles';
class ProjectSpecs extends Component {
    
    downloadAllSpecs = () => {
        let downloadables = document.getElementsByClassName('specsFiles');
        
       
        for(let i=0; i <= downloadables.length ; i++){
            if(downloadables[i] !== undefined){
               downloadables[i].childNodes[0].click();
            }
           
        }
    }

    render() {
        const {
            ProjectIntormation,
        } = this.props;
        
        return(
            <Dialog
                open={this.props.open}
                onClose={this.props.closeModal}
                aria-labelledby="simple-dialog-title"
                className="fullDialog grayBg"
                id="full-specs"
                maxWidth='md'
            >
                <div className="p-lr-5 p-tb-2 whiteBg">
                    <span className="floatLeft" onClick={this.props.closeModal}><Cancel/></span>
                    
                    <h3 className="textCenter m-0">PRODUCT SPEC</h3>
                </div>
                <div className="p-lr-5 p-tb-5" id="product-specs">
                    <div className="sectionSpecs">
                        <h4 className="bold workSans" >Product name:</h4> 
                        <span className="workSans"> { ProjectIntormation.name }</span>
                    </div>
                    <div className="sectionSpecs">
                        <h4 className="bold workSans">Description: </h4>
                        <span className="workSans">{ ProjectIntormation.description }</span>
                    </div>
                    <div className="sectionSpecs">
                        <h4 className="bold workSans">Design Files</h4>
                        <span className="workSans">
                            {
                                ProjectIntormation.files !== undefined && ProjectIntormation.files.length > 0 ?
                                    ProjectIntormation.files.map((file, index) => {
                                        return (
                                            <PreviewFiles
                                            key={index}
                                            file={file}
                                            onComments = {false}
                                            />
                                           
                                        )
                                    })
                                : 'no files attached'
                            }
                        </span>
                    </div>
                    <div className="sectionSpecs">
                        <h4 className="bold workSans">Packaging Files</h4>
                        <span className="workSans">
                            {
                                ProjectIntormation.filePackaging !== undefined && ProjectIntormation.filePackaging.length > 0 ?
                                    ProjectIntormation.filePackaging.map((file,index) => {
                                        return(
                                            <PreviewFiles
                                            key={index}
                                            file={file}
                                            onComments = {false}
                                            />
                                        )
                                    })
                                : 'no files attached'
                            }
                        </span>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default ProjectSpecs;