import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitIcon from '@material-ui/icons/ExitToApp';
import CreditCard from '@material-ui/icons/CreditCard';
import Cancel from '@material-ui/icons/Clear';
import Pencil from '@material-ui/icons/Create'
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { getFromStorage, updateStorage } from '../../services/storage';
import { isAuthenticated, signout } from '../../auth/index';
import { toast } from 'react-toastify';
import Billing from '../Billing';
import { Link } from 'react-router-dom';

const AccountTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#3BA3FF',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#3BA3FF',
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#3BA3FF',
                borderWidth: '2px',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#3BA3FF',
            },
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '&:hover fieldset': {
                borderColor: '#cccccc',
                borderWidth: '1px',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#cccccc',
            },
        },
    },
})(TextField);

class UserButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuEl : null,
            accountModal : false,
            errorAccountClass : false,
            editFirstName: false,
            editLastName: false,
            editEmail: false,
            editPass: false,
            accountFirstName: '',
            accountLastName: '',
            accountEmail :'' ,
            accountPassword : '********',
            confirmPassword:'',
            billingModal: false
        };

        this.closeModalBilling = this.closeModalBilling.bind(this);
    };
    
    updateAccountData = () => {
        const {
            editPass,
            editFirstName,
            editLastName,
            editEmail,
            accountFirstName,
            accountLastName,
            accountEmail,
            accountPassword,
            confirmPassword
        } = this.state;
        let bodyRequest = {};
        let bodyname, bodyemail, bodypass;
        
        if (!editPass && !editEmail && !editFirstName && !editLastName) {
            toast.error('Cant perform update of not selected values, please click on a element to update' );
        } else {
            if (editFirstName && (accountFirstName === '')) {
                toast.error('Clicked on edit name but is empty');
            }
            
            if (editFirstName && (accountFirstName !== '')) {
                bodyname = { first_name : accountFirstName}
            }
            
            if (editLastName && (accountLastName === '')) {
                toast.error('Clicked on edit name but is empty');
            }
            
            if (editLastName && (accountLastName !== '')) {
                bodyname = { last_name : accountLastName}
            }
            
            if (editEmail && (accountEmail === '')) {
                toast.error('Clicked on edit email but is empty');
            }
            
            if (editEmail && (accountEmail !== '')) {
                bodyemail = { email : accountEmail}
            }
            
            if (editPass && (accountPassword === '********')) {
                toast.error('Clicked on edit password but has not being edited');
            }
            
            if (editPass && (confirmPassword === '')) {
                toast.error('Confirm password  is empty');
            }
            
            if (accountPassword !== '********') {
                if ( accountPassword !== confirmPassword) {
                    toast.error('Passwords must match');
                } else {
                    bodypass = {
                        password: accountPassword,
                        confirmPassword: confirmPassword
                    }
                }
            }
            
            bodyRequest = {
                ...bodyname,
                ...bodyemail,
                ...bodypass
            }
            
            if (Object.keys(bodyRequest).length) {
                fetch(`${process.env.REACT_APP_DOMAIN_BACK}/user/update`, {
                    method: 'PUT',
                    mode: 'cors',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${isAuthenticated().token}`,
                    },
                    body: JSON.stringify(bodyRequest),
                })
                .then(res => res.json())
                .then(json => {
                    if (json.message) {
                        toast.success(json.message);
                        updateStorage('gembah_user', json.user);
                        this.handleCloseAccount();
                        this.setState({ user: getFromStorage('gembah_user') })
                    } else {
                        toast.error(json.error)
                    }
                }).catch(err => console.log(err));
            }
        }
    }

    handleClick = event => {
        this.setState({ menuEl: event.currentTarget });
    };
    
    handleClose = () => {
        this.setState({ menuEl: null });
    };

    handleCloseAccount = () => {
        this.setState({
            accountModal : false,
            accountFirstName: '',
            accountLastName: '',
            accountEmail : '',
            accountPassword : '********',
            confirmPassword: '',
            editPass: false,
            editEmail:false,
            editFirstName: false,
            editLastName: false
        });
    }

    openModalAccount = () => {
        this.setState({
            accountModal : true,
            menuEl: null,
        });
    }
    openModalBilling = () => {
        this.setState({
            billingModal : true,
            menuEl: null,
        })
    }
    closeModalBilling = () => {
        this.setState({
            billingModal : false,
        })
    }
    onChange = (e) => {
       this.setState({
           [e.target.name] : e.target.value,
        })
    }
    
    editFirstName = () => {
        this.setState({
            editFirstName: true,
        });
    }

    editLastName = () => {
        this.setState({
            editLastName: true,
        });
    }
    
    editEmail= () => {
        this.setState({
            editEmail : true,
        });
    }
    
    editPass= () => {
        this.setState({
            editPass : true,
        });
    }
    
    avatarName = () => {
        if (isAuthenticated()) {
            return `${isAuthenticated().first_name.charAt(0)}${isAuthenticated().last_name.charAt(0)}`;
        }
    }
    
    render() {
        const {
            menuEl,
            errorAccountClass,
            accountModal,
            editEmail,
            editFirstName,
            editLastName,
            editPass,
            accountPassword,
            accountEmail,
            accountFirstName,
            accountLastName,
            confirmPassword,
        } = this.state;
        return(
            <div id="userMenuButton">
                <Button
                    aria-owns={menuEl ? 'user-menu' : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                >
                    <Avatar className="userAvatar workSans">
                        {this.avatarName()}
                    </Avatar>
                </Button>
                <Hidden xsDown>
                    { /* modal for user*/}
                    <Menu
                        id="user-menu"
                        keepMounted
                        anchorEl={menuEl}
                        open={Boolean(menuEl)}
                        onClose={this.handleClose}
                    >
                        <div className="darkBg textCenter p-tb-5 userMenuDiv">
                            <Avatar className="m-lr-auto userAvatar workSans">
                                {this.avatarName()}
                            </Avatar>
                            <h3 className="whiteText">{isAuthenticated().first_name}</h3>
                            <p className="whiteText">{isAuthenticated().companyActually.name}</p>
                        </div>
                        {
                            isAuthenticated().role === 'administrator' || isAuthenticated().role === 'staff' ? <Link to="/" style={{'textDecoration': 'none', 'color':'#000000'}}>
                                <MenuItem className="workSans"><SettingsIcon className="cancel p-lr-5"/>Admin</MenuItem>
                            </Link> : null
                        }
                        <MenuItem className="workSans" onClick={this.openModalAccount}><SettingsIcon className="cancel p-lr-5"/>Account</MenuItem>
                        {
                            isAuthenticated().role !== 'user' ? <MenuItem className="workSans" onClick={this.openModalBilling}><CreditCard className="cancel p-lr-5"/>Billing</MenuItem> : null
                        }
                        <MenuItem className="workSans" onClick={() => signout(() => this.props.logout() )}><ExitIcon className="cancel p-lr-5"/>Logout</MenuItem>
                    </Menu>
                    { /*ends  modal for user*/}
                </Hidden>
                <Hidden smUp>
                    { /*starts responsive for mobile user modal*/ }
                    <Dialog
                        open={Boolean(menuEl)}
                        onClose={this.handleClose}
                        aria-labelledby="simple-dialog-title"
                        id="full-user"
                        fullScreen
                    >
                        <div className="darkBg textCenter p-tb-5 userMenuDiv">
                            <Button onClick={this.handleClose} className="cancelUserPopup"><Cancel/></Button>
                            <Avatar className="m-lr-auto userAvatar workSans">
                                {this.avatarName()}
                            </Avatar>
                            <h3 className="whiteText">{isAuthenticated().first_name}</h3>
                            <p className="whiteText">{isAuthenticated().companyActually.name}</p>
                        </div>
                        {
                            isAuthenticated().role === 'administrator' || isAuthenticated().role === 'staff' ? <Link to="/" style={{'textDecoration': 'none', 'color':'#000000'}}>
                            <MenuItem className="workSans"><SettingsIcon className="cancel p-lr-5"/>Admin</MenuItem>
                        </Link> : null
                        }
                        <MenuItem className="workSans" onClick={this.openModalAccount}><SettingsIcon className="cancel p-lr-5"/>Account</MenuItem>
                        {
                            isAuthenticated().role !== 'user' ? <MenuItem className="workSans" onClick={this.openModalBilling}><CreditCard className="cancel p-lr-5"/>Billing</MenuItem> : null
                        }
                        <MenuItem className="workSans" onClick={() => signout(() => this.props.logout() )}><ExitIcon className="cancel p-lr-5"/>Logout</MenuItem>
                    </Dialog>
                    {/* end responsive for mobile user modal */ }
                </Hidden>
                
                { /*starts account modal */}
                <Dialog
                    open={accountModal}
                    onClose={this.handleCloseAccount}
                    aria-labelledby="simple-dialog-title"
                    className="fullDialog grayBg"
                    maxWidth='md'
                    id="accountModal"
                >
                    <Grid container>
                        <Grid item md={8} s={12} className="grayBg p-tb-2">
                            <span className="cancel p-lr-5  " onClick={this.handleCloseAccount}><Cancel /></span>
                            <h3 className="p-lr-5">ACCOUNT</h3>
                            <p className="p-lr-5">You can edit and update your personal details below.</p>
                            <p className="p-lr-5">Company</p>
                            <p className="m-lr-5" style={{'border': '1px solid #ccc', 
                                                        'color': '#d2d1d1',
                                                        'padding': '15px'}}> 
                            {isAuthenticated().companyActually.name}
                            </p>
                            <FormControl className="loginForm m-tb-2 p-lr-5">
                            <p>First Name</p>
                            <AccountTextField
                                placeholder={isAuthenticated().first_name}
                                disabled={editFirstName ? false : true }
                                type="text"
                                className={errorAccountClass ? "loginInput errorClass" : "loginInput"}
                                variant="outlined"
                                value={ accountFirstName }
                                name="accountFirstName"
                                onChange={e => this.onChange(e)}
                                InputProps={{
                                    endAdornment: <InputAdornment
                                        className="cursorPointer"
                                        onClick={ this.editFirstName} 
                                        position="end"
                                    >
                                        <Pencil/>
                                    </InputAdornment>
                                }}
                            />
                            </FormControl>
                            <FormControl className="loginForm m-tb-2 p-lr-5">
                                <p>Last Name</p>
                                <AccountTextField
                                    placeholder={isAuthenticated().last_name}
                                    disabled={editLastName ? false : true}
                                    type="text"
                                    className={errorAccountClass ? "loginInput errorClass" : "loginInput"}
                                    variant="outlined"
                                    value={accountLastName}
                                    name="accountLastName"
                                    onChange={e => this.onChange(e)}
                                    InputProps={{
                                        endAdornment: <InputAdornment
                                            className="cursorPointer"
                                            onClick={this.editLastName} 
                                            position="end"
                                        >
                                            <Pencil/>
                                        </InputAdornment>
                                    }}
                                />
                            </FormControl>
                            <FormControl className="loginForm m-tb-2 p-lr-5">
                                <p>Email</p>
                                <AccountTextField
                                    disabled={editEmail ? false : true }
                                    placeholder={isAuthenticated().email}
                                    type="email"
                                    className={ errorAccountClass ? "loginInput errorClass" : "loginInput"}
                                    variant="outlined"
                                    value={accountEmail}
                                    name="accountEmail"
                                    onChange={e => this.onChange(e)}
                                    InputProps={{
                                        endAdornment: <InputAdornment
                                            className="cursorPointer"
                                            onClick={this.editEmail}
                                            position="end"
                                        >
                                            <Pencil/>
                                        </InputAdornment>
                                    }}
                                />
                            </FormControl>
                            <FormControl className="loginForm m-tb-2 p-lr-5">
                                <p>Password</p>
                                <AccountTextField
                                    disabled={editPass ? false : true }
                                    type="password"
                                    className={ errorAccountClass ? "loginInput errorClass" : "loginInput"}
                                    variant="outlined"
                                    value={ accountPassword}
                                    name="accountPassword"
                                    onChange={e => this.onChange(e)}
                                    InputProps={{
                                        endAdornment: <InputAdornment
                                            className="cursorPointer"
                                            onClick={this.editPass}
                                            position="end"
                                        >
                                            <Pencil/>
                                            </InputAdornment>,
                                        }}
                                />
                            </FormControl>
                            {
                                editPass ? <FormControl className="loginForm m-tb-2 p-lr-5">
                                    <p>Confirm Password</p>
                                    <AccountTextField
                                        type="password"
                                        className={errorAccountClass ? "loginInput errorClass" : "loginInput"}
                                        variant="outlined"
                                        value={ confirmPassword}
                                        name="confirmPassword"
                                        onChange={e => this.onChange(e)}
                                    />
                                </FormControl> : ''
                            }
                            
                            <FormControl className="loginForm m-tb-2 p-lr-5">
                                <TextField
                                    id="password account"
                                    type="submit"
                                    className="yellowSubmit"
                                    variant="outlined"
                                    value="UPDATE"
                                    onClick={this.updateAccountData}
                                />
                            </FormControl>
                        </Grid>
                        <Hidden smDown>
                            <Grid item md={4} className="darkBg yellowDotsBg"></Grid>
                        </Hidden>
                    </Grid>
                </Dialog>
                { /*ends account modal */}
                <Billing billingModal={this.state.billingModal} closeBilling={this.closeModalBilling}/>
            </div>
        )
    }
}

export default UserButton;