import React,{Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Header from '../../components/Header';
import DotsMenu from '../../components/3DotsMenu';
import TasksSidebar from '../../components/TasksSidebar';
import ProductCycle from '../../components/ProductCycle';
import NewTask from '../../components/NewTask';
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import { isAuthenticated } from '../../auth/index';
import Back from '@material-ui/icons/KeyboardBackspace';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SunImage from '../../assets/img/sun.png';
import ClockImage from '../../assets/img/clock.png';
import TaskDetails from '../../components/TaskDetails';
import { toast } from 'react-toastify';
import SocketContext from '../../socket-context';
import { getFromStorage, updateStorage } from '../../services/storage';
import Details from '../../components/TaskDetails/Details';
import Footer from '../../components/Footer';

class ProductView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect : false,
            productData : '',
            allProductTasks: {},
            tasksDesign:{},
            tasksPre: {},
            tasksProd:{},
            tasksShipping :{},
            isLoading: true,
            goBackDashboard: false,
            modal: false,
            reloadInfo: false
        }
    }
    
    componentDidMount() {
        const companySlug = this.props.match.params.companySlug;
        const productSlug = this.props.match.params.productSlug;
        this.getProductData(companySlug, productSlug);
        document.body.style.overflowY= "overlay";
    };
    
    componentWillReceiveProps(nextProps) {
        const companySlug = nextProps.match.params.companySlug;
        const productSlug = nextProps.match.params.productSlug;
        this.getProductData(companySlug, productSlug);
        this.setState({
            isLoading: true 
        })
    }

    componentWillUnmount() {
        document.body.style.overflowY= "";
    }

    updateProductData = (data) => {
        this.getProductData(data.company, data.product);
    }

    getProductData = (companySlug, productSlug) => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/${companySlug}/${productSlug}`,{
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            }
        })
        .then(res => res.json())
        .then( json => {
            if (json.error) {
                toast.error(json.error);
                return this.setState({
                    redirect: true
                });    
            }
            const st = getFromStorage('gembah_user');
            const newCompany = st.companies.filter(company => company.slug === companySlug )[0];
            const newSt = {
                ...st,
                companyActually: newCompany
            }
            updateStorage('gembah_user', newSt);

            this.setState({
                productData: json,
                isLoading: false
            });
            this.getProductTasks(productSlug);
        }).catch(err => console.log(err))
    }

    getProductTasks = (productSlug) => {
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/task/${productSlug}/tasks`,{
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            }
        })
        .then(res => res.json())
        .then(json => {
            const tasksDesign = json.filter(design => design.status === "DESIGN");
            const tasksPre = json.filter(pre => pre.status === "PRE-PRODUCTION");
            const tasksProd = json.filter(prod => prod.status === "PRODUCTION");
            const tasksShipping = json.filter(shipping => shipping.status === "SHIPPING");
            this.setState({
                allProductTasks: json,
                tasksDesign: tasksDesign,
                tasksPre: tasksPre,
                tasksProd: tasksProd,
                tasksShipping: tasksShipping
            });
        }).catch(err => console.log(err))
    }

    refreshTask = () => {
        const companySlug = this.props.match.params.companySlug;
        const productSlug = this.props.match.params.productSlug;
        this.getProductData(companySlug, productSlug);
        this.setState({
            reloadInfo: true
        });
    }

    refreshTasksInfo = (data) => {
        this.setState(data);
    }
    
    toggleClass(status)  {
        switch (status) {
            case "DESIGN" : return 'statusDesign';
            case "PRE-PRODUCTION" : return 'statusPre';
            case "PRODUCTION" : return 'statusProd';
            case "SHIPPING" : return 'statusShipping';
            case "FULFILLED" : return 'statusFulfilled';
            default : return 'statusDesign';
        }
    };

    goBackDashboard = () => {
        this.setState({
            goBackDashboard: true
        })
    }

    render(){
        const {
            redirect,
            productData,
            allProductTasks,
            tasksDesign,
            tasksPre,
            tasksProd,
            tasksShipping,
            isLoading,
        } = this.state;

        if (redirect) {
            return <Redirect to={`/${isAuthenticated().companyActually.slug}`}/>;
        }
        const { state = {} } = this.props.location;
        let { modal } = state;
        const taskSlug = this.props.match.params.taskSlug;
        if (taskSlug !== undefined && modal === undefined) {
            modal = true
        }
       
        return(
            <Grid container>
                <Header />
                <Grid item xs={12} sm={5} md={4} className="whiteBg tasksDiv">
                    <TasksSidebar
                        reloadInfo={ this.state.reloadInfo }
                    />
                </Grid>
                {
                    !isLoading ? <Grid item xs={12} sm={7} md={8} id="productsDiv"className="m-lr-auto p-b-5">
                        <div className="m-tb-2 relative p-lr-5 ">
                            <div>
                                <Link to={`/${isAuthenticated().companyActually.slug}`} id="goBackDashboard" className="account flex">
                                    <Back/>
                                    <p>Products Dashboard</p>
                                </Link>
                            </div>
                            <h4 className="account workSans">{isAuthenticated().companyActually.name}</h4>
                            <h2 className="inlineBlock" >{productData.name}</h2>
                            <span className="floatRight">
                                <DotsMenu
                                    refresh={ this.updateProductData }
                                    product={productData}
                                />
                            </span>
                        </div>
                        <ProductCycle status={productData.status}/>
                        <div id="tasksOnProduct" className="m-lr-5">
                            <div className="p-lr-2 p-t-2 p-b-5" id="taskProductHeader">
                                <span id="taskIcon">
                                    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 7H0H1ZM1 23H2H1ZM9.53513 3V4H10.1119L10.4007 3.50073L9.53513 3ZM5 7H4V8H5V7ZM21 7V8H22V7H21ZM16.4649 3L15.5993 3.50073L15.8881 4H16.4649V3ZM5 7V6H4V7H5ZM21 7H22V6H21V7ZM21 19L21.7071 19.7071L22 19.4142V19H21ZM13 27V28H13.4142L13.7071 27.7071L13 27ZM5 27H4V28H5V27ZM12.5 18.5V17.5H11.5V18.5H12.5ZM21 18.5H22V17.5H21V18.5ZM21 19.5V20.5H22V19.5H21ZM13.5 19.5V18.5H12.5V19.5H13.5ZM13.5 27V28H14.5V27H13.5ZM12.5 27H11.5V28H12.5V27ZM16.2727 4H21V2H16.2727V4ZM24 7V23H26V7H24ZM21 26H5V28H21V26ZM2 23V7H0V23H2ZM5 4H9.72727V2H5V4ZM2 7C2 5.34315 3.34315 4 5 4V2C2.23858 2 0 4.23858 0 7H2ZM5 26C3.34315 26 2 24.6569 2 23H0C0 25.7614 2.23858 28 5 28V26ZM24 23C24 24.6569 22.6569 26 21 26V28C23.7614 28 26 25.7614 26 23H24ZM21 4C22.6569 4 24 5.34315 24 7H26C26 4.23858 23.7614 2 21 2V4ZM9 4H9.53513V2H9V4ZM6 7C6 5.34315 7.34315 4 9 4V2C6.23858 2 4 4.23858 4 7H6ZM21 6H5V8H21V6ZM17 4C18.6569 4 20 5.34315 20 7H22C22 4.23858 19.7614 2 17 2V4ZM16.4649 4H17V2H16.4649V4ZM17.3305 2.49927C16.4675 1.00738 14.852 0 13 0V2C14.1092 2 15.079 2.60141 15.5993 3.50073L17.3305 2.49927ZM13 0C11.148 0 9.53254 1.00738 8.66952 2.49927L10.4007 3.50073C10.921 2.60141 11.8908 2 13 2V0ZM5 8H21V6H5V8ZM20 7V19H22V7H20ZM20.2929 18.2929L12.2929 26.2929L13.7071 27.7071L21.7071 19.7071L20.2929 18.2929ZM13 26H5V28H13V26ZM6 27V7H4V27H6ZM13 17.5H12.5V19.5H13V17.5ZM21 17.5H13V19.5H21V17.5ZM22 19.5V18.5H20V19.5H22ZM13.5 20.5H21V18.5H13.5V20.5ZM14.5 27V19.5H12.5V27H14.5ZM12.5 28H13.5V26H12.5V28ZM11.5 19V27H13.5V19H11.5ZM11.5 18.5V19H13.5V18.5H11.5Z" fill="#9998AF"/>
                                    </svg>
                                </span>
                                <p className="inlineBlock m-0 p-lr-2">Tasks</p>
                                {
                                    productData.status !== 'FULFILLED' && (isAuthenticated().role === 'staff' || isAuthenticated().role === 'administrator') ? <NewTask product={productData._id} active={productData.isActive} refreshTask={ this.refreshTask }/> : null
                                }
                            </div>
                            {
                                productData.isActive ? Object.keys(allProductTasks).length ? <div>
                                    <ExpansionPanel className={!Object.keys(tasksDesign).length || productData.status !== "DESIGN" ? 'disabledBg' : ''} >
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <p className="bold">Design</p>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className="contentDropdownTasks">
                                            {
                                                Object.keys(tasksDesign).length ? tasksDesign.map((designTasks,index) => (
                                                    <TaskDetails
                                                        onProduct={true}
                                                        key={designTasks._id}
                                                        task={designTasks}
                                                    />
                                                )) : <p className="account">There are no tasks assigned to this status</p>
                                            }
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel className={!Object.keys(tasksPre).length || productData.status !== "PRE-PRODUCTION" ? 'disabledBg' : ''}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <p className="bold">Pre-Production</p>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className="contentDropdownTasks">
                                            {
                                                Object.keys(tasksPre).length ? tasksPre.map((pre,index) => (
                                                    <TaskDetails
                                                        onProduct={true}
                                                        key={pre._id}
                                                        task={pre}
                                                    />
                                                )) : <p className="account">There are no tasks assigned to this status</p>
                                            }
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel className={!Object.keys(tasksProd).length || productData.status !== "PRODUCTION" ? 'disabledBg' : ''}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <p className="bold">Production</p>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className="contentDropdownTasks">
                                            {
                                                Object.keys(tasksProd).length ? tasksProd.map((prod,index) => (
                                                    <TaskDetails
                                                        onProduct={true}
                                                        key={prod._id}
                                                        task={prod}
                                                    />
                                                )) : <p className="account">There are no tasks assigned to this status</p>
                                            }
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel className={!Object.keys(tasksShipping).length || productData.status !== "SHIPPING" ? 'disabledBg' : ''}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <p className="bold">Shipping</p>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className="contentDropdownTasks">
                                            {
                                                Object.keys(tasksShipping).length ? tasksShipping.map((ship,index) => (
                                                    <TaskDetails
                                                        onProduct={true}
                                                        key={ship._id}
                                                        task={ship}
                                                    />
                                                )) : <p className="account">There are no tasks assigned to this status</p>
                                            }
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div> : <div className="p-lr-5 p-tb-5 textCenter">
                                    <img src={SunImage} alt="no tasks"/>
                                    <p className="account">There are no current open tasks in this project</p>
                                </div> : <div className="p-lr-5 p-tb-5 textCenter">
                                    <img src={ClockImage} alt="no tasks"/>
                                    <p className="account">We are reviewing your product specs and getting everything ready. Our turnaround time is approximately <span className="cancel">6</span> hours after you first submit your product details.</p>
                                    <p className="account">If you have questions email us at <a style={{'color':'#3BA3FF'}} href="mailto:support@gembah.com">Support</a></p>
                                </div>
                            }
                        </div>
                        {
                            modal && <Details
                                {...this.props}
                                refreshTasksInfo={ this.refreshTasksInfo }
                            />
                        }
                    </Grid> : <div style={{width: '100px',margin : 'auto'}}><CircularProgress/></div>
                }
                <Footer />
            </Grid>
        )
    }
}

const ProductViewWithSocket = props => (
    <SocketContext.Consumer>
        {socket => <ProductView {...props} socket={socket} />}
    </SocketContext.Consumer>
)

export default ProductViewWithSocket;