import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {TextField, FormControl} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {toast} from 'react-toastify';
import { isAuthenticated } from '../../auth/index';
require('dotenv').config();

const UserTextField = withStyles({
    root: {
		'& label.Mui-focused': {
			color: '#3BA3FF',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#3BA3FF',
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: '#3BA3FF',
				borderWidth: '2px',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#3BA3FF',
			},
		},
	},
})(TextField);

class RegisterUser extends Component {
	state = {
		first_name: '',
		last_name: '',
		role: 'user',
		email : '',
		company : '',
		companies:[],
		rolesAdmin: [
			{
				name: 'Administrator',
				value: 'administrator'
			},
			{
				name: 'Staff',
				value: 'staff'
			},
			{
				name: 'Owner',
				value: 'owner'
			},
			{
				name: 'User',
				value: 'user'
			},
		],
		rolesStaff: [
			{
				name: 'Owner',
				value: 'owner'
			},
			{
				name: 'User',
				value: 'user'
			},
		]
	}

	componentDidMount(){
		this.getCompanies();
	}
	
	onChange = (e) => {
		if (e.target.name === 'role') {
			this.setState({
				company: ''
			});	
		}
		this.setState({
			[e.target.name] : e.target.value,
		});
	}
	
	addUser = () => {
		const  {
			first_name,
			last_name,
			role,
			email,
			company
		} = this.state;
		
		if (!company && role === 'user') {
			return toast.error("All fields are required");
		}

		if (!company && role === 'owner') {
			return toast.error("All fields are required");
		}

		if (!email || !first_name || !last_name || !role) {
			toast.error("All fields are required");
		} else {
			var onlyLetters = /^([a-zA-Z]+[\s]*)+$/i.test(first_name);
			if (!onlyLetters) {
				toast.error("Name field must contain just letters");
			} else {
				fetch(`${process.env.REACT_APP_DOMAIN_BACK}/user/send-invitation`, {
					method: 'POST',
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${isAuthenticated().token}`,
					},
					body: JSON.stringify({
						first_name,
						last_name,
						email,
						role,
						company
					}),
				})
				.then(res => res.json())
				.then(json => {
					if (json.error) {
						return toast.error(json.error);
					}
					this.setState({
						name: '',
						email : '',
						role: ''
					});
					toast.success(json.message);
					this.props.view();
				})
				.catch(err => console.log(err));
			}
		}
	}
	
	getCompanies = () => {
		fetch(`${process.env.REACT_APP_DOMAIN_BACK}/admin/company/list`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
        })
        .then(res => res.json())
        .then(json => {
			if(json.error){
				return toast.error(json.error);
			}
           this.setState({
               companies : json,
           });
        })
        .catch(err => {
            toast.error(err);
        });
	}

	render() {
		const {
			role,
			companies,
			company,
			rolesAdmin,
			rolesStaff
		} = this.state;
		
		return(
			<Grid container>
				<Grid item xs={8} className="m-lr-auto m-tb-2">
					<h3 className="p-lr-2">Register new user</h3>
					<FormControl className="loginForm m-tb-2">
						<UserTextField
							name="first_name"
							type="text"
							className="loginInput"
							variant="outlined"
							placeholder="First Name"
							onChange={ e => this.onChange(e)}
						/>
					</FormControl>
					<FormControl className="loginForm m-tb-2">
						<UserTextField
							name="last_name"
							type="text"
							className="loginInput"
							variant="outlined"
							placeholder="Last Name"
							onChange={ e => this.onChange(e)}
						/>
					</FormControl>
					<FormControl className="loginForm m-tb-2">
						<UserTextField
							name="email"
							type="email"
							className="loginInput"
							variant="outlined"
							placeholder="Email"
							onChange={ e => this.onChange(e)}
						/>
					</FormControl>
					<FormControl variant="outlined" className="loginForm  m-tb-2">
						<p>Role:</p>
						<Select
                            name="role"
                            value={role}
                            variant="outlined"
							onChange={ e => this.onChange(e)}
							className="loginInput selectForm"
						>
							{
								isAuthenticated().role === 'administrator' ? rolesAdmin.map((rol, index) => (
									<MenuItem key={index} value={rol.value}>{rol.name}</MenuItem>
								)) : rolesStaff.map((rol, index) => (
									<MenuItem key={index} value={rol.value}>{rol.name}</MenuItem>
								))
							}
						</Select>
					</FormControl>
					{
						Object.keys(companies).length ?
						<FormControl variant="outlined" className="loginForm  m-tb-2">
						<p>Company:</p>
						<Select
                            name="company"
                            value={company}
                            variant="outlined"
							onChange={ e => this.onChange(e)}
							className="loginInput selectForm"
						>
							{
								companies.map((comp,index) => {
									return(
									<MenuItem key={index} value={comp._id}>{comp.name}</MenuItem>
									)	
								})
							}
						</Select>
						</FormControl>
					:
					<p className="account">There are no companies created, please create one first</p>

					}
					
					<FormControl className="loginForm">
						<TextField
							id="submit-login"
							type="submit"
							className="yellowSubmit"
							variant="outlined"
							value="ADD"
							onClick={this.addUser}
						/>
					</FormControl>
				</Grid>
			</Grid>
		);
	}
}

export default RegisterUser;