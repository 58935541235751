import React,{Component} from 'react';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement,
	injectStripe
} from 'react-stripe-elements';
import {
	FormControl,
	TextField,
	CircularProgress
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { isAuthenticated } from '../../../auth/index';

const createOptions = () => {
	return {
		style: {
			base: {
				fontSize: '16px',
				color: '#424770',
				letterSpacing: '0.025em',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#c23d4b',
			},
		},
	};
};


class FormStripe extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMessage: '',
			processPayment: false
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	handleSubmit = (ev) => {
		
		let errors = document.getElementsByClassName('StripeElement--complete');
		ev.preventDefault();
		
		if(errors.length >= 3){
			
			//console.log('pay intent');
			if (this.props.stripe) {
			this.props.stripe.createPaymentMethod('card').then((payload) => {
				const bod = {
					company_id: isAuthenticated().companyActually._id,
					invoice: this.props.invoiceID,
					payload
				}
				this.setState({
					processPayment: true
				});
				fetch(`${process.env.REACT_APP_DOMAIN_BACK}/billing/pay`, {
					method: 'POST',
					mode: 'cors',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${isAuthenticated().token}`,
					},
					body: JSON.stringify(bod)
				})
				.then(res => res.json())
				.then(json => {
					
					if (json.error) {
						return toast.error(json.error)
					}
					this.setState({
						processPayment: false
					});
					//toast.success(json.message)
					setTimeout(this.props.thankyou(),2500);
				})
			})
			.catch(err => console.log(err));
		} else {
			console.log("Stripe.js hasn't loaded yet.");
		}
	    }
	    else{
		   this.setState({
			   errorMessage : 'Please verify data given, all fields are required'
		   });
	    }
	};
	
	handleChange = (e) => {
		
		if (e.error) {
			this.setState({
				errorMessage: e.error.message
			});
		} else {
			this.setState({
				errorMessage: ''
			});
		}
	};
	
	render() {
		const { processPayment} = this.state;
		if(processPayment){
			return <div style={{width: '100px',margin : 'auto'}}><CircularProgress/></div>
		}
		
		return (
			
			<form onSubmit={(e) => this.handleSubmit(e)} id="main-form">
				<div className="stripeCheckout">
					<label className="workSans">
						Card number
						<CardNumberElement
							{...createOptions()}
							onChange={(e) => this.handleChange(e)}
						
						/>
					</label>
				</div>
				<div className="split-form stripeCheckout">
					<label className="workSans">
						CVC
						<CardCVCElement
							{...createOptions()}
							onChange={(e) => this.handleChange(e)}
						
						/>
					</label>
					<label className="workSans">
						Expiration date
						<CardExpiryElement
							{...createOptions()}
							onChange={(e) => this.handleChange(e)}
						
						/>
					</label>
				</div>
				<div className="error workSans" role="alert">
					{this.state.errorMessage}
				</div>
				<FormControl className="loginForm m-tb-2 ">
					<TextField
						type="submit"
						className="yellowSubmit"
						variant="outlined"
						value="CONTINUE"
					/>
				</FormControl>
			</form>
		);
	}
}


export default injectStripe(FormStripe);
