import React,{ Component} from 'react';
import Cancel from '@material-ui/icons/Clear';
import Attach from '@material-ui/icons/AttachFile';

class Lightbox extends Component {

    render(){
        const file = this.props.file;
        return(
            <div className="previewFilesBg">
                <p onClick={this.props.close} className="floatRight whiteText m-lr-2 cursorPointer">
                    <Cancel/>
                </p>
                <div className="previewFileContainer"> 
                    <img src={file.path} alt="" className="imagePreview"/>
                    <div className="textCenter">
                    <a className="p-lr-2 workSans whiteText" href={file.path} >
                        <Attach/>
                        {file.name}
                    </a> 
                    </div>
                </div>
                
            </div>
        )
    }

}
export default Lightbox;
