import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid'
import Cancel from '@material-ui/icons/Clear';
import Success from '../../assets/img/success.png'

class SuccessStartMaking extends Component {
    closeThis = () => {
        this.props.closeThis({
            startMakingModal: false,
            start: false,
            completeProcess: false,
        })
    }
    render() {
      //  console.log(this.props.productLink,this.props.productName );
        return(
            <Grid container>
                <Grid item sm={12} className="grayBg p-lr-5 p-tb-2 textCenter">
                    <span className="cancel floatLeft" onClick={this.closeThis} ><Cancel/></span>
                    <div className="m-tb-5">
                        <h3 className="m-tb-2 ">THANK YOU!</h3>
                        <img alt='success' src={Success } className="m-tb-5"/>
                        <p className="p-lr-5">
                        The form was submitted successfully and is awaiting review
                        </p>
                        <p>
                        The next step is on us! Gembah will review the details today and will reach out
                        to you in the next 3 hours to get manufacturing moving along.
                        </p>
                        {
                            this.props.isFind ? null : <p className="p-lr-5">Here is your product link : <a href={this.props.productLink} style={{'color':'#3BA3FF'}}>{this.props.productName}</a></p>
                        }
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default SuccessStartMaking;