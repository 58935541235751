import React , {Component} from 'react';
import Button from '@material-ui/core/Button';
import {
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    FormControlLabel,
    Checkbox,
    FormControl,
    Select,
    Dialog,
    TextField,
    Hidden,
    MenuItem
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { isAuthenticated } from '../../auth/index';
import { toast } from 'react-toastify';
import { getCategories } from '../NewProduct/Product/functions';

const OrangeCheckbox = withStyles({
    root: {
        color: '#f9612e',
        '&$checked': {
            color: '#f9612e',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

class StatusFilter extends Component {
  
    constructor(props) {
        super();
        this.state = {
            openFilter: null,
            openFullFilter: false,
            design: false,
            preprod: false,
            prod: false,
            shipping:false,
            fulfilled: false,
            select: 'created',
            filterArray: [],
            categories:[],
            cat:'',
            filterWasApplied: false,
            filterApplied : [],
            sortSelected :''
        };

        this.closeFilter = this.closeFilter.bind(this);
    }
    componentDidMount(){
        this.getCategories();
    }

    openFilterClick = event => {
        this.setState({ openFilter: event.currentTarget });    
    };
    
    closeFilter = (e) => {
        const target = e.target.getAttribute('name');
        const {filterApplied, sortSelected }= this.state
        this.setState({
            filterWasApplied : false
        })
      
        if( (target !== 'optionsort') && (target !== 'optioncategory')){
        this.setState({ 
            openFilter: null,
           
        });

        if(this.state.filterWasApplied === false){
            this.setState({
                design: false,
                preprod: false,
                prod: false,
                shipping:false,
                fulfilled: false,
                select: sortSelected
            });
         
            filterApplied.forEach( applied => {
                if( applied === 'DESIGN'){
                    this.setState({ design: true });
                }
                
                if( applied === 'PRE-PRODUCTION'){
                     this.setState({ preprod: true });
                }


                if( applied === 'PRODUCTION'){
                    this.setState({ prod : true});
                }


                if( applied === 'SHIPPING'){
                    this.setState({ shipping : true});
                }
                
                if( applied === 'FULFILLED'){
                 this.setState({ fulfilled : true});
                }
                
        })

        if(!this.state.filterWasApplied && (filterApplied.length === 0)){
            this.setState({
                design: false,
                preprod: false,
                prod: false,
                shipping:false,
                fulfilled: false,
                select: 'created',
                filterArray: [],
                cat:''
            })
        }
       
        }
    }
    };

    closeFilterOnSort = () => { 
        this.setState({ openFilter: null });
           
    };

    openFullFilterClick = () => {
        this.setState({
            openFullFilter : true,
        })
        
    };
    
    handleCloseFullFilter = (e) => {
        const { sortSelected} = this.state;
    
        if(e.target.id === 'closefilterFull'){

            this.setState({
                filterWasApplied : false
            });

            if(this.state.filterWasApplied === false){
                this.setState({
                    openFullFilter : false,
                    design: false,
                    preprod: false,
                    prod: false,
                    shipping:false,
                    fulfilled: false,
                    select: sortSelected
                });
                const filterApplied = this.state.filterApplied;
        
                filterApplied.forEach( applied => {
                if( applied === 'DESIGN'){
                    this.setState({ design: true });
                }
                
                if( applied === 'PRE-PRODUCTION'){
                     this.setState({ preprod: true });
                }


                if( applied === 'PRODUCTION'){
                    this.setState({ prod : true});
                }


                if( applied === 'SHIPPING'){
                    this.setState({ shipping : true});
                }
                
                if( applied === 'FULFILLED'){
                 this.setState({ fulfilled : true});
                }
                
                })
            }
        }
       
    };
   
    handleCheck = async (e) => {
        await this.setState({
            [e.target.value]: e.target.checked
        });
        const newfilterArray = [];
        if (this.state.design) {
            newfilterArray.push('DESIGN')
        }
        if (this.state.preprod) {
            newfilterArray.push('PRE-PRODUCTION')
        }
        if (this.state.prod) {
            newfilterArray.push('PRODUCTION')
        }
        if (this.state.shipping) {
            newfilterArray.push('SHIPPING')
        }
        if (this.state.fulfilled) {
            newfilterArray.push('FULFILLED')
        }
        this.setState({
            filterArray: newfilterArray
        });
    };

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    sortProducts = (e) => {

        this.setState({
            openFullFilter : false,
        })
        
        const d = this.state.select;
        let sort;
        if (d === 'created') {
            sort = { created: -1 };
        } 
        if(d === 'name') {
            sort = { name: 1 };
        }
        if(d === 'namedesc') {
            sort = { name: -1 };
        }
        
        let checkSubcategory = this.state.cat.split('-');
		let SearchCategory = {};
		
		checkSubcategory.length > 1 ? SearchCategory = {
			id: checkSubcategory[0],
			subcategory: checkSubcategory[1]
		} : SearchCategory = {
			id: checkSubcategory[0],
        };
        
       
        fetch(`${process.env.REACT_APP_DOMAIN_BACK}/product/${isAuthenticated().companyActually.slug}/sort`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${isAuthenticated().token}`,
            },
            body: JSON.stringify({
                status: this.state.filterArray,
                sortby: sort,
                category: SearchCategory
            }),
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                this.setState({
                    design: false,
                    preprod: false,
                    prod: false,
                    shipping:false,
                    fulfilled: false,
                    select: 'created',
                    filterArray: [],
                    cat:'',
                });
                return toast.warning(json.error ) 
            }
            let activeProducts = [];
            let finishedProducts = [];
            let reviewProducts = [];
            if (json.length > 0) {
                activeProducts = json.filter(active => active.status !== "FULFILLED" && active.isActive === true);
                finishedProducts = json.filter(finish => finish.status === "FULFILLED" && finish.isActive === true );
                reviewProducts = json.filter( review => review.status !== "FULFILLED" && review.isActive === false);
               
            } 
            //this.closeFilterOnSort();
            this.setState({
                filterWasApplied: true,
                openFilter: null,
                filterApplied : this.state.filterArray,
                sortSelected: d
            });
            this.props.changeProductList(activeProducts, finishedProducts, reviewProducts);
            
        })
        .catch(err => console.log(err));
    };

    getCategories = () => {
        getCategories().then(data => {
            this.setState({
                categories: data
            });
        });
    }
    render() {
        const {
            openFilter,
            design,
            preprod,
            prod,
            shipping,
            fulfilled,
            select,
            categories,
            cat
        } = this.state;
        
        return(
            <div className="inlineBlock floatRight" style={{'marginRight':'5px', 'marginTop':'10px'}}>
                <Hidden xsDown>
                    <Button className="filterButton" onClick={this.openFilterClick}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"  d="M16.8293 8C16.4175 6.83481 15.3062 6 14 6C12.6938 6 11.5825 6.83481 11.1707 8H5V10H11.1707C11.5825 11.1652 12.6938 12 14 12C15.3062 12 16.4175 11.1652 16.8293 10H19V8H16.8293ZM7.17071 16C7.58254 17.1652 8.69378 18 10 18C11.3062 18 12.4175 17.1652 12.8293 16H19V14H12.8293C12.4175 12.8348 11.3062 12 10 12C8.69378 12 7.58254 12.8348 7.17071 14H5V16H7.17071ZM14 10C14.5523 10 15 9.55228 15 9C15 8.44772 14.5523 8 14 8C13.4477 8 13 8.44772 13 9C13 9.55228 13.4477 10 14 10ZM11 15C11 15.5523 10.5523 16 10 16C9.44772 16 9 15.5523 9 15C9 14.4477 9.44772 14 10 14C10.5523 14 11 14.4477 11 15Z" fill="black"/>
                            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="6" width="14" height="12">
                                <path fillRule="evenodd"  d="M16.8293 8C16.4175 6.83481 15.3062 6 14 6C12.6938 6 11.5825 6.83481 11.1707 8H5V10H11.1707C11.5825 11.1652 12.6938 12 14 12C15.3062 12 16.4175 11.1652 16.8293 10H19V8H16.8293ZM7.17071 16C7.58254 17.1652 8.69378 18 10 18C11.3062 18 12.4175 17.1652 12.8293 16H19V14H12.8293C12.4175 12.8348 11.3062 12 10 12C8.69378 12 7.58254 12.8348 7.17071 14H5V16H7.17071ZM14 10C14.5523 10 15 9.55228 15 9C15 8.44772 14.5523 8 14 8C13.4477 8 13 8.44772 13 9C13 9.55228 13.4477 10 14 10ZM11 15C11 15.5523 10.5523 16 10 16C9.44772 16 9 15.5523 9 15C9 14.4477 9.44772 14 10 14C10.5523 14 11 14.4477 11 15Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0)">
                                <rect width="24" height="24" fill="#22223B"/>
                            </g>
                        </svg>
                    </Button>
                    <Popper
                        id="menu-filters"
                        anchorEl={openFilter}
                        open={Boolean(openFilter)}
                        transition
                        disablePortal
                        placement="left-start"
                    >
                        {
                            ({ TransitionProps, placement }) => (
                                <Grow {...TransitionProps} id="menu-filter-grow">
                                    <Paper>
                                        <ClickAwayListener onClickAway={e => this.closeFilter(e)}>
                                            <div className="p-lr-5 p-tb-5" id="checkboxContainer">
                                                <p>Filter Products by:</p>
                                                <FormControlLabel 
                                                    control={
                                                        <OrangeCheckbox
                                                            checked={design}
                                                            value="design"
                                                            onChange={e => this.handleCheck(e)}
                                                            className = "contentStatusDesign"
                                                        />
                                                    }
                                                    label="Design"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <OrangeCheckbox
                                                            checked={preprod}
                                                            value="preprod"
                                                            onChange={e => this.handleCheck(e)}
                                                            className = "contentStatusPre"
                                                        />
                                                    }
                                                    label="Pre-production"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <OrangeCheckbox
                                                            checked={prod}
                                                            value="prod"
                                                            onChange={e => this.handleCheck(e)}
                                                            className = "contentStatusProd"
                                                        />
                                                    }
                                                    label="Production"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <OrangeCheckbox
                                                            checked={shipping}
                                                            value="shipping"
                                                            onChange={e => this.handleCheck(e)}
                                                            className = "contentStatusShipping"
                                                        />
                                                    }
                                                    label="Shipping"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <OrangeCheckbox
                                                            checked={fulfilled}
                                                            value="fulfilled"
                                                            onChange={e => this.handleCheck(e)}
                                                            className = "contentStatusFulfilled"
                                                        />
                                                    }
                                                    label="Fulfilled"
                                                />
                                                <p>Sort Products by:</p>
                                                <FormControl variant="outlined" className="loginForm  m-tb-2">
                                                    <Select
                                                        name="select"
                                                        variant="outlined"
                                                        value= {select}
                                                        onChange={ e => this.onChange(e)}
                                                        className = "loginInput selectForm"
                                                    >
                                                        <MenuItem className="workSans" name="optionsort" value='created'>Project Creation Date</MenuItem>
                                                        <MenuItem className="workSans" name="optionsort" value='name'>Project Name (A-Z)</MenuItem>
                                                        <MenuItem className="workSans" name="optionsort" value='namedesc'>Project Name (Z-A)</MenuItem>/>
                                                    </Select>
                                                </FormControl>
                                                <p>Category:</p>
                                                <FormControl variant="outlined" className="loginForm  m-tb-2">
                                                    <Select
                                                        name="cat"
                                                        variant="outlined"
                                                        value= {cat}
                                                        onChange={ e => this.onChange(e)}
                                                        className = "loginInput selectForm"
                                                    >
                                                        {
                                                            categories.map((category, index) => {
                                                                return(
                                                                    <MenuItem
                                                                        key = {category.id}
                                                                        className="workSans"  
                                                                        value={category.id}
                                                                        name="optioncategory"
                                                                    >
                                                                        {category.type === 'subcategory' ? <span className='spaceSub'>{category.name} </span>: category.name }
                                                                    </MenuItem>
                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </FormControl>
                                                <FormControl className="loginForm">
                                                    <TextField
                                                        id="aplyfilter"
                                                        type = "submit"
                                                        className = "yellowSubmit"
                                                        variant="outlined"
                                                        value="APPLY"
                                                        name="sortby"
                                                        onClick={this.sortProducts}
                                                    />
                                                </FormControl>
                                            </div>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )
                        }
                    </Popper>
                </Hidden>
                <Hidden smUp>
                    <Button className="filterButton"  onClick={this.openFullFilterClick}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"  d="M16.8293 8C16.4175 6.83481 15.3062 6 14 6C12.6938 6 11.5825 6.83481 11.1707 8H5V10H11.1707C11.5825 11.1652 12.6938 12 14 12C15.3062 12 16.4175 11.1652 16.8293 10H19V8H16.8293ZM7.17071 16C7.58254 17.1652 8.69378 18 10 18C11.3062 18 12.4175 17.1652 12.8293 16H19V14H12.8293C12.4175 12.8348 11.3062 12 10 12C8.69378 12 7.58254 12.8348 7.17071 14H5V16H7.17071ZM14 10C14.5523 10 15 9.55228 15 9C15 8.44772 14.5523 8 14 8C13.4477 8 13 8.44772 13 9C13 9.55228 13.4477 10 14 10ZM11 15C11 15.5523 10.5523 16 10 16C9.44772 16 9 15.5523 9 15C9 14.4477 9.44772 14 10 14C10.5523 14 11 14.4477 11 15Z" fill="black"/>
                            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="6" width="14" height="12">
                                <path fillRule="evenodd"  d="M16.8293 8C16.4175 6.83481 15.3062 6 14 6C12.6938 6 11.5825 6.83481 11.1707 8H5V10H11.1707C11.5825 11.1652 12.6938 12 14 12C15.3062 12 16.4175 11.1652 16.8293 10H19V8H16.8293ZM7.17071 16C7.58254 17.1652 8.69378 18 10 18C11.3062 18 12.4175 17.1652 12.8293 16H19V14H12.8293C12.4175 12.8348 11.3062 12 10 12C8.69378 12 7.58254 12.8348 7.17071 14H5V16H7.17071ZM14 10C14.5523 10 15 9.55228 15 9C15 8.44772 14.5523 8 14 8C13.4477 8 13 8.44772 13 9C13 9.55228 13.4477 10 14 10ZM11 15C11 15.5523 10.5523 16 10 16C9.44772 16 9 15.5523 9 15C9 14.4477 9.44772 14 10 14C10.5523 14 11 14.4477 11 15Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0)">
                                <rect width="24" height="24" fill="#22223B"/>
                            </g>
                        </svg>
                    </Button>
                    <Dialog
                        open={this.state.openFullFilter}
                        onClose={this.handleCloseFullFilter}
                        aria-labelledby="simple-dialog-title"
                        className = "grayBg"
                        id="full-filter"
                        fullScreen
                    >
                        <div className="p-lr-5 p-tb-5 whiteBg">
                            <span id="closefilterFull" className="floatLeft workSans" onClick={(e) => this.handleCloseFullFilter(e)}>X</span>
                            <h3 className="textCenter m-0">FILTER PRODUCTS</h3>
                        </div>
                        <div className="p-lr-5 p-tb-5" id="checkboxContainer">
                            <p>Filter Products by: </p>
                            <FormControlLabel
                                control={
                                    <OrangeCheckbox
                                        checked={design}
                                        value="design"
                                        onChange={e => this.handleCheck(e)}
                                        className = "contentStatusDesign"
                                    />
                                }
                                label="Design"
                            />
                            <FormControlLabel
                                control={
                                    <OrangeCheckbox
                                        checked={preprod}
                                        value="preprod"
                                        onChange={e => this.handleCheck(e)}
                                        className = "contentStatusPre"
                                    />
                                }
                                label="Pre-production"
                            />
                            <FormControlLabel
                                control={
                                    <OrangeCheckbox
                                        checked={prod}
                                        value="prod"
                                        onChange={e => this.handleCheck(e)}
                                        className = "contentStatusProd"
                                    />
                                }
                                label="Production"
                            />
                            <FormControlLabel
                                control={
                                    <OrangeCheckbox
                                        checked={shipping}
                                        value="shipping"
                                        onChange={e => this.handleCheck(e)}
                                        className = "contentStatusShipping"
                                    />
                                }
                                label="Shipping"
                            />
                            <FormControlLabel
                                control={
                                    <OrangeCheckbox
                                        checked={fulfilled}
                                        value="fulfilled"
                                        onChange={e => this.handleCheck(e)}
                                        className = "contentStatusFulfilled"
                                    />
                                }
                                label="Fulfilled"
                            />
                            <p>Sort Products by:</p>
                            <FormControl variant="outlined" className="loginForm  m-tb-2">
                                <Select
                                    name="select"
                                    variant="outlined"
                                    value={select}
                                    onChange={ e => this.onChange(e)}
                                    className = "loginInput selectForm"
                                >
                                    <MenuItem className="workSans"  value='created'>Project Creation Date</MenuItem>
                                    <MenuItem className="workSans" value='name'>Project Name (A-Z)</MenuItem>
                                    <MenuItem className="workSans" name="optionsort" value='namedesc'>Project Name (Z-A)</MenuItem>/>
                                </Select>
                            </FormControl>
                            <p>Category:</p>
                            <FormControl variant="outlined" className="loginForm  m-tb-2">
                                <Select
                                    name="cat"
                                    variant="outlined"
                                    value= {cat}
                                    onChange={ e => this.onChange(e)}
                                    className = "loginInput selectForm"
                                >
                                    {
                                        categories.map((category, index) => {
                                            return(
                                                <MenuItem
                                                    key = {category.id}
                                                    className="workSans"  
                                                    value={category.id}
                                                    name="optioncategory"
                                                >
                                                    {category.type === 'subcategory' ? <span className='spaceSub'>{category.name} </span>: category.name }
                                                </MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                            </FormControl>
                            <FormControl className="loginForm">
                                <TextField
                                    id="aplyfilter"
                                    type = "submit"
                                    className = "yellowSubmit"
                                    variant="outlined"
                                    value="APPLY"
                                    onClick={this.sortProducts}
                                />
                            </FormControl>
                        </div>
                    </Dialog>
                </Hidden>
            </div>
        )
    }
}

export default StatusFilter;