import React, {Component} from 'react';
import MainBilling from './MainBilling';
import NewInvoice from './NewInvoice';


class AdminBilling extends Component {
    constructor(props){
        super(props);
      
        this.clickNew = this.clickNew.bind(this);
        this.backMainBilling = this.backMainBilling.bind(this);
    }

    
    clickNew = () => {
      
        this.props.change({
            isInInvoices: true,
            billingOption : 'new'
        });
    }
    backMainBilling = () => {
        this.props.change({
            billingOption : ''
        });
    }


    render(){
       const { billingOption} = this.props;
       return(
           <>
           {
                <MainBilling
                option = { billingOption}
                clickNew = {this.clickNew}
                />
           }
           {
                <NewInvoice 
                option = { billingOption}
                back = { this.backMainBilling}
                />
           }
           </>
       )
    }

}
export default AdminBilling